import React from 'react';
import { Row, Col, Alert, Box, themes } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import AbsenceDetailsTable from './AbsenceDetailsTable';

const Error = () => (
  <Alert type={'info'}>
    <span>There are no absence details to display at this time.</span>
  </Alert>
);

const AbsenceDetails = ({ leaveDetails, leave, employee }) => {
  const { t } = useTranslation();

  if (!leaveDetails || !leave) {
    return <Error />;
  }

  const workDays = employee.numberDaysWorkWeek;
  const workHoursInDay = employee.numberHoursForWorkDay;
  const workHoursInWeek = workDays && workHoursInDay ? workDays * workHoursInDay : '';

  if (workDays === '0' && workHoursInDay === '0.0' && leave.lveCatg === 'C') {
    return <Alert type='info'>There is no absence detail data to display at this time.</Alert>;
  }

  return (
    <>
      <Box style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        <Row>
          <Col>
            <strong>{t('workScheduleDetailsText')}</strong>
          </Col>
        </Row>
        {workDays !== '0' && workHoursInDay !== '0.0' ? (
          <Row>
            <Col>
              <strong>{t('daysInWorkWeekText')}</strong>
              <span style={{ marginLeft: '1rem' }}>{workDays}</span>
            </Col>
            <Col>
              <strong>{t('hoursInWorkWeekText')}</strong>
              <span style={{ marginLeft: '1rem' }}>{workHoursInWeek}</span>
            </Col>
            <Col>
              <strong>{t('hoursinWorkDayText')}</strong>
              <span style={{ marginLeft: '1rem' }}>{workHoursInDay}</span>
            </Col>
          </Row>
        ) : (
          'There are no work schedule details to display.'
        )}
      </Box>
      {leave.lveCatg !== 'C' ? <AbsenceDetailsTable leave={leave} leaveId={leave.lveNum} /> : null}
    </>
  );
};

export default AbsenceDetails;
