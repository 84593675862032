import React, { useEffect } from 'react';
import { Box, Collapse, Heading, Icon, themes } from '@digitools/honest-react';
import QuickTipsImg from 'src/assets/quick_tips.svg';
import styled from 'styled-components';
import IdentityVerification from '../verification/IdentityVerification';
import useTranslate from 'src/hooks/useTranslate';
import InfoText from './GettingStartedText';
import useAuthentication from 'src/hooks/useAuthentication';
import Notifications, { FunctionalArea } from 'src/components/Notifications/Notifications';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useAsCustomerConfigContext from '../../hooks/useAsConfigContext';
import useAsDataContext from '../../hooks/useAsDataContext';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const StyledIcon = styled(Icon) `
  font-size: 2.5rem;
  margin-right: 0.5rem;
  margin-top: -0.25rem;
  @media only screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;
const BoxContainer = styled('div') `
  display: flex;
  min-height: 8.125rem;
  margin-top: 1.875rem;
`;
const InlineColorHeaderBox = styled(Box) `
  @media (max-width: 1024px) {
    width: 4.5rem;
  }
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  padding: 1.5rem;
  width: 8.125rem;
`;
const InlineContentBox = styled(Box) `
  @media (min-width: 1025px) {
    border-left: none;
  }
  display: flex;
  flex: 1;
  margin-top: 0px;
`;
const ScalableBorderlessBox = styled(Box) `
  margin-bottom: 1rem;
  width: 75%;
  margin-left: 0rem;
  border: none;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0;
    border: none;
  }
  @media (min-width: 992px) and (max-width: 1300px) {
    width: 90%;
  }
`;
const HeadingText = styled(Heading) `
  @media (max-width: 1024px) {
    margin-left: 0.8rem;
  }
`;
const ParagraphText = styled('p') `
  @media (max-width: 1024px) {
    margin-left: 0.8rem;
  }
`;
const boxStyle = {
    backgroundColor: '#f2f2f2',
    display: 'flex',
    flexDirection: 'row',
};
const infoBoxTextStyle = {
    marginTop: '0px',
};
const paragraphTextStyle = {
    marginTop: '5px',
};
const contentBoxStyle = {
    display: 'block',
};
const GettingStarted = () => {
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { user } = useAuthentication();
    const { trackView } = useTealium();
    const { asConfigLoading } = useAsCustomerConfigContext();
    const { setDocuments, setClaimantData, setConditionData, accommodationResponse, setAccommodationResponse, isLoading, } = useAsDataContext();
    const identityVerificationTitleDetails = experience === Experience.EMPLOYEE
        ? t(InfoText.identityVerificationTitleDetailsEE)
        : t(InfoText.identityVerificationTitleDetailsER);
    const supportingDocumentationDetails = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? t(InfoText.supportingDocumentationDetailsEE)
        : t(InfoText.supportingDocumentationDetailsER));
    const supportingDocumentationMoreDetails = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? t(InfoText.supportingDocumentationMoreDetailsEE)
        : t(InfoText.supportingDocumentationMoreDetailsER));
    useEffect(() => {
        if (accommodationResponse !== undefined) {
            setClaimantData({});
            setAccommodationResponse({});
            setConditionData({});
            setDocuments([]);
        }
        trackView({
            page_l3: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
        });
    }, []);
    return (<>
      <ScalableBorderlessBox>
        <Notifications data-testid='start-notification-banner' functionalArea={FunctionalArea.AS}/>
        <Heading>{t(InfoText.pageTitle)}</Heading>
        <br />
        <Heading data-private={true} elemType='h2' data-testid={`asWelcomeMessage`}>
          {t(InfoText.welcomeTxt)} {user?.firstName}{' '}
        </Heading>
        <Box style={boxStyle}>
          <StyledIcon name='important-message' size={'large'} color={'primary'}/>
          <p style={infoBoxTextStyle}>{t(InfoText.pageInfo)}</p>
        </Box>
        <BoxContainer data-public={true}>
          <InlineColorHeaderBox type='color' theme={themes.lincoln} data-public={true}>
            <QuickTipsImg width='59px' height='65px' aria-hidden={true} data-public={true}/>
          </InlineColorHeaderBox>
          <InlineContentBox data-public={true}>
            <div style={contentBoxStyle}>
              <Collapse type='alternate' isOpen={true} theme={themes.lincoln} title={t(InfoText.header)}>
                <HeadingText elemType='h3' elemStyle='h4'>
                  {t(InfoText.identityVerificationTitle)}
                </HeadingText>
                <ParagraphText style={paragraphTextStyle}>{identityVerificationTitleDetails}</ParagraphText>

                <HeadingText elemType='h3' elemStyle='h4'>
                  {t(InfoText.accomodationDetailsTitle)}
                </HeadingText>
                <ParagraphText style={paragraphTextStyle}>{t(InfoText.accomodationDetails)}</ParagraphText>

                <HeadingText elemType='h3' elemStyle='h4'>
                  {t(InfoText.supportingDocumentationTitle)}
                </HeadingText>
                <ParagraphText style={paragraphTextStyle}>{supportingDocumentationDetails}</ParagraphText>
                <br />

                <ParagraphText>{supportingDocumentationMoreDetails}</ParagraphText>
                <br />
                {experience === Experience.EMPLOYEE && (<ParagraphText>
                    <b>{t(InfoText.noteTxt)}</b>
                    {t(InfoText.noteDetails)}
                  </ParagraphText>)}
              </Collapse>
            </div>
          </InlineContentBox>
        </BoxContainer>
        <IdentityVerification />
      </ScalableBorderlessBox>
    </>);
};
export default GettingStarted;
