import React from 'react';
import { Button, themes } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import useAsDataContext from '../../hooks/useAsDataContext';
import useAuthentication from 'src/hooks/useAuthentication';
import styled from 'styled-components';
import { AsText } from '../../constants/Text';
import { useNavigate } from 'react-router-dom';
import useExperience from 'src/hooks/useExperience';
import { SubmitInterviewData } from '../../api/ApiCalls';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { getAsRequest } from '../../utils/AsDataUtils';
import { Experience } from "src/types/Experience";
import useAsCustomerConfigContext from '../../hooks/useAsConfigContext';
const StyledReviewSubmitButton = styled(Button) `
    @media(max-width: 1200px) {
        padding: 0 1rem;
        width: 100%;
        margin-bottom: .5rem;
    }
    @media(min-width: 768px) {
        width: auto;
    }
    margin-left: 0 !important;
`;
const ReviewSubmit = () => {
    const { t } = useTranslate();
    let navigate = useNavigate();
    const { claimantData, conditionData, accommodationRequestDataArray, documents, setSubmitErrors, setIsLoading, setAccommodationResponse, isDisclaimerAccepted, } = useAsDataContext();
    const { experience, language, experienceCode } = useExperience();
    const { authHeader } = useAuthentication();
    const { trackEvent } = useTealium();
    const { asConfig } = useAsCustomerConfigContext();
    const handleOnClick = async () => {
        setIsLoading(true);
        const submitObj = {
            aboutYouFormData: {
                employeeId: claimantData?.empId,
                employeeSsn: claimantData?.ssn,
                firstName: claimantData?.empFirstName,
                middleInitial: claimantData?.empMiName,
                lastName: claimantData?.empLastName,
                dateOfBirth: claimantData?.empBthDte,
                emailAddress: claimantData?.empEmail,
                address1: claimantData?.empLine1Addr,
                address2: claimantData?.empLine2Addr,
                city: claimantData?.empCity,
                country: claimantData?.empCtryCde,
                state: claimantData?.empState,
                zip: claimantData?.empZip,
                phoneNumber: claimantData?.empPhoneNum,
            },
            aboutAccommodationFormData: {
                receivedDate: conditionData?.receivedDate,
                conditions: conditionData?.conditions,
                workRelated: conditionData?.workRelated,
                conditionText: conditionData?.conditionText,
                requests: accommodationRequestDataArray && getAsRequest(accommodationRequestDataArray),
            },
            documents: documents,
            language: language,
            asPdfSubmission: {
                claimantData,
                accommodationRequestDataArray: accommodationRequestDataArray && getAsRequest(accommodationRequestDataArray),
                conditionData,
                documents: documents,
                language: language,
                experience: experienceCode,
                asConfig: asConfig?.config,
            }
        };
        if (experience === Experience.EMPLOYEE)
            submitObj.disclaimerAccepted = isDisclaimerAccepted;
        try {
            const { data, status } = await SubmitInterviewData(submitObj, experience, authHeader());
            if (status === 200) {
                setIsLoading(false);
                setSubmitErrors(false);
                setAccommodationResponse(data);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                    event_name: 'submit',
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION_INTAKE,
                    event_version: 'review',
                    event_status: TEALIUM_EVENT.EVENT_STATUS.COMPLETE,
                    transaction_id: data?.eventNum,
                });
                window.scrollTo(0, 0);
                navigate(`/as/${experience.toLowerCase()}/confirmation`);
            }
            else {
                setSubmitErrors(true);
                setIsLoading(false);
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                    event_name: t(AsText.submitErrorMessage),
                    event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                    event_version: 'review your request and submit',
                });
            }
        }
        catch (error) {
            setIsLoading(false);
            setSubmitErrors(true);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_name: t(AsText.submitErrorMessage),
                event_type: TEALIUM_EVENT.EVENT_TYPE.ACCOMMODATION,
                event_version: 'review your request and submit',
            });
        }
    };
    return (<StyledReviewSubmitButton type='submit' buttonType={'primary'} theme={themes.lincoln} data-testid='review-submit' onClick={handleOnClick} disabled={experience === Experience.EMPLOYEE ? !isDisclaimerAccepted : false} style={(experience === Experience.EMPLOYEE && !isDisclaimerAccepted) ? { opacity: 0.5 } : { opacity: 1 }}>
            {t(AsText.submit)}
        </StyledReviewSubmitButton>);
};
export default React.memo(ReviewSubmit);
