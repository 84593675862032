import React from 'react';
import Media from 'react-media';
import { Box, Col, Row } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const PaymentInformationSummary = ({ checkTotals }) => {
  // TODO: swap out checkTotals prop for paymentSummary stuff
  // const checkTotals = paymentSummary ? {
  //   paidThroughDate: paymentSummary.paidThroughDate,
  //   grossBenefit: paymentSummary.totalGrossBenefit,
  //   offsets: paymentSummary.totalOffsets,
  //   adjustedGross: paymentSummary.totalAdjustedGross,
  //   cola: paymentSummary.totalCola,
  //   taxes: paymentSummary.totalTaxes,
  //   otherDeductions: paymentSummary.totalOtherDeductions,
  //   netBenefit: paymentSummary.totalNetBenefit,
  // }: {};

  return (
    <Media query='(max-width: 768px)'>
      {matches =>
        matches ? (
          <PaymentInformationSummaryMobile checkTotals={checkTotals} />
        ) : (
          <PaymentInformationSummaryRegular checkTotals={checkTotals} />
        )
      }
    </Media>
  );
};

const PaymentInformationSummaryRegular = ({ checkTotals }) => {
  const { t } = useTranslation();
  return (
    checkTotals && (
      <Box
        color={'grayLightest'}
        style={{ paddingLeft: '2rem', border: 'none' }}
        data-testid={'payment-information-summary'}>
        <Row>
          <strong data-testid={'payments-paid-through-reg'}>{`Payments Paid Through ${format(
            new Date(checkTotals.paidThroughDate),
            'MM/dd/yyyy',
          )}`}</strong>
        </Row>
        <Row>
          <Col>
            <Row>
              <strong>{t('benefitGrossAmountText')}</strong>
            </Row>
            <Row data-testid={'benefit-gross-amount-reg'}>{checkTotals.grossBenefit}</Row>
          </Col>
          <Col>
            <Row>
              <strong>{t('checkTotalOffsetAmountText')}</strong>
            </Row>
            <Row data-testid={'check-total-offset-amount-reg'}>{checkTotals.offsets}</Row>
          </Col>
          <Col>
            <Row>
              <strong>{t('checkAdjustedBenefitGrossAmountText')}</strong>
            </Row>
            <Row data-testid={'adjusted-gross-amount-reg'}>{checkTotals.adjustedGross}</Row>
          </Col>
          <Col>
            <Row>
              <strong>{t('colaAmountText')}</strong>
            </Row>
            <Row data-testid={'cola-amount-reg'}>{checkTotals.cola}</Row>
          </Col>
          <Col>
            <Row>
              <strong>{t('taxesText')}</strong>
            </Row>
            <Row data-testid={'taxes-amount-reg'}>{checkTotals.taxes}</Row>
          </Col>
          <Col>
            <Row>
              <strong>{t('checkTotalDeductionAmountText')}</strong>
            </Row>
            <Row data-testid={'other-deductions-amount-reg'}>{checkTotals.otherDeductions}</Row>
          </Col>
          <Col>
            <Row>
              <strong>{t('benefitNetAmountText')}</strong>
            </Row>
            <Row data-testid={'net-benefit-amount-reg'}>{checkTotals.netBenefit}</Row>
          </Col>
        </Row>
      </Box>
    )
  );
};

const PaymentInformationSummaryMobile = ({ checkTotals }) => {
  const { t } = useTranslation();
  return (
    checkTotals && (
      <Box color={'grayLighter'} style={{ paddingLeft: '2rem' }} data-testid={'payment-information-summary'}>
        <Row data-testid={'payments-paid-through-mobile'}>
          <strong>{`Payments Paid Through ${format(new Date(checkTotals.paidThroughDate), 'MM/dd/yyyy')}`}</strong>
        </Row>
        <Row data-testid={'benefit-gross-amount-mobile'}>
          <strong>{t('benefitGrossAmountText')}:&nbsp;</strong>
          {checkTotals.grossBenefit}
        </Row>
        <Row data-testid={'check-total-offset-amount-mobile'}>
          <strong>{t('checkTotalOffsetAmountText')}:&nbsp;</strong>
          {checkTotals.offsets}
        </Row>
        <Row data-testid={'adjusted-gross-amount-mobile'}>
          <strong>{t('checkAdjustedBenefitGrossAmountText')}:&nbsp;</strong>
          {checkTotals.adjustedGross}
        </Row>
        <Row data-testid={'cola-amount-mobile'}>
          <strong>{t('colaAmountText')}:&nbsp;</strong>
          {checkTotals.cola}
        </Row>
        <Row data-testid={'taxes-amount-mobile'}>
          <strong>{t('taxesText')}:&nbsp;</strong>
          {checkTotals.taxes}
        </Row>
        <Row data-testid={'other-deductions-amount-mobile'}>
          <strong>{t('checkTotalDeductionAmountText')}:&nbsp;</strong>
          {checkTotals.otherDeductions}
        </Row>
        <Row data-testid={'net-benefit-amount-mobile'}>
          <strong>{t('benefitNetAmountText')}:&nbsp;</strong>
          {checkTotals.netBenefit}
        </Row>
      </Box>
    )
  );
};

export default PaymentInformationSummary;
