import React, { useEffect, useState } from 'react';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import Checkbox from '@digitools/honest-react/dist/lib/components/Checkbox';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import styled from 'styled-components';
import { useFormApi } from 'informed';
import { zipCodeMask, canadianZipCodeMask } from '../../../utils/InputMasks';
import { validateAddressCity, validateAddress1, validateAddress2 } from '../../../../../utils/validationUtils';
import { validateCanadianZipCode, validateZipCode, fieldRequired, selectInputErrorMessage } from '../../../utils/FormFieldValidations';
import { ContactInformationText } from '../../../i18n/ApplicantInformationText';
import useTranslate from '../../../../../hooks/useTranslate';
import useExperience from 'src/hooks/useExperience';
const AddressLabel = styled(Label) `
  margin-bottom: 0.5rem;
  flex: 0 0 100%;
`;
const AddressCheckboxDiv = styled('div') `
  align-self: start;
  display: flex;
  margin-bottom: 0.5rem;
`;
const AddressForm = (props) => {
    const { t } = useTranslate();
    const { language } = useExperience();
    const formApi = useFormApi();
    const labelText = props.type === 'residential' ? t(ContactInformationText.residentialAddress) : t(ContactInformationText.mailingAddress);
    const label = <AddressLabel theme={themes.lincoln}>{labelText}</AddressLabel>;
    const address1 = props.type === 'residential' ? t(ContactInformationText.residentialAddress1) : t(ContactInformationText.mailingAddress1);
    const address2 = props.type === 'residential' ? t(ContactInformationText.residentialAddress2) : t(ContactInformationText.mailingAddress2);
    const [selectedCountry, setSelectedCountry] = useState('USA');
    const [stateDropdownLabel, setStateDropdownLabel] = useState('');
    const [stateSelectLabel, setStateSelectLabel] = useState('');
    const [zipCodeLabel, setZipCodeLabel] = useState('');
    const [stateDropdownOptions, setStateDropdownOptions] = useState([]);
    const country = formApi.getValue(`${props.type}Country`);
    useEffect(() => {
        setSelectedCountry(formApi.getValue(`${props.type}Country`));
    }, [country]);
    useEffect(() => {
        if (selectedCountry) {
            if (selectedCountry === 'USA') {
                setStateDropdownLabel(t(ContactInformationText.state));
                setStateSelectLabel(t(ContactInformationText.selectState));
                setZipCodeLabel(t(ContactInformationText.zipCode));
                setStateDropdownOptions(props.states);
            }
            else if (selectedCountry === 'CAN') {
                setStateDropdownLabel(t(ContactInformationText.province));
                setStateSelectLabel(t(ContactInformationText.selectProvince));
                setStateDropdownOptions(props.provinces);
            }
        }
        setZipCodeLabel(t(ContactInformationText.zipCode));
    }, [selectedCountry, language]);
    const hideMailingAddressCheckbox = () => {
        if (props.type === 'residential' && props.hideMailingAddress) {
            return (<AddressCheckboxDiv data-public={true}>
          <Checkbox theme={themes.lincoln} label={t(ContactInformationText.useAsMyMailingAddress)} aria-label={t(ContactInformationText.useAsMyMailingAddress)} field={'useAsMailingAddress'} 
            //@ts-ignore FIXME: type error
            onValueChange={props.hideMailingAddress(formApi.getValue('useAsMailingAddress'))} initialValue={contactInformation.useAsMailingAddress}/>
        </AddressCheckboxDiv>);
        }
        else {
            return <></>;
        }
    };
    const getZipCodeMask = () => {
        if (formApi) {
            if (selectedCountry === 'USA') {
                return zipCodeMask;
            }
            else if (selectedCountry === 'CAN') {
                return canadianZipCodeMask;
            }
        }
        return undefined;
    };
    const getZipCodeValidator = () => {
        if (formApi) {
            if (selectedCountry === 'USA') {
                return validateZipCode;
            }
            else if (selectedCountry === 'CAN') {
                return validateCanadianZipCode;
            }
        }
        return undefined;
    };
    // get prepop values from interviewAnswersApplicant
    let contactInformation = {};
    if (props.interviewAnswersApplicant.contactInformation) {
        contactInformation = props.interviewAnswersApplicant.contactInformation;
    }
    let countryInitialValue;
    if (props.type === 'residential') {
        countryInitialValue = contactInformation.residentialCountry ? contactInformation.residentialCountry : 'USA';
    }
    else {
        countryInitialValue = contactInformation.mailingCountry ? contactInformation.mailingCountry : 'USA';
    }
    return (<>
      {label}
      <Row style={{ marginTop: '0.5rem' }} data-public={true}>
        <Col sm={12} md={6}>
          <TextInput theme={themes.lincoln} label={address1} aria-label={address1} field={`${props.type}AddressField1`} data-testid={`${props.type}-residential-address1-input`} validate={validateAddress1} validateOnChange={true} validateOnBlur={true} initialValue={props.type === 'residential' ? contactInformation.residentialAddressField1 : contactInformation.mailingAddressField1}/>
        </Col>
        <Col sm={12} md={6}>
          <TextInput theme={themes.lincoln} label={address2} aria-label={address2} field={`${props.type}AddressField2`} data-testid={`${props.type}-residential-address2-input`} optional={true} validate={validateAddress2} validateOnChange={true} validateOnBlur={true} initialValue={props.type === 'residential' ? contactInformation.residentialAddressField2 : contactInformation.mailingAddressField2}/>
        </Col>
      </Row>
      <Row data-public={true}>
      <Col sm={12} md={6}>
          <TextInput theme={themes.lincoln} validate={validateAddressCity} validateOnChange={true} validateOnBlur={true} 
    // @ts-ignore
    label={t(ContactInformationText[`${props.type}City`])} 
    // @ts-ignore
    aria-label={t(ContactInformationText[`${props.type}City`])} field={`${props.type}City`} initialValue={props.type === 'residential' ? contactInformation.residentialCity : contactInformation.mailingCity} data-testid={`${props.type}-city`}/>
        </Col>
        <Col sm={12} md={6}>
          {selectedCountry && (selectedCountry === 'USA' || selectedCountry === 'CAN') &&
            <SelectInput theme={themes.lincoln} options={stateDropdownOptions} label={stateDropdownLabel} placeholderText={stateSelectLabel} aria-label={stateDropdownLabel} field={`${props.type}State`} validate={fieldRequired} validateOnChange={true} validateOnBlur={true} errorMessage={selectInputErrorMessage} initialValue={props.type === 'residential' ? contactInformation.residentialState : contactInformation.mailingState}/>}
        </Col>
      </Row>
      <Row data-public={true}>
        <Col sm={12} md={6}>
          <TextInput theme={themes.lincoln} label={zipCodeLabel} field={`${props.type}ZipCode`} data-testid={`${props.type}-zip-code`} mask={getZipCodeMask()} aria-label={zipCodeLabel} validate={getZipCodeValidator()} validateOnChange={true} validateOnBlur={true} initialValue={props.type === 'residential' ? contactInformation.residentialZipCode : contactInformation.mailingZipCode}/>
        </Col>
        <Col sm={12} md={6}>
          <SelectInput theme={themes.lincoln} options={props.countries} field={`${props.type}Country`} label={t(ContactInformationText.country)} aria-label={t(ContactInformationText.country)} placeholderText={t(ContactInformationText.selectCountry)} validate={fieldRequired} validateOnChange={true} validateOnBlur={true} errorMessage={selectInputErrorMessage} initialValue={countryInitialValue}/>
        </Col>
      </Row>
      {hideMailingAddressCheckbox()}
    </>);
};
export default AddressForm;
