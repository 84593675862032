import { TabContainer, TabSection } from '@digitools/honest-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import useAuthentication from '../../../../../hooks/useAuthentication';
import useGet from '../../../../../hooks/useGet';
import useTealium from '../../../../../hooks/useTealium';
import NotesTable from '../NotesTable/NotesTable';
import ClaimDates from '../ClaimDetails/ClaimDates';
import Correspondence from '../ClaimDetails/Correspondence';
import PaymentInformation from '../ClaimDetails/Payment/PaymentInformation';
import RtwDetails from '../ClaimDetails/RtwDetails';
import { RtwContext } from '../../../context';
import { decode } from '../../../utilities/hash';

// Helper function to determine if we can show correspondence
const showCorrespondance = (exp, userMeta) => {
  const applicationPermissions = userMeta.applicationPermissions;
  if (exp === 'ER') {
    return applicationPermissions.indexOf('statusER.claimLeaveStatus.viewCorrespondence') > -1;
  } else {
    return applicationPermissions.indexOf('statusEE.claimLeaveStatus.viewCorrespondence') > -1;
  }
};

// Helper function to determine if we can show paymentInformation
const showPaymentInfo = (exp, userMeta) => {
  const applicationPermissions = userMeta.applicationPermissions;
  if (exp === 'ER') {
    return applicationPermissions.indexOf('statusER.claimLeaveStatus.viewPaymentInformation') > -1;
  } else {
    return applicationPermissions.indexOf('statusEE.claimLeaveStatus.viewPaymentInformation') > -1;
  }
};

const displayRtw = (exp, userMeta) => {
  const applicationPermissions = userMeta.applicationPermissions;
  if (exp === 'ER') {
    return applicationPermissions.includes('statusER.claimLeaveStatus.displayRtw');
  } else {
    return applicationPermissions.includes('statusEE.claimLeaveStatus.displayRtw');
  }
};

const hasViewClaimNotesPermission = (exp, userMeta) =>
  exp === 'ER' && userMeta.applicationPermissions.includes('statusER.claimLeaveStatus.viewClaimNotes');
const validateClaim = claim => claim && claim.fundMechnCde === 'ASO' && claim.diagWaivI === 'Y';

const ClaimTabs = ({ claimNum, claim, experience, userMeta, empNum, basicInfo, employeeData }) => {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const { rtwNavigation } = useContext(RtwContext);
  const { trackEvent } = useTealium();
  const showPaymentTab = showPaymentInfo(experience, userMeta);
  const showCorrespondanceTab = showCorrespondance(experience, userMeta);
  let employeeInfo = { firstName: employeeData.empFirstName, lastName: employeeData.empLastName };
  const showRTW = (user, experience, claim) => {
    if (user.customerInContext.smallMid && displayRtw(experience, user)) {
      return claim.clmSttusCde === 'In Progress' || claim.clmSttusCde === 'Open' || claim.clmSttusCde === 'Pended';
    } else if (!user.customerInContext.smallMid && displayRtw(experience, user)) {
      return (
        claim.clmSttusCde === 'PE' ||
        claim.clmSttusCde === 'IP' ||
        claim.clmSttusCde === 'AP' ||
        claim.clmSttusCde === 'Pending' ||
        claim.clmSttusCde === 'Pended'
      );
    }
    return false;
  };
  let tabs = [
    <TabSection
      name={t('claimDatesText')}
      label={t('claimDatesText')}
      key={'Claim Dates'}
      onClick={() =>
        trackEvent({
          event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
          event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_DATES,
          event_name: 'Claim Dates',
        })
      }>
      <ClaimDates claim={claim} />
    </TabSection>,
  ];
  //RTW details
  const showRTWTab = showRTW(user, experience, claim);
  if (showRTWTab) {
    tabs.push(
      <TabSection name={t('tab')} label={t('tab')} key={'ReturnToWork'}>
        <RtwDetails RtwProps={claim} product='claim' employeeInfo={employeeInfo} />
      </TabSection>,
    );
  }
  if (showPaymentTab) {
    tabs.push(
      <TabSection
        name={t('paymentInformationTabHeaderText')}
        label={t('paymentInformationTabHeaderText')}
        key={'Payment Information'}
        onClick={() =>
          trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_type: TEALIUM_EVENT.EVENT_TYPE.PAYMENT_INFORMATION,
            event_name: 'Payment Information',
          })
        }>
        <PaymentInformation claimNum={decode(claimNum)} empNum={empNum} claim={claim} />
      </TabSection>,
    );
  }
  if (showCorrespondanceTab) {
    tabs.push(
      <TabSection
        name={t('correspondenceText')}
        label={t('correspondenceText')}
        key={'Correspondence'}
        onClick={() =>
          trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_type: TEALIUM_EVENT.EVENT_TYPE.SHOW_CORRESPONDENCE,
            event_name: 'Correspondence',
          })
        }>
        <Correspondence claimNum={decode(claimNum)} claim={claim} />
      </TabSection>,
    );
  }

  // TODO: Update showNotesTab to actually check something
  const showNotesTab = hasViewClaimNotesPermission(experience, userMeta) && validateClaim(claim);
  if (showNotesTab) {
    const {
      data: notesData,
      error: notesError,
      loading: notesLoading,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useGet({
      url: '/status-service/notes/claim',
      options: {
        params: { claimNum: decode(claimNum) },
      },
    });
    const claimNoteBasicDetails = {
      clmLveNumber: decode(claimNum),
      determinationDate: claim.clmDtrmDte,
      status: basicInfo.status,
      employeeName: basicInfo.employeeFullName,
      location: basicInfo.location,
      subsidiary: basicInfo.subsidiary,
      userName: userMeta.firstName,
      reportType: 'Claim',
    };
    tabs.push(
      <TabSection
        name={'Claim Notes'}
        onClick={() =>
          trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_type: TEALIUM_EVENT.EVENT_TYPE.SHOW_CLAIM_NOTES,
            event_name: 'Claim Notes',
          })
        }>
        <NotesTable
          noteBasicDetails={claimNoteBasicDetails}
          notes={notesData}
          error={notesError}
          loading={notesLoading}
        />
      </TabSection>,
    );
  }
  return (
    <TabContainer data-testid='tabContainer' initialActiveTab={rtwNavigation ? t('tab') : ''}>
      {tabs.map(tab => {
        return tab;
      })}
    </TabContainer>
  );
};

export default ClaimTabs;
