import React, { useState, useEffect } from 'react';
import { Row, Col, themes, Heading, Label } from '@digitools/honest-react';
import WorkLocationModal from './work-location-model/workLocationsModal';
import { workStatesModel, workProvincesModel } from './work-location-model/initialStateAndProvinces';
import { useFormApi } from 'informed';
const SelectStatesAndProvinces = props => {
    const [stateAndProvinceCount, setStateAndProvinceCount] = useState(props.totalStatesAndProvinces);
    useEffect(() => {
        const count = props.totalStatesAndProvinces ? props.totalStatesAndProvinces : countOfStatesAndProvinces;
        setStateAndProvinceCount(count);
    }, [props.reportFilterCategory, props.totalStatesAndProvinces]);
    const updateSelectedStatesAndProvinces = (count) => {
        setStateAndProvinceCount(count);
    };
    const workStatesModalCount = workStatesModel.filter((workStates) => workStates.stateCode !== 'AWS')
        .length;
    const workProvincesModalCount = workProvincesModel.filter((workProvince) => workProvince.provinceCode !== 'AWL').length;
    const countOfStatesAndProvinces = workStatesModalCount + workProvincesModalCount;
    const prevSelectedWorkStates = getPreviouslySelectedStates();
    const prevSelectedWorkProvinces = getPreviouslySelectedProvinces();
    return (<div style={{ marginTop: '1.5rem' }}>
      <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }}>
        <Heading elemType={'h3'} color={'grayDarker'}>
          {props.headerLabel + ' work location'}
        </Heading>
        <Row>
          <Col>
            <Label style={{ marginLeft: '1rem' }}>
              {' '}
              {stateAndProvinceCount === countOfStatesAndProvinces ? 'All' : stateAndProvinceCount} selected{' '}
            </Label>
          </Col>
        </Row>
        <WorkLocationModal onSave={updateSelectedStatesAndProvinces} prevSelectedStates={prevSelectedWorkStates} prevSelectedProvinces={prevSelectedWorkProvinces} reportFilterCategory={props.reportFilterCategory}/>
      </div>
      <hr />
    </div>);
};
export default SelectStatesAndProvinces;
const getPreviouslySelectedStates = () => {
    const formApi = useFormApi();
    const workStates = workStatesModel.filter((workState) => formApi.getValue(workState.stateCode) === true);
    return workStates;
};
const getPreviouslySelectedProvinces = () => {
    const formApi = useFormApi();
    const workProvinces = workProvincesModel.filter((workProvince) => formApi.getValue(workProvince.provinceCode) === true);
    return workProvinces;
};
