import React from 'react';
import { Row, Col, Checkbox, CheckboxGroup, SelectAllCheckbox, themes, Heading } from '@digitools/honest-react';
import { getProductDisplayLabel } from './FilterMyReportValidation';
import * as _ from 'lodash';
const validateGroup = (value) => {
    return !value || value.length < 1 ? 'Required' : undefined;
};
const SelectProducts = ({ reportFilterCategory, products, onProductSelect }) => {
    const productsArray = _.chunk(products, Math.ceil(products ? products.length / 3 : 2));
    const productTitle = reportFilterCategory + ' products';
    const productCheckboxRows = productsArray.map((subProducts, indexRow) => {
        return (<Col lg={4} key={indexRow}>
      {subProducts.map((product, index) => {
                const onSelect = (event) => onProductSelect(product);
                return (<Col style={{ marginTop: '.3rem' }} key={'col' + index}> <Checkbox field={product.code} checked={product.checked} label={getProductDisplayLabel(product)} onChange={onSelect}/></Col>);
            })}
    </Col>);
    });
    return ((<div style={{ marginTop: '1.5rem' }}>
        <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }}>
          <Heading elemType={'h3'} color={'grayDarker'}>
            {productTitle}
          </Heading>
          <CheckboxGroup field={'productgroup'} label={''} validate={validateGroup} validateOnChange={true}>
            <Row>
                <Col lg={4} style={{ paddingLeft: '14px' }}>
                    <SelectAllCheckbox field={'All'} onChange={(event) => onProductSelect({ code: 'All', label: 'All', checked: event.currentTarget.checked })}/>
                </Col>
            </Row>
            <Row style={{ marginTop: '-0.05rem' }}>
              {productCheckboxRows}
            </Row>                
          </CheckboxGroup>
        </div>
        <hr />
      </div>));
};
export default SelectProducts;
