import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, Container, Heading, Icon, Row, TextInput } from '@digitools/honest-react';
import { Form } from 'informed';
import Captcha from '../../../../../components/captcha/Captcha';
import useRequiredField from '../../../../../hooks/fieldValidation/useRequiredField';
import useValidEmail from '../../../../../hooks/fieldValidation/useValidEmail';
import useValidFirstName from '../../../../../hooks/fieldValidation/useValidFirstName';
import useValidLastName from '../../../../../hooks/fieldValidation/useValidLastName';
import useValidPhone from '../../../../../hooks/fieldValidation/useValidPhone';
import useValidUsername from '../../../../../hooks/fieldValidation/useValidUsername';
import useExperience from '../../../../../hooks/useExperience';
import CancelModal from '../cancelModal/CancelModal';
import Password from './../password/Password';
import TermsAndConditions from './../termsAndConditions/TermsAndConditions';
// @ts-ignore
import Styles from './RegistrationForm.scss';
import { Language } from 'src/types/Language';
const RegistrationForm = (props) => {
    const { submitError, resetCaptcha, registerNewUser, setResetCaptcha, captchaInitialValue } = props;
    const [formApi, setFormApi] = useState();
    const [resetCaptchaState, setCaptchaState] = useState(false);
    const { language } = useExperience();
    const personalInfoFieldset = useRef(null);
    const { getLocalDisplayText } = props;
    const validateUsername = useValidUsername(true);
    const validatePhone = useValidPhone(true);
    const validateEmail = useValidEmail(true);
    const validateFirstName = useValidFirstName(true);
    const validateLastName = useValidLastName(true);
    const validateRequired = useRequiredField({ [Language.ENGLISH]: 'Captcha', [Language.SPANISH]: 'Captcha' });
    const trim = (value) => (value != null ? value.trim() : value);
    useEffect(() => {
        if (submitError) {
            setCaptchaState(true);
        }
        if (resetCaptcha && formApi) {
            setCaptchaState(true);
            setResetCaptcha(false);
        }
    }, [submitError, resetCaptcha, language]);
    useEffect(() => {
        if (personalInfoFieldset.current) {
            personalInfoFieldset.current.focus();
        }
    }, []);
    return (<Container className={'mt-2'}>
      <Heading elemType={'h1'} elemStyle={'h2'} id='pageHeading' color='secondaryDarker'>
        {getLocalDisplayText('pageHeading')}
      </Heading>

      <p className={'mt-3'} id='registrationInfoText'>
        {getLocalDisplayText('registrationInfoTextRegistrationForm')}
      </p>

      {/* This is specifically for screen-reader users */}
      <span role={'status'} style={{ opacity: 0, height: 0 }}>
        We've verified your company code! You are registering for {props.companyName}.
      </span>

      <strong id='employerNameLabel' className={'d-block'}>
        {getLocalDisplayText('employerNameLabel')}
      </strong>

      <Row classNames={'mt-2 mb-2'}>
        <Col>
          <Badge aria-labelledby={'employerNameLabel'} id='companyName' backgroundColor='secondaryDark' style={{ display: 'inline-flex', alignItems: 'center', padding: '.25rem .5rem' }} data-testid={'companyName'}>
            <Icon name='success' color='white' className='mr-1'/>
            <strong>{props.companyName}</strong>
          </Badge>
        </Col>
      </Row>

      <Form onSubmit={registerNewUser} getApi={setFormApi}>
        <fieldset className={`mt-5 ${Styles.fieldset}`} ref={personalInfoFieldset} tabIndex={-1}>
          <legend className={`mb-3 ${Styles.legend}`} id='personalInfoHeading'>
            <Heading elemType={'h3'}>{getLocalDisplayText('personalInfoHeading')}</Heading>
          </legend>

          <Row>
            <Col sm='6' classNames={'mb-3 mb-sm-0'}>
              <TextInput ariaLabelText={'First Name'} field='firstName' label={getLocalDisplayText('firstName')} maxLength={35} validate={validateFirstName} validateOnChange={true} validateOnBlur={true} data-testid={'firstName'} data-private={true}/>
            </Col>
            <Col sm='6'>
              <TextInput ariaLabelText={'Last Name'} field='lastName' label={getLocalDisplayText('lastName')} maxLength={35} validate={validateLastName} validateOnChange={true} validateOnBlur={true} data-testid={'lastName'} data-private={true}/>
            </Col>
          </Row>
          <Row>
            <Col sm='6' classNames={'mb-3 mb-sm-0'}>
              <TextInput ariaLabelText={'enter phonenumber'} field='preferredPhone' label={getLocalDisplayText('phoneNumber')} maxLength={17} success={true} validate={validatePhone} validateOnChange={true} validateOnBlur={true} data-testid={'preferredPhone'}/>
            </Col>
            <Col sm='6'>
              <TextInput ariaLabelText={'enter email'} field='preferredEmail' validate={validateEmail} parse={trim} format={trim} validateOnChange={true} validateOnBlur={true} success={true} data-testid={'preferredEmail'} label={getLocalDisplayText('preferredEmailAddress')}/>
            </Col>
          </Row>
        </fieldset>

        <fieldset className={`mt-5 ${Styles.fieldset}`}>
          <legend className={`mb-3 ${Styles.legend}`} id='loginInfoHeading' role={'heading'}>
            <Heading elemType={'h3'}>{getLocalDisplayText('loginInfoHeading')}</Heading>
          </legend>

          <Row className={'mt-0'}>
            <Col sm='6'>
              <TextInput ariaLabelText={'enter username'} field='username' helperText={getLocalDisplayText('usernameHelperText')} label={getLocalDisplayText('usernameLabel')} parse={trim} format={trim} maxLength={128} notify={['pwd']} success={true} validate={validateUsername} validateOnChange={true} validateOnBlur={true} data-testid={'username'}/>
            </Col>
          </Row>
          <Password language={language} getLocalDisplayText={getLocalDisplayText}/>
        </fieldset>

        <Row className={'d-flex justify-content-start mt-5'}>
          <Col>
            <div className={Styles.captcha}>
              <Captcha aria-label={'solve captcha'} field={'captcha'} resetCaptcha={resetCaptchaState} setCaptchaState={setCaptchaState} validate={validateRequired} validateOnBlur={true} validateOnChange={true} data-testid={'captcha'} initialValue={captchaInitialValue} // needed for testing purposes and should never be set outside of tests
    />
            </div>
          </Col>
        </Row>

        <Row className={'d-flex justify-content-start'}>
          <Col>
            <TermsAndConditions language={language} getLocalDisplayText={getLocalDisplayText}/>
          </Col>
        </Row>

        <Row className={'d-flex justify-content-start ml-1'}>
          <Col className={'mt-3 mb-2'}>
            <div className={Styles.parent}>
              <div className={Styles.rightAlign}>
                <Button type={'submit'} buttonType='primary' className={'ml-1 mr-3'} id='register' data-testid={'register'}>
                  {getLocalDisplayText('registerButtonLabel')}
                </Button>
                <CancelModal history={props.history} getLocalDisplayText={getLocalDisplayText}/>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>);
};
export default RegistrationForm;
