import React from 'react';
import { Col, Radio, RadioGroup, Row, themes } from '@digitools/honest-react';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import useCliDataContext from '../../../hooks/useCliDataContext';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import useTranslate from 'src/hooks/useTranslate';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
const SurgeryIndicator = (props) => {
    const { absenceData } = useCliDataContext();
    const { experience } = useExperience();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const surgeryIndicatorLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.surgeryIndicatorEE : formLabels.surgeryIndicatorER));
    return (<>
      <Row data-testid='surgeryIndicator' className='mb-3'>
        <Col xs={12}>
          <RadioGroup label={surgeryIndicatorLabel} field={'surgeryIndicator'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.surgeryIndicator}>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-yes'} key={'hi-yes'} name='surgeryIndicator' label={t(AboutYourAbsenceText.formLabels.yes)} value={'Y'}/>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-no'} key={'hi-no'} name='surgeryIndicator' label={'No'} value={'N'}/>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-unknown'} key={'hi-unknown'} name='surgeryIndicator' label={t(AboutYourAbsenceText.formLabels.unknown)} value={'U'}/>
          </RadioGroup>
        </Col>
      </Row>
    </>);
};
export default SurgeryIndicator;
