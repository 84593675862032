import { Heading, Stepper, StepperContext, ThemeProvider, themes } from '@digitools/honest-react';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAuthentication from '../../../hooks/useAuthentication';
import { StepTitle } from '../constants/LCIConstant';
import LCIConfirmation from './LCIConfirmation';
import LCIGettingStarted from './LCIGettingStarted';
import AdditionalInfo from './steps/AdditionalInfo';
import CallerInfo from './steps/CallerInfo';
import DependentInfo from './steps/DependentInfo';
import EmployeeInfo from './steps/EmployeeInfo';
import EmployerInfo from './steps/EmployerInfo';
import PolicyBeneficiaries from './steps/PolicyBeneficiaries';
import PolicyInfoContainer from './steps/policyInfo/PolicyInfoContainer';
import ReviewSign from './steps/ReviewSign';
import BdmLciConfigProvider from '../../../providers/BdmLciConfigProvider';
import useRouteCheck from '../../../hooks/useRouteCheck';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
const StyledSubTitle = styled(Heading) `
  text-transform: capitalize;
  font-size: 1.125rem !important;
`;
const EMPLOYER_INFO_INITIAL_STATE = {
    employerName: '',
    policyNumber: '',
    streetAddress1: '',
    city: '',
    postalcode: '',
    country: '',
};
const EMPLOYEE_INFO_INITIAL_STATE = {
    employeeId: '',
    ssn: '',
    firstName: '',
    lastName: '',
    middleInitial: '',
    phoneNumber: '',
    dob: '',
    gender: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    postalcode: '',
    state: '',
    province: '',
    country: '',
    maritalStatus: '',
};
const DEPENDENT_INFO_INITIAL_STATE = {
    firstName: '',
    lastName: '',
    dependentRelationship: '',
    dob: '',
    gender: '',
    ssn: '',
    phoneNumber: '',
};
const POLICYINFO_INITIAL_STATE = {
    workHours: '',
    earnings: 0.0,
    workStatus: '',
    dateEmployed: '',
    lastWorked: '',
    otherReason: '',
};
const CALLERINFO_INITIAL_STATE = {};
const ADDITIONAL_INFO_INITIAL_STATE = {};
const POLICY_BENEFICIARY_INITAL_STATE = {
    primary: [],
    contingent: [],
    primaryEqual: false,
    contingentEqual: false,
};
const getEventType = (event) => {
    if (event === 'death') {
        return 'DEATH';
    }
    if (event === 'total disability') {
        return 'DISABILITY';
    }
    if (event === 'dismemberment / loss of sight') {
        return 'DISMEMBERMENT';
    }
    if (event === 'accelerated death') {
        return 'ACCELERATED_DEATH';
    }
    return '';
};
const getClaimantType = (claimant, waiver) => {
    if (waiver === 'true') {
        return 'YES';
    }
    if (waiver === 'false') {
        return 'NO';
    }
    if (claimant === 'employee') {
        return 'EMPLOYEE';
    }
    if (claimant === 'dependent') {
        return 'DEPENDENT';
    }
    return '';
};
const LCI = () => {
    const [event, setEvent] = useState('');
    const [claimant, setClaimant] = useState('');
    const [waiver, setWaiver] = useState('');
    const [employeeLabel, setEmployeeLabel] = useState('');
    const [stepLabels, setStepLabels] = useState([]);
    const [sectionTitle, setSectionTitle] = useState('');
    const { setLciStarted, setLciCompleted } = useRouteCheck();
    const stepper = useContext(StepperContext);
    let showPolicyBeneficiaries = false;
    let showCallerInfo = false;
    let showDependentInfo = false;
    const { trackView, trackEvent } = useTealium();
    const authContext = useAuthentication();
    const customerName = authContext.user && authContext.user.customerDisplayName || authContext.user && authContext.user.customerInternalName;
    const getPolicyNumber = (context) => {
        return context?.customerDiv && context?.customerSerial ? context?.customerDiv + '-' + context?.customerSerial : '';
    };
    const policyNo = getPolicyNumber(authContext.user);
    function completeGettingStarted(values) {
        stepper.next();
        setEvent(values.event);
        setClaimant(values.claimant);
        setWaiver(values.premiumWaiver);
    }
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.LCI,
        });
    }, []);
    switch (event) {
        case 'death':
            showCallerInfo = true;
            showPolicyBeneficiaries = claimant !== 'dependent';
            showDependentInfo = claimant === 'dependent';
            break;
        case 'accelerated death':
        case `dismemberment / loss of sight`:
            showDependentInfo = claimant === 'dependent';
            break;
    }
    const [dependentInfo, setDependentInfo] = useState(DEPENDENT_INFO_INITIAL_STATE);
    const [policyInformation, setPolicyInformation] = useState(POLICYINFO_INITIAL_STATE);
    const [employerInfo, setEmployerInfo] = useState(EMPLOYER_INFO_INITIAL_STATE);
    const [employeeInfo, setEmployeeInfo] = useState(EMPLOYEE_INFO_INITIAL_STATE);
    const [callerInfo, setCallerInfo] = useState(CALLERINFO_INITIAL_STATE);
    const [additionalInfo, setAdditionalInfo] = useState(ADDITIONAL_INFO_INITIAL_STATE);
    const [confirmationData, setConfirmationData] = useState();
    const [policyBeneficiaries, setPolicyBeneficiaries] = useState(POLICY_BENEFICIARY_INITAL_STATE);
    const [displayComponent, setDisplayComponent] = useState('Steps');
    const [currentPage, setCurrentPage] = useState(0);
    const gettingStartedCompleted = !!event && !!(claimant || waiver);
    useEffect(() => {
        if (confirmationData) {
            setDisplayComponent('Confirmation');
        }
        else {
            setDisplayComponent('Steps');
            if (event && (claimant || waiver)) {
                setSectionTitle(StepTitle(employeeLabel)[event][claimant || waiver].sectionTitle);
            }
            setStepLabels((event && (claimant || waiver)) ? StepTitle(employeeLabel)[event][claimant || waiver].sections : ['Getting Started']);
            setCurrentPage(0);
        }
    }, [event, claimant, waiver, confirmationData]);
    useEffect(() => {
        return () => {
            setLciStarted(false);
            setLciCompleted(false);
        };
    }, []);
    const goBack = () => {
        setEvent('');
        setClaimant('');
        setWaiver('');
    };
    return (<ThemeProvider theme={themes.secureSite}>
      <BdmLciConfigProvider isBdmConfigRequired={false} data-public={true}>
        {displayComponent === 'Confirmation' && confirmationData && (<LCIConfirmation submissionId={confirmationData.submissionId} dateOfSubmission={confirmationData.dateOfSubmission} signatureInfo={confirmationData.signatureInfo} sectionTitle={sectionTitle} eventType={getEventType(event)} claimantType={getClaimantType(claimant, waiver)} sections={{
                employerInfo,
                employeeInfo,
                policyInformation,
                policyBeneficiaries,
                dependentInfo,
                callerInfo,
                additionalInfo,
            }}/>)}

        {displayComponent === 'Steps' && (<div data-public={true}>
            <Heading className={'mt-3'}>
              Report a New Life Claim
            </Heading>
            <StyledSubTitle elemType={'h2'}>
              {!gettingStartedCompleted ? 'Select a Life Claim Event' : sectionTitle}
            </StyledSubTitle>
            {/* @ts-ignore */}
            <Stepper onStepChange={setCurrentPage} stepTitles={stepLabels}>
              <LCIGettingStarted onComplete={completeGettingStarted} setEmployeeLabel={setEmployeeLabel}/>
              <EmployerInfo onComplete={setEmployerInfo} employerName={customerName} policyNumber={policyNo} goBack={goBack}/>
              <EmployeeInfo claimant={claimant} event={event} onComplete={setEmployeeInfo}/>
              <PolicyInfoContainer onComplete={setPolicyInformation} claimant={claimant} event={event}/>
              {showPolicyBeneficiaries && <PolicyBeneficiaries onComplete={setPolicyBeneficiaries}/>}
              {showDependentInfo && <DependentInfo onComplete={setDependentInfo}/>}
              {showCallerInfo && <CallerInfo title={claimant} onComplete={setCallerInfo} claimant={claimant} event={event}/>}
              <AdditionalInfo onComplete={setAdditionalInfo} claimant={claimant} event={event}/>
              <ReviewSign data-public={true} titles={stepLabels} onComplete={setConfirmationData} sectionTitle={sectionTitle} eventType={getEventType(event)} claimantType={getClaimantType(claimant, waiver)} sections={{
                employerInfo,
                employeeInfo,
                policyInformation,
                policyBeneficiaries,
                dependentInfo,
                callerInfo,
                additionalInfo,
            }}/>
            </Stepper>
          </div>)}
      </BdmLciConfigProvider>
    </ThemeProvider>);
};
export default LCI;
