import React from 'react';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../../../../hooks/useTranslate';
import styled from 'styled-components';
import { EoiGeneralText } from '../../../../i18n/EoiText';
const QuestionCol = styled(Col) `
  margin-bottom: 0.5rem;
  border-right: ${props => props.hideBorder ? undefined : `6px solid ${themes.lincoln.palette.grayLightest}`}
  @media (max-width: 500px) {
    border-right: none;
  }
`;
const AnswerCol = styled(Col) `
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 500px) {
    border-bottom: ${props => props.hideBorder ? undefined : `6px solid ${themes.lincoln.palette.grayLightest}`}
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 0;
  }
`;
const MedicalQuestionAnswerSection = (props) => {
    const { t } = useTranslate();
    //split note from question text
    const splitQuestionText = props.questionText && t(props.questionText).split('Note:');
    return (<div style={{ marginLeft: '1rem', marginBottom: '0.5rem', fontStyle: props.italicize ? 'italic' : undefined }} data-testid={props.dataTestid}>
      {props.label && <Label theme={themes.lincoln} style={{ marginBottom: '0.5rem' }}>{t(props.label)}</Label>}
      <Row>
        <QuestionCol sm={11} hideBorder={props.hideBorder}>
          {splitQuestionText && splitQuestionText[0]}
          {splitQuestionText && splitQuestionText[1] && <><br />
            <div style={{ marginTop: '.5rem' }} data-testid='question-note'>
              <em>{t(EoiGeneralText.note)}{splitQuestionText[1]}</em>
            </div></>}
            {props.questionNote && <><br />
            <div style={{ marginTop: '.5rem' }} data-testid='question-note-direct'>
              <em>{props.questionNote}</em>
            </div></>}
          
        </QuestionCol>
        <AnswerCol sm={1} hideBorder={props.hideBorder}>
          <b>{props.questionAnswer && t(props.questionAnswer)}</b>
        </AnswerCol>
      </Row>   
    </div>);
};
export default MedicalQuestionAnswerSection;
