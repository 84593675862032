import React, { useRef, useState, useEffect } from 'react';
import { TabSection, Alert, Icon, Button, CompositeTable, Spinner } from '@digitools/honest-react';
import useGet from '../../../../../hooks/useGet';
import { compareAsc, parse } from 'date-fns';
import useTealium from '../../../../../hooks/useTealium';
// @ts-ignore
import styles from '../NotesTable/NotesTable.scss';
import styled from 'styled-components';
import axios from 'axios';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import saveAs from 'file-saver';
const NoteDiv = styled('div') `
  background-color: white;
  width: 99%;
  margin-right: 1rem;
  margin-left: -.8125rem;
  padding-left: 3rem;
`;
const ExpandCollapseCell = ({ data: { _original: { accmNum, noteTxt }, }, }) => {
    const noteRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const toggleExpand = () => {
        setExpanded(!expanded);
    };
    const setNoteHeight = () => {
        // @ts-ignore
        setHeight(noteRef.current.clientHeight);
    };
    useEffect(() => {
        // @ts-ignore
        setHeight(noteRef.current.clientHeight);
        window.addEventListener('resize', setNoteHeight);
        return () => window.removeEventListener('resize', setNoteHeight);
    });
    return (<div style={{ paddingBottom: `calc(${height}px + 0.5rem)` }} data-expanded={expanded} data-testid='expand-collapse-cell'>
        
      <Button onClick={toggleExpand} size={'small'} className={styles.icon}>
        {expanded
            ? <Icon name='subtract' color={'primary'} data-testid={'notes-tab-collapse'}/>
            : <Icon name='add' color={'primary'} data-testid={'notes-tab-expand'}/>}
      </Button>
      {accmNum}  
      <NoteDiv ref={noteRef} className={styles.noteRow} data-expanded={expanded}>
        {noteTxt}
      </NoteDiv>
    </div>);
};
/**
 * Represents a TabSection for AS (Accommodation Status) Notes
 * rendered by the AccommodationDetails page. (permissions handled there)
 */
const NotesTab = ({ accommodationId, employeeNumber, }) => {
    const { trackEvent } = useTealium();
    const { authHeader } = useAuthentication();
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadError, setIsDownloadError] = useState(false);
    const { data: notesData, loading: notesLoading, error: notesError } = useGet({
        url: `/status-service/accommodations/${accommodationId}/notes`,
    }) || {}; // the || {} is for tests where this useGet is not mocked out.. prevents undefined
    const notesColumns = [
        {
            Header: 'Request Number',
            accessor: '',
            id: 'expandCollapse',
            Cell: ExpandCollapseCell,
            sortMethod: (a, b) => (a.accmNum === b.accmNum ? 0 : (a.accmNum < b.accmNum ? -1 : 1)),
        },
        {
            Header: 'Note type',
            accessor: 'noteType',
            id: 'noteType',
        },
        {
            Header: 'Topic',
            accessor: 'topic',
            id: 'topic',
        },
        {
            Header: 'Date created',
            accessor: 'rowEntryTimeStamp',
            id: 'rowEntryTimeStamp',
            sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
    ];
    const defaultNotesSort = [
        {
            id: 'rowEntryTimeStamp',
            desc: true,
        },
    ];
    const downloadAllNotes = async () => {
        try {
            setIsDownloading(true);
            const response = await axios.get(`/status-service/report/${employeeNumber}/${accommodationId}/asNotes`, {
                responseType: 'blob',
                headers: { Authorization: authHeader(), }
            });
            const filename = `LFG Accommodation #${accommodationId} Notes.pdf`;
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            // @ts-ignore
            saveAs(blob, filename);
            setIsDownloadError(false);
            setIsDownloading(false);
        }
        catch (error) {
            // const status = error.response.status; //do something with status later?
            setIsDownloadError(true);
            setIsDownloading(false);
        }
    };
    /**
     * Render loading spinner inside tab section while data loads
     * Render info alert if data comes back empty
     * Render error alert if data results in an error
     * Render table for non-zero length data
     */
    return (<TabSection name='notes-tab' label='Notes' onClick={() => trackEvent({ event_action: TEALIUM_EVENT.EVENT_ACTION.TAB, event_type: TEALIUM_EVENT.EVENT_TYPE.AS_NOTES, event_name: 'AS Notes' })}>
      {notesLoading && <div data-testid={'notes-table-loading'}><Spinner id='notes-tab-spinner1'/></div>}
      {!notesLoading && notesData && notesData.length > 0 && (<>
          {isDownloadError &&
                <Alert data-testid={'download-notes-alert'} type={'error'} closeable={true} onClose={() => setIsDownloadError(false)}>
              There was a problem downloading the notes for this accommodation, please try again later.
            </Alert>}
          <div style={{ textAlign: 'right', marginBottom: '.5rem' }}>
            <Button data-testid={'downloadAllNotes'} onClick={downloadAllNotes} disabled={isDownloading} buttonType={'text'}>
              {isDownloading && <Spinner id='notes-tab-spinner2' size='small' style={{ float: 'left', margin: '0px', marginTop: '-.25rem', marginRight: '.5rem' }}/>}
              Download All Notes
              <Icon name={'download'} color={'secondary'} style={{ marginLeft: '.5rem' }}/>
            </Button>
          </div>
          <CompositeTable data-testid='notes-table' data={notesData} columns={notesColumns} defaultSorted={defaultNotesSort} paginated={true} defaultPageSize={10}/>
        </>)}
      {!notesLoading && (notesData && notesData.length === 0) && (<Alert data-testid='notes-table-nodata' type='info'>
          There are no notes items for this accommodation.
        </Alert>)}
      {!notesLoading && notesError && (<Alert data-testid='notes-table-error' type='error'>
          There was an error retrieving notes for this accommodation.
        </Alert>)}
    </TabSection>);
};
export default NotesTab;
