import { Col, Container, Heading, Row } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { getEnvVariable } from 'src/utils/envUtils';
import styled from 'styled-components';
import { TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
import useAuthentication from '../../hooks/useAuthentication';
import useExperience from '../../hooks/useExperience';
import useTealium from '../../hooks/useTealium';
import CommPrefContainer from '../../packages/employeeProfile/components/commPref/CommPrefContainer';
import { Language } from 'src/types/Language';
import SecurityAndSettings from '../securityAndSettings/SecurityAndSettings';
import UserProfileInformation from '../UserProfileInformation/UserProfileInformation';
import useTranslate from 'src/hooks/useTranslate';
import ProveText from '../prove/ProveText';
import ServiceUnavailable from 'src/components/ServiceUnavailable/ServiceUnavailable';
import Prove from 'src/components/prove/Prove';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import useCustomerConfig from 'src/hooks/useCustomerConfig';
const StyledNavHeading = styled(Heading) `
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
`;
const StyledNavLink = styled(NavLink) `
  color: ${({ theme }) => theme.palette.black};
  display: block;
  padding: 0.25rem 0 0.25rem 0.5rem;

  &.active {
    color: ${({ theme }) => theme.palette.primary};
    font-weight: 500;
    border-left: 3px solid ${({ theme }) => theme.palette.primary};
  }
`;
const profileLinksEn = [
    {
        href: 'userInfo',
        title: 'userInfo',
        text: 'Profile information',
    },
    {
        href: 'password',
        title: 'password',
        text: 'Security settings',
    },
    {
        href: 'alertsNotifications',
        title: 'alertsNotifications',
        text: 'Alerts & notifications',
    },
];
const profileLinksEs = [
    {
        href: 'userInfo',
        title: 'userInfo',
        text: 'Información de perfil',
        gaName: 'Profile information',
    },
    {
        href: 'password',
        title: 'password',
        text: 'Seguridad configuración',
        gaName: 'Security settings',
    },
    {
        href: 'alertsNotifications',
        title: 'Alertas y notificaciones',
        text: 'Alertas y notificaciones',
        gaName: 'Alerts & notifications',
    },
];
const profileLinksLFGEn = [
    {
        href: 'userInfo',
        title: 'userInfoLFG',
        text: 'Profile information',
    },
    {
        href: 'password',
        title: 'passwordLFG',
        text: 'Security settings',
    },
    {
        href: 'alertsNotifications',
        title: 'alertsNotifications',
        text: 'Alerts & notifications',
    },
];
const profileLinksLFGEs = [
    {
        href: 'userInfo',
        title: 'userInfoLFG',
        text: 'Información de perfil',
        gaName: 'Profile information',
    },
    {
        href: 'password',
        title: 'passwordLFG',
        text: 'Seguridad configuración',
        gaName: 'Security settings',
    },
    {
        href: 'alertsNotifications',
        title: 'Alertas y notificaciones',
        text: 'Alertas y notificaciones',
        gaName: 'Alerts & notifications',
    },
];
const ReRoute = ({ urlPath }) => {
    useEffect(() => {
        const url = `${getEnvVariable('lfgHomeUrl')}${urlPath}`;
        window.open(url, '_blank');
    }, []);
    return <></>;
};
const EmployeeProfile = () => {
    const { user } = useAuthentication();
    const { isCommPrefEnabled } = useCustomerConfig();
    const { language, experience } = useExperience();
    const { trackView } = useTealium();
    const { t } = useTranslate();
    const [navLinks, setNavLinks] = useState([{ href: '', text: '' }]);
    const pageL4 = CustomEmployeeText(experience === Experience.EMPLOYEE ? 'employee view' : 'employer view');
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EMPLOYEE_PROFILE,
            page_l4: pageL4,
        });
    }, []);
    useEffect(() => {
        let links = language === Language.SPANISH ? profileLinksEs : profileLinksEn;
        if (user?.isLfgUser && !user?.auth0Roles?.includes('MLPEmployee')) {
            links = language === Language.SPANISH ? profileLinksLFGEs : profileLinksLFGEn;
        }
        if (user && user.ssoUser || (user && user.isLfgUser && user.auth0Roles?.includes("PA"))) {
            links = links.filter(link => link.href !== 'password');
        }
        if (user && user.isLfgUser && user.auth0Roles?.includes("PA")) {
            links = links.filter(link => link.href !== 'userInfo');
        }
        if (!isCommPrefEnabled) {
            links = links.filter(link => link.href !== 'alertsNotifications');
        }
        if (!user?.isIdentityVerified && user?.isLfgUser) {
            const title = t(ProveText.verifyHeadingAlt);
            const accountAccess = {
                href: 'accountAccess', //FIXME: pretty sure this is ignored
                title: 'accountAccess', //FIXME: sideNav is messed up and uses title instead of href when you click
                text: title,
                gaName: ProveText.verifyHeadingAlt.english,
            };
            links = [accountAccess, ...links];
        }
        // @ts-ignore
        setNavLinks(links);
    }, [isCommPrefEnabled, language, user]);
    const SecuritySettingsTemp = () => {
        const url = getEnvVariable('lfgHomeUrl') + '/secure/consumer/securitysettings#/consumer';
        window.open(url, '_blank');
        return null;
    };
    const ProfileInformationTemp = () => {
        const url = getEnvVariable('lfgHomeUrl') + '/secure/consumer/profileinformation#/consumer';
        window.open(url, '_blank');
        return null;
    };
    const UserProfileWrapper = ({ children }) => {
        const { user } = useAuthentication();
        if (!user) {
            return <ServiceUnavailable />;
        }
        return (<Container>
        <Row className={'mt-3'} style={{ marginBottom: '1.1rem' }}>
          <Col>
            <StyledNavHeading data-public={true} elemType={'h2'} color={'black'} id='profileHeading'>
              {language === Language.SPANISH ? 'Bienvenidas' : 'Welcome'}, {user.firstName}
            </StyledNavHeading>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={4} style={{ paddingRight: '2%', marginTop: '6px' }}>
            <nav>
              {navLinks.map(link => (<StyledNavLink data-public={true} to={link.href}>{link.text}</StyledNavLink>))}
            </nav>
          </Col>
          <Col md={8} sm={7} className={'mt-1'} style={{ margin: '0' }}>
            {children}
          </Col>
        </Row>
      </Container>);
    };
    return (<>
      <UserProfileWrapper>
        <Routes>
          <Route path={`userInfo`} element={<UserProfileInformation />}/>
          <Route path={`userInfoLFG`} element={<ProfileInformationTemp />}/>
          <Route path={`accountAccess`} element={<Prove altDisplay/>}/>
          {!user.ssoUser && <Route path={`password`} element={<SecurityAndSettings />}/>}
          {!user.ssoUser && <Route path={`passwordLFG`} element={<SecuritySettingsTemp />}/>}
          {isCommPrefEnabled && <Route path={`alertsNotifications`} element={<CommPrefContainer />}/>}
          <Route path={`*`} element={<Navigate to={`userInfo`}/>}/>
        </Routes>
      </UserProfileWrapper>
    </>);
};
export default EmployeeProfile;
