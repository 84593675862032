import React, { useContext, useState, useEffect } from 'react';
import { Box, Form, SelectInput, Row, Icon, TextInput, Col, Button, StepperContext, Alert, } from '@digitools/honest-react';
// @ts-ignore
import styles from './LoginBoxStyles.scss';
import { validateRequestor, validateEmpID, validateSelectGeneric, validateDate } from './../../utils/Validator';
import Footer from './Footer';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
const LoginBox = ({ employeeLookupStatus, resetStatus, getEmployee, setEmployeeState }) => {
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.USAA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.USAA.LOGIN,
        });
    }, []);
    const stepper = useContext(StepperContext);
    const [formApi, setFormApi] = useState();
    const resetFormState = () => {
        if (formApi) {
            formApi.reset();
        }
    };
    /* trigger form state reset */
    if (resetStatus === true) {
        resetFormState();
    }
    const handleContinue = async (values) => {
        trackClickWithPage('Log In', '/uaMobile/login');
        const responseStatus = await getEmployee(values.employeeId, values.birthMonth + values.birthDay);
        if (responseStatus === 200) {
            stepper.next();
        }
        setEmployeeState(values);
    };
    const createArrayOfDaysSelect = (input) => {
        return Array(input)
            .fill(null)
            .map((value, i) => {
            if (i < 9) {
                return { label: `0${i + 1}`, value: `0${i + 1}` };
            }
            else {
                return { label: `${i + 1}`, value: `${i + 1}` };
            }
        });
    };
    const getStuff = () => {
        let days = [];
        if (formApi) {
            switch (formApi.getValue('birthMonth')) {
                case '01':
                    days = createArrayOfDaysSelect(31);
                default:
                    days = createArrayOfDaysSelect(3);
            }
        }
        else {
            days = createArrayOfDaysSelect(31);
        }
        return days;
    };
    const render31Days = () => {
        return (<SelectInput data-testid={'birthDay'} field={'birthDay'} label={'Day'} validateOnBlur={true} placeholderText={'Day'} validate={validateDate} options={createArrayOfDaysSelect(31)} type={'text'}/>);
    };
    const render30Days = () => {
        return (<SelectInput data-testid={'birthDay'} field={'birthDay'} label={'Day'} validateOnBlur={true} placeholderText={'Day'} validate={validateDate} options={createArrayOfDaysSelect(30)} type={'text'}/>);
    };
    const render29Days = () => {
        return (<SelectInput data-testid={'birthDay'} field={'birthDay'} label={'Day'} validateOnBlur={true} placeholderText={'Day'} validate={validateDate} options={createArrayOfDaysSelect(29)} type={'text'}/>);
    };
    const months = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];
    const requesterTypes = [
        { label: 'Employee', value: 'E' },
        { label: 'Manager', value: 'M' },
        { label: 'Other', value: 'O' },
    ];
    return (<>
      {employeeLookupStatus !== 200 && employeeLookupStatus !== 0 && (<Alert type={'error'}>Something went wrong, please try again.</Alert>)}
      {/* <Alert type={'warning'}><b>Important!</b> The Lincoln Financial Group website will be undergoing maintenance. It will be unavailable on Sunday, February 23rd from 5am to approximately 11am EST.  Any unplanned time off that needs to be reported during this time should be done via telephone at 1-800-210-USAA (8722), option 1. We apologize for any inconvenience.
        </Alert> */}
      <Form getApi={setFormApi} onSubmit={handleContinue}>
        {({ formState }) => (<Box color={'grayLighter'}>
            <h5 style={{ color: '#002663' }}>
              <span>
                <Icon style={{ color: '#002663' }} name={'lock'}/>
              </span>{' '}
              Log In
            </h5>
            <SelectInput data-testid={'enteredBy'} field={'enteredBy'} label={'Requester Type'} placeholderText={'Select a type'} validateOnBlur={true} validateOnChange={true} validate={validateRequestor} options={requesterTypes}/>
            <br />
            <TextInput data-testid={'employeeID'} field={'employeeId'} label={'Employee ID'} validateOnBlur={true} validate={validateEmpID} helperText={'Enter your 5 character Employee ID'} type={'text'}/>
            <br />
            <Row>
              <Col>
                <SelectInput data-testid={'birthMonth'} field={'birthMonth'} label={'Birth Month'} placeholderText={'Month'} validateOnBlur={true} initialValue={'01'} onChange={getStuff} validate={validateSelectGeneric} options={months}/>
              </Col>
              <Col>
                {
            // @ts-ignore
            ['01', '03', '05', '07', '08', '10', '12'].includes(formState.values.birthMonth) ? (render31Days()) : (<></>)}
                {
            // @ts-ignore
            ['04', '06', '09', '11'].includes(formState.values.birthMonth) ? render30Days() : <></>}
                {formState.values.birthMonth === '02' ? render29Days() : <></>}
              </Col>
            </Row>
            <br />
            <Row style={{ display: 'flex', textAlign: 'center' }}>
              <Col>
                <Button data-testid={'loginButton'} id='loginButton' className={styles['button']} buttonType={'primary'} type={'submit'} disabled={formState.invalid}>
                  Log In
                </Button>
              </Col>
            </Row>
          </Box>)}
      </Form>
      <br />
      <Footer />
    </>);
};
export default LoginBox;
