import React from 'react';
import { Row, Col, Alert, Container, Icon } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import checkDate from 'status/utilities/checkDate';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { isDev } from 'src/utils/envUtils';

const Error = () => (
  <Alert type={'error'}>
    <span>
      <strong>Error!</strong>&nbsp;Unable to load claims dates.
    </span>
  </Alert>
);

const ClaimDates = ({ claim }) => {
  const { user } = useAuthentication();

  const { t } = useTranslation();

  const isSTDLTD = claim.prodType === 'STD' || claim.prodType === 'LTD';

  if (!claim) {
    return <Error />;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Row>
            <Col md={5}>
              <strong>{t('claimReceivedText')}</strong>
            </Col>
            <Col md={7}>{claim.clmRecdDte && claim.clmRecdDte.split(' ')[0]}</Col>
          </Row>
          <Row>
            <Col md={5}>
              <strong>{t('lastDateWorkedText')}</strong>
            </Col>
            <Col md={7}>{checkDate(claim.clmLastWrkDte)}</Col>
          </Row>
          <Row>
            <Col md={5}>
              <strong>{t('dateOfDisabilityText')}</strong>
            </Col>
            <Col md={7}>{checkDate(claim.clmDisabDte)}</Col>
          </Row>
          <Row className={'mb-4'}>
            <Col md={5}>
              <strong>{t('determinationDateText')}</strong>
            </Col>
            <Col md={7}>{checkDate(claim.clmDtrmDte)}</Col>
          </Row>
          <Row>
            <Col md={5}>
              <strong>{t('benefitsBeginText')}</strong>
            </Col>
            <Col md={7}>{checkDate(claim.benBeginDte)}</Col>
          </Row>
          <Row>
            <Col md={5}>
              {user?.customerInContext?.smallMid ? '' : isSTDLTD && <strong>*</strong>}
              {user?.customerInContext?.smallMid ? (
                <strong>{t('approvedToText')}</strong>
              ) : (
                <strong>{t('approvedThroughText')}</strong>
              )}
            </Col>
            <Col md={7}>{checkDate(claim.disabApprvThruDte)}</Col>
          </Row>
          <Row className={'mb-4'}>
            <Col md={5}>
              <strong>{t('benefitsEndText')}</strong>
            </Col>
            <Col md={7}>{checkDate(claim.benEndDte)}</Col>
          </Row>
          <Row>
            <Col md={5}>
              <strong>{t('reopenDateText')}</strong>
            </Col>
            <Col md={7}>{checkDate(claim.clmReopnDte)}</Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md={6}>
              <strong>{t('returnToWorkEstimatedText')}</strong>
            </Col>
            <Col md={6}>{checkDate(claim.clmExpecRtrnWrkDte)}</Col>
          </Row>
          <Row>
            <Col md={6}>
              <strong>{t('returnToWorkModifiedText')}</strong>
            </Col>
            <Col md={6}>{checkDate(claim.partDtyRtrnWrkDte)}</Col>
          </Row>
          <Row>
            <Col md={6}>
              <strong>{t('returnToWorkActualText')}</strong>
            </Col>
            <Col md={6}>{checkDate(claim.fullDtyRtrnWrkDte)}</Col>
          </Row>
          {isDev() && (user?.customerInContext?.smallMid && !claim.fullDtyRtrnWrkDte) && (<Row> <Col md={6}><Icon name='info' color={'secondary'} style={{ paddingRight: '.25rem' }} />
            {t('returnToWorkNumberText')} <a href="tel:8004232765">800-423-2765.</a></Col></Row>)}
        </Col>
      </Row>
      {user?.customerInContext?.smallMid
        ? ''
        : isSTDLTD && (
          <Row data-testid='date-disclaimer'>
            <i>* {t('dateDisclaimerText')}</i>
          </Row>
        )}
    </Container>
  );
};

export default ClaimDates;
