import { TextInput, Row, Col, themes, RadioGroup, Radio } from '@digitools/honest-react';
import { Scope, useFormState } from 'informed';
import { AboutYourAbsenceText } from 'src/packages/cli/constants/AboutYourAbsenceText';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
export const shouldDisplayClaimantCondition = (leaveType, leaveCategory, isClaimCustomer, isLeaveCustomer, 
// TODO: @Ryan Look into why this does not like boolean type
inValidPaidLeaveState) => {
    return ((leaveType === LeaveType.OWN_ILLNESS && leaveCategory === 'C') || (isClaimCustomer && !isLeaveCustomer && !inValidPaidLeaveState));
};
/**
 * Sub-form for Absence data - LeaveType and LeaveCategory fields
 * These fields are common to all leaveTypes and help drive the rest of the absenceData form
 */
const ClaimantCondition = () => {
    const { t } = useTranslate();
    const { claimantData, absenceData } = useCliDataContext();
    const { experience } = useExperience();
    const { claimantCondition: claimantConditons, // TODO rename this in backend to be plural to indicate it's a list
    isLeaveCustomer, isClaimLeaveCustomer, isClaimCustomer, paidLeaveStates } = useCliCustomerConfigContext();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const inValidPaidLeaveState = claimantData &&
        claimantData.selectedEmpStateID &&
        paidLeaveStates.includes(claimantData.selectedEmpStateID);
    // @ts-ignore
    const leaveType = values?.absenceData?.leaveType;
    // @ts-ignore
    const leaveCategory = values?.absenceData?.leaveCategory;
    return (<>
      <div data-testid='claimant-condition'>
        {/* @ts-ignore */}
        <Scope scope='absenceData'>
          {/* default hidden value of 'P' for leaveType of Pregnancy with a continuous leaveCategory */}
          {(leaveType === LeaveType.PREGNANT && claimantData?.gender === 'F') && (<TextInput hidden={true} skinny={true} hideLabel={true} label={''} field='claimantCondition' initialValue={'P'}/>)}
          {/* show question for Claim only customer -OR- leaveCategory is continous for OWN leaveType */}
          {shouldDisplayClaimantCondition(leaveType, leaveCategory, isClaimCustomer, isLeaveCustomer, inValidPaidLeaveState) && (<Row className='mb-3'>
              <Col xs={12}>
                <RadioGroup label={experience === Experience.EMPLOYEE ? t(formLabels.claimantConditionEE) : t(formLabels.claimantConditionER)} field={'claimantCondition'} theme={themes.lincoln} legendStyles={{ marginBottom: '.5rem' }} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.claimantCondition}>
                  {claimantConditons.map(claimantCondition => {
                // Hide Pregnancy option from Male claimants
                if (claimantCondition.code === 'P' && claimantData?.gender === 'M') {
                    return null;
                }
                else if (claimantCondition.code === 'P' && isLeaveCustomer && leaveType === LeaveType.OWN_ILLNESS && leaveCategory === 'C') {
                    return null;
                }
                return (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'cc-' + claimantCondition.code} key={'cc-' + claimantCondition.code} id={'cc-' + claimantCondition.code} name='claimantCondition' label={t(claimantCondition)} value={claimantCondition.code}/>);
            })}
                </RadioGroup>
              </Col>
            </Row>)}
        </Scope>
      </div>
    </>);
};
export default ClaimantCondition;
