import React, { useContext } from 'react';
import { StepperContext, Row, Col, Collapse, themes } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import useCliDataContext from '../../hooks/useCliDataContext';
import ReviewField, { EditReviewField } from './ReviewField';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import { ReviewText } from 'src/packages/cli/constants/ReviewText';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const ReviewPersonalIdentification = () => {
    const stepper = useContext(StepperContext);
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { claimantData } = useCliDataContext();
    const cliConfigContext = useCliCustomerConfigContext();
    const formatSsn = (ssn) => {
        return "xxx-xx-" + ssn.substring(5);
    };
    const SECTION_HEADER = CustomEmployeeText(t(experience === 'Employer' ? ReviewText.verification.pageTitleER : ReviewText.verification.pageTitleEE));
    return (<div className={`mb-3`} data-testid="ReviewPersonalIdentification" data-public={true}>
            <Collapse headerRightDisplay={<EditReviewField data-testid="editButtonIdentificationTestId" onClick={() => { stepper.edit(0); }}/>} isOpen={true} theme={themes.lincoln} type='alternate' title={SECTION_HEADER}>
                <Row>
                    {claimantData && claimantData?.ssn &&
            <Col lg={6} md={6} sm={6} xs={6} data-testid="ssnField">
                            <ReviewField label={t(ReviewText.verification.ssnLabel)} value={formatSsn(claimantData && claimantData?.ssn)}/>
                        </Col>}
                    {claimantData && claimantData?.empID &&
            <Col lg={6} md={6} sm={6} xs={6} data-testid="empIdField">
                            <ReviewField label={cliConfigContext && cliConfigContext.empIdText && t(cliConfigContext?.empIdText)} value={claimantData && claimantData?.empID}/>
                        </Col>}
                </Row>
            </Collapse>
        </div>);
};
export default ReviewPersonalIdentification;
