import { Label, SelectInput } from '@digitools/honest-react';
import { Scope, useFormApi, useFormState } from 'informed';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import { AboutYourAbsenceText } from 'src/packages/cli/constants/AboutYourAbsenceText';
import useCliCustomerConfigContext from 'src/packages/cli/hooks/useCliContext';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import { LeaveType } from 'src/packages/cli/providers/CliConfigProvider';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
const fmcLeaveReasons = ['CH', 'SK', 'PA', 'SP', 'DV', 'OT', 'CS'];
const bndLeaveReasonsFemale = ['NM', 'AD', 'FC', 'SS', 'OT'];
const bndLeaveReasonsMale = ['NP', 'AD', 'FC', 'SS', 'OT'];
const ownLeaveReasonsFemale = ['AT', 'CC', 'DV', 'HC', 'MT', 'LT', 'PR', 'WR', 'OT'];
const ownLeaveReasonsMale = ['AT', 'CC', 'DV', 'HC', 'MT', 'LT', 'WR', 'OT'];
export const othLeaveReasons = ['AM', 'AS', 'AA', 'BR', 'CA', 'CF', 'CM', 'CO', 'CT', 'CV', 'DP', 'DA', 'ED', 'JD', 'MW', 'PT', 'PE', 'PS', 'RL', 'SA', 'SE', 'SC', 'A1', 'A2', 'A3', 'A4', 'A5', 'UB', 'WC', 'OD', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C0'];
const xmlLeaveReasons = ['ML', 'MD', 'EX'];
export const shouldDisplayLeaveReason = (leaveType, leaveCategory) => {
    const shouldDisplay = leaveType && leaveCategory && ((leaveType === LeaveType.FAMILY_CARE || leaveType === LeaveType.PARENTAL || leaveType === LeaveType.OTHER || leaveType === LeaveType.MILITARY) ||
        (leaveType === LeaveType.OWN_ILLNESS && leaveCategory === 'I'));
    return shouldDisplay;
};
const LeaveReason = () => {
    const { t } = useTranslate();
    const { absenceData, claimantData } = useCliDataContext();
    const { leaveReasons } = useCliCustomerConfigContext();
    const { values } = useFormState();
    const { setValue } = useFormApi();
    const { experience } = useExperience();
    const { formLabels } = AboutYourAbsenceText;
    //@ts-ignore
    const leaveType = values?.absenceData?.leaveType;
    //@ts-ignore
    const leaveCategory = values?.absenceData?.leaveCategory;
    const shouldDisplay = shouldDisplayLeaveReason(leaveType, leaveCategory);
    const getLeaveReasonDisplayOptions = () => {
        let potentialList = [];
        switch (leaveType) {
            case LeaveType.FAMILY_CARE:
                potentialList = fmcLeaveReasons;
                break;
            case LeaveType.OWN_ILLNESS:
                if (claimantData?.gender === 'M') {
                    potentialList = ownLeaveReasonsMale;
                }
                else {
                    potentialList = ownLeaveReasonsFemale;
                }
                break;
            case LeaveType.OTHER:
                potentialList = othLeaveReasons;
                break;
            case LeaveType.MILITARY:
                potentialList = xmlLeaveReasons;
                break;
            case LeaveType.PARENTAL:
                if (claimantData?.gender === 'M') {
                    potentialList = bndLeaveReasonsMale;
                }
                else {
                    potentialList = bndLeaveReasonsFemale;
                }
                break;
            default:
                break;
        }
        // Filter out only codes found in the potention list
        const leaveReasonOptions = leaveReasons.filter(leaveReason => potentialList.includes(leaveReason.code));
        // Convert to display options for input
        const leaveReasonDisplayOptions = leaveReasonOptions.map(leaveReasonOption => {
            return ({
                label: t(leaveReasonOption),
                value: leaveReasonOption.code,
            });
        });
        return leaveReasonDisplayOptions;
    };
    const leaveReasonLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ?
        t(formLabels.leaveReason) :
        `${t(formLabels.leaveReasonER)}`);
    return (<>
      {shouldDisplay && (<>
          {/* @ts-ignore */}
          <Scope scope='absenceData'>
            <Label className='mb-2' htmlFor='leaveReason'>
              {leaveReasonLabel}
            </Label>
            <SelectInput field={'leaveReason'} label={t(formLabels.leaveReasonShortLabel)} options={getLeaveReasonDisplayOptions()} data-testid={'leave-reason'} validateOnBlur={true} onChange={() => setValue('absenceData.relationship', undefined)} validate={fieldRequired} initialValue={absenceData?.leaveReason}/>
          </Scope>
        </>)}
    </>);
};
export default LeaveReason;
