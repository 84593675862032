import React, { useState, useEffect } from 'react';
import Box from '@digitools/honest-react/dist/lib/components/Box';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
// @ts-ignore
import EmployeeInsuranceIcon from './../assets/employee_insurance.svg';
// @ts-ignore
import SpouseIcon from './../assets/spouse.svg';
// @ts-ignore
import DependentIcon from './../assets/dependent_child.svg';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import styled from 'styled-components';
import { customize } from '../utils/HelperFunctions';
import { ApplicantInformationText } from '../i18n/ApplicantInformationText';
import useTranslate from '../../../hooks/useTranslate';
import { ApplicantCoverageText } from '../i18n/ApplicantCoverageText';
import { EoiGeneralText } from '../i18n/EoiText';
import InputMessage from '@digitools/honest-react/dist/lib/components/InputMessage';
import useEoiContext from '../hooks/useEoiContext';
const ApplicantInfoDiv = styled('div') `
display: flex;
flex-direction: row;
@media(max-width: 500px) {
  flex-direction: column;
}
`;
const ApplicantInfoIconDiv = styled('div') `
display: flex;
flex-direction: row;
margin-top: auto;
margin-bottom: auto;
padding-right: 1rem;
@media(max-width: 500px) {
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0;
}
`;
const ApplicantInfoEditButton = styled(Button) `
margin-left: auto;
margin-top: auto;
margin-bottom: auto;
padding-right: 0;
`;
const ApplicantContainer = ({ applicant, type, startCollapsed = false, showEditButton = false, error, errorText, firstName = 'First', lastName = 'Last', children, }) => {
    const [expanded, setExpanded] = useState(!startCollapsed);
    const { customContent, validationError, initialInformation } = useEoiContext();
    const { t } = useTranslate();
    useEffect(() => {
        if (!expanded) {
            setExpanded(true);
        }
    }, [validationError]);
    let icon;
    let headerText;
    let subHeadingText = 'subHeading';
    // FIXME: These icons suck - swapt hem out for stuff that actually makes more sense and is consistent
    if (applicant === 'employee') {
        icon = <EmployeeInsuranceIcon height='56px' width='51px' alt={'Employee coverage document icon'}/>;
        if (type === 'information') {
            headerText = t(ApplicantInformationText.employeeInformation);
        }
        else if (type === 'coverage') {
            headerText = t(ApplicantCoverageText.employeeCoverage);
        }
        else { // type must be 'name'
            headerText = `${firstName} ${lastName}`;
            subHeadingText = ''; // don't display a subheading for employee.
        }
    }
    else if (applicant === 'spouse') {
        icon = <SpouseIcon height='56px' width='51px' alt={'silhouette with pen and paper'}/>;
        if (type === 'information') {
            headerText = t(customize(ApplicantInformationText.spouseInformation, customContent, initialInformation));
        }
        else if (type === 'coverage') {
            headerText = t(customize(ApplicantCoverageText.spouseCoverage, customContent, initialInformation));
        }
        else { // type must be 'name'
            headerText = `${firstName} ${lastName}`;
            subHeadingText = t(customize(EoiGeneralText.spouseLabel, customContent, initialInformation)).toUpperCase();
        }
    }
    else if (applicant === 'dependent') {
        icon = <DependentIcon height='56px' width='51px' alt={'two silhouettes'}/>;
        if (type === 'information') {
            headerText = t(ApplicantInformationText.dependentInformation);
        }
        else if (type === 'coverage') {
            headerText = t(ApplicantCoverageText.dependentCoverage);
        }
        else { // type must be 'name'
            headerText = `${firstName} ${lastName}`;
            subHeadingText = t(EoiGeneralText.dependentLabel).toUpperCase();
        }
    }
    else {
        icon = <DependentIcon height='56px' width='51px' alt={'two silhouettes'}/>;
        headerText = 'Family Coverage';
        // TODO: need spanish tranlsations for this
    }
    const MainHeading = (<Heading theme={themes.lincoln} color={'grayDark'} elemType={'h2'} className={'align-self-center align-self-md-none'} style={{ marginTop: 'auto', marginBottom: 'auto' }} data-private={type === 'name'}>
      {headerText}
    </Heading>);
    return (<>
      {customContent &&
            <Box data-testid={`${applicant}-${type}-container`}>
          <ApplicantInfoDiv data-public={true}>
            <ApplicantInfoIconDiv>
              {icon}
            </ApplicantInfoIconDiv>
            {type !== 'name' ?
                    MainHeading
                    :
                        <div>
                {MainHeading}
                <Heading style={{ marginTop: '.25rem' }} theme={themes.lincoln} color={'grayDark'} elemType={'h3'} elemStyle={'h6'}>
                  {subHeadingText}
                </Heading>
              </div>}
            {showEditButton &&
                    <ApplicantInfoEditButton className={'pt-2 pt-md-0 ml-0 ml-md-auto pr-3 pr-md-0 pl-0'} type={'button'} theme={themes.lincoln} onClick={() => setExpanded(!expanded)}>
                <Icon name={expanded ? 'subtract' : 'add'} color={'primary'} theme={themes.lincoln} style={{ marginRight: '0.5rem' }}/>
                {t(EoiGeneralText.edit)}
              </ApplicantInfoEditButton>}
          </ApplicantInfoDiv>
          <div hidden={!expanded}>
            {children}
          </div>
          <InputMessage error={error && !expanded} data-public={true}>
            {errorText && t(errorText)}
          </InputMessage>
        </Box>}
    </>);
};
export default ApplicantContainer;
