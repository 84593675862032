// TODO: spanish text update (story needed ) - this is roughly what's there today
export const FooterText = {
    accessibility: { english: 'Accessibility', spanish: 'Accesibilidad' },
    glossary: { english: 'Glossary of terms', spanish: 'Glosario de términosa' },
    companyOverview: { english: 'Company Overview', spanish: 'Descripción General de la Empresa' },
    privacy: { english: 'Privacy', spanish: 'Intimidad' },
    legal: { english: 'Legal', spanish: 'Legal' },
    googleAnalytics: { english: 'Google Analytics', spanish: 'Google Analitico' },
    content: {
        english: 'Lincoln Financial Group is the marketing name for Lincoln National Corporation and insurance company affiliates, including The Lincoln National Life Insurance Company, Fort Wayne, IN, and in New York, Lincoln Life & Annuity Company of New York, Syracuse, NY. Affiliates are separately responsible for their own financial and contractual obligations.',
        spanish: 'Lincoln Financial Group es el nombre comercial de Lincoln National Corporation y las filiales de las compañías de seguros, incluidas The Lincoln National Life Insurance Company, Fort Wayne, IN, y en Nueva York, Lincoln Life & Annuity Company of New York, Syracuse, NY. Los afiliados son responsables por separado de sus propias obligaciones financieras y contractuales.'
    }
};
