import { Box, Col, Collapse, Icon, Loader, Row, themes } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import useRiaData from '../../hooks/useRiaData';
import styled from 'styled-components';
import PreviousAbsence from '../../components/PreviousAbsence';
import { Scope } from 'informed';
import { SubmitAbsenceData } from '../../api/RiaApi';
import { compareDesc, format, parse } from 'date-fns';
import { RIA_TEXT } from '../../constants/RiaConstants';
import useTranslate from 'src/hooks/useTranslate';
import useRiaConfig from '../../hooks/useRiaConfig';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { InCollapseButton, StyledCollapseHeading } from '../../components/LayoutComponents';
export const updatedAbsences = [];
export const submitPreviousAbsences = async (formValues, currentLeave, riaConfig, language, authHeader, setUpdatedAbsences) => {
    let successful = 0;
    const updatedAbsences = [];
    if (formValues && formValues.previousAbsences) {
        const promises = formValues.previousAbsences.map(async (absence) => {
            const submitObj = {
                absenceResource: {
                    //@ts-ignore
                    absenceDate: format(new Date(absence.absenceDate), 'yyyy-MM-dd'),
                    // @ts-ignore
                    absenceHours: absence.absenceDuration?.split(':')[0],
                    // @ts-ignore
                    absenceMinutes: absence.absenceDuration?.split(':')[1],
                    leaveNumber: currentLeave?.lveNum,
                },
                lang: language,
                dateEnteredTimeStamp: new Date().toString(),
                emailActive: riaConfig?.reportingPolicyItems[0]?.emailActive,
                emailAddress: riaConfig?.reportingPolicyItems[0]?.emailAddresses,
                locCde: currentLeave?.locCde,
                ssdCde: currentLeave?.ssdCde,
            };
            try {
                await SubmitAbsenceData(submitObj, authHeader());
                updatedAbsences.push({ ...submitObj.absenceResource, isUpdate: true, success: true });
                successful++;
            }
            catch {
                updatedAbsences.push({ ...submitObj.absenceResource, isUpdate: true, success: false });
            }
        });
        const numEdits = await Promise.all(promises);
        setUpdatedAbsences(updatedAbsences);
    }
    //  returns # of successful edits - will help with determining 'all fail' state to know not to show confirmation
    return successful;
};
const StyledIcon = styled(Icon) `
  font-size: 2rem;
`;
const LeftIcon = () => <StyledIcon aria-hidden='true' name='view-request-status' className='mr-3' color='primary'/>;
const PreviousAbsences = () => {
    const { oldAbsences, loadingAbsences, currentLeave } = useRiaData();
    const { riaConfig } = useRiaConfig();
    const [customizedReportingPolicyItem, setCustomizedReportingPolicyItem] = useState();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const [editShowAmount, setEditShowAmount] = useState(10);
    const AbsencesHeading = () => <StyledCollapseHeading>{t(RIA_TEXT.editAbsences)}</StyledCollapseHeading>;
    useEffect(() => {
        if (riaConfig?.reportingPolicyItems && riaConfig?.reportingPolicyItems?.length > 0) {
            setCustomizedReportingPolicyItem(riaConfig?.reportingPolicyItems[0]);
        }
        if (riaConfig?.customizeReportingPolicy && currentLeave?.ssdCde) {
            const customizedReportingPolicyItems = riaConfig?.reportingPolicyItems?.filter((reportingPolicyItem) => {
                return reportingPolicyItem.subsidiary === currentLeave?.ssdCde;
            });
            if (customizedReportingPolicyItems?.length > 0) {
                setCustomizedReportingPolicyItem(customizedReportingPolicyItems[0]);
            }
        }
    }, [currentLeave]);
    let durationInterval = 1;
    if (customizedReportingPolicyItem?.durationInterval !== undefined && customizedReportingPolicyItem?.durationInterval !== null) {
        if (customizedReportingPolicyItem?.durationInterval === 0) {
            durationInterval = 60;
        }
        else {
            durationInterval = customizedReportingPolicyItem?.durationInterval;
        }
    }
    const handleClick = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: t(RIA_TEXT.editAbsences),
            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
            event_version: 'report absence',
            transaction_id: currentLeave?.lveNum,
        });
    };
    const buildViewedMessage = () => {
        if (editShowAmount < oldAbsences.length) {
            return t({
                english: `You're viewing ${editShowAmount} of ${oldAbsences.length} absences`,
                spanish: `Estás viendo ${editShowAmount} de ${oldAbsences.length} ausencias`,
            });
        }
        else {
            return t({
                english: `You're viewing ${oldAbsences.length} of ${oldAbsences.length} absences`,
                spanish: `Estás viendo ${oldAbsences.length} de ${oldAbsences.length} ausencias`,
            });
        }
    };
    const reportingPolicyInstructions = t({
        english: `Absences may be edited up to ${customizedReportingPolicyItem?.numberOfEditingDaysPast} days in the past and up to ${customizedReportingPolicyItem?.numberOfEditingDaysFuture} days in the future.`,
        spanish: `Las ausencias pueden ser reportadas hasta ${customizedReportingPolicyItem?.numberOfEditingDaysPast} días en el pasado y hasta ${customizedReportingPolicyItem?.numberOfEditingDaysFuture} días en el futuro.`,
    });
    oldAbsences.sort((a, b) => {
        return compareDesc(parse(a.absenceDate, 'P', new Date()), parse(b.absenceDate, 'P', new Date()));
    });
    /* Form outside this table */
    return (<>
      {oldAbsences?.length > 0 && (<Box>
          <Collapse data-testid='collapse-button' title={'Manage previous absences'} // For ADA
         titleComponent={AbsencesHeading} type='alternate' theme={themes.secureSite} headerClick={handleClick} headerLeftDisplay={LeftIcon} color='accent1'>
            <p className={`mb-3`}>{reportingPolicyInstructions}</p>
            <Loader isShown={loadingAbsences}>
              <>
                {oldAbsences.map((absence, i) => i < editShowAmount && (
            //@ts-ignore
            <Scope scope={`previousAbsences[${i}]`}>
                        <PreviousAbsence absence={absence} index={i} durationInterval={durationInterval} policyItem={customizedReportingPolicyItem}/>
                      </Scope>))}
                <Row>
                  <Col small={2} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <div style={{
                marginBottom: '18px',
                fontSize: '.75rem',
            }}>
                      {buildViewedMessage()}
                    </div>
                    {editShowAmount < oldAbsences.length && (<InCollapseButton data-testid='showMoreButton' buttonType={'secondary'} theme={themes.lincoln} onClick={() => setEditShowAmount(editShowAmount + 10)}>
                        {t(RIA_TEXT.editShowMore)}
                      </InCollapseButton>)}
                  </Col>
                </Row>
              </>
            </Loader>
          </Collapse>
        </Box>)}
    </>);
};
export default PreviousAbsences;
