import React from 'react';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { GrayTextDiv, GraySpacerDiv } from 'src/components/Formatting/StyledComponents';
import { Icon } from '@digitools/honest-react';
import styled from 'styled-components';
import { ReviewText } from '../../constants/ReviewText';
import useTranslate from 'src/hooks/useTranslate';
const StyledButton = styled('button') `
  outline: none;
  border: none;
  font-size: 1rem;
  background: inherit;
  color: #23669a;
  flex: 1;
  text-align: right;

  &:hover {
    cursor: pointer;
  }
`;
export const EditReviewField = ({ onClick, ...rest }) => {
    const { t } = useTranslate();
    return (<StyledButton onClick={onClick} {...rest}>
      <Icon color="secondary" className="mr-2" name="edit"/>
      {t(ReviewText.editButton)}
    </StyledButton>);
};
const ReviewField = ({ label, value, ...rest }) => {
    return (<GrayTextDiv {...rest}>
      {label}
      <div style={{ color: `${themes.lincoln.palette.grayDark}`, fontWeight: 300, fontSize: '1.125rem' }}>
        {value}
      </div>
      <GraySpacerDiv style={{ marginTop: value ? undefined : '1.3rem' }}/>
    </GrayTextDiv>);
};
export default ReviewField;
