import React from 'react';
import { ICoverageType } from '../../../../providers/EoiProvider';
import { GraySpacerDiv } from '../../../../../../components/Formatting/StyledComponents';
import useTranslate from '../../../../../../hooks/useTranslate';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import styled from 'styled-components';
import ReviewAndSignHeader from './ReviewAndSignHeader';
import { colorOrder, displayProductNote, getCoverageIcon } from '../../../../utils/HelperFunctions';
import useEoiContext from '../../../../hooks/useEoiContext';
const CoverageSelectionContainerDiv = styled('div') `
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const CoverageSelectionValueDiv = styled('div') `
  color: ${themes.lincoln.palette.grayDark};
  font-weight: 500;
  font-size: 0.875rem;
  margin-left: 1.5rem;
  @media (max-width: 500px) {
    margin-left: 0.5rem
    margin-right: 0
    margin-top: 0.25rem
  }
`;
const SelectedCoverages = (props) => {
    const { t } = useTranslate();
    const { initialInformation } = useEoiContext();
    const displayNote = props.applicant === 'employee' && displayProductNote(props.coverages, initialInformation);
    const findCoverageTitle = (coverageCode) => {
        // @ts-ignore
        const allProducts = [...initialInformation.employerConfiguration.familyProducts, ...initialInformation.employerConfiguration[`${props.applicant}Products`]];
        // @ts-ignore
        return allProducts.filter((product) => product.productCode === coverageCode).shift().text;
    };
    // TODO: figure out why coverageAmount is coming through as a string even though the interface for ICoverage and the signature of this method have coverage amount as a number
    const formatCoverageAmount = (coverageAmount, coverageType) => {
        if (!coverageAmount && !coverageType) {
            return undefined;
        }
        else {
            let coverageString;
            if (coverageType === ICoverageType.AMOUNT) {
                // @ts-ignore
                coverageString = (parseFloat(coverageAmount && coverageAmount.toString().replace(',', ''))).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });
            }
            else if (coverageType === ICoverageType.PERCENTAGE) {
                // @ts-ignore
                coverageString = `${parseFloat(coverageAmount).toFixed(2)}%`;
            }
            else {
                // @ts-ignore
                coverageString = `${parseFloat(coverageAmount).toFixed(2)}x`;
            }
            return (<div style={{ color: `${themes.lincoln.palette.grayDark}`, fontWeight: 300, fontSize: '18px', display: 'flex' }}>
          {coverageString}
        </div>);
        }
    };
    const coverageBox = (coverage, coverageColor, key) => {
        // const displayAmounts = true;
        const displayAmounts = initialInformation.employerConfiguration.preference.displayProductAmountsIndicator;
        return (
        // @ts-ignore
        <div style={{ borderLeft: displayAmounts ? `20px solid ${themes.lincoln.palette[coverageColor]}` : undefined, marginLeft: '1rem', marginBottom: '1rem', }} key={key}>
        <div style={{ fontWeight: 500, marginLeft: '0.5rem', marginBottom: displayAmounts ? '1rem' : '0rem' }}>
          <>
            {getCoverageIcon(coverage.coverageCode, coverageColor)}{' '}
            {t(findCoverageTitle(coverage.coverageCode))}
          </>
        </div>
        {displayAmounts &&
                <CoverageSelectionContainerDiv>
          <div style={{ color: `${themes.lincoln.palette.grayDark}`, fontWeight: 500, fontSize: '14px', marginLeft: '0.5rem' }}>
            {t(ReviewAndSignText.currentAmount)}
            {formatCoverageAmount(coverage.currentCoverageAmount, coverage.currentCoverageType)}
            <GraySpacerDiv />
          </div>
          <CoverageSelectionValueDiv>
            {t(ReviewAndSignText.requestedAmount)}
            {formatCoverageAmount(coverage.requestedCoverageAmount, coverage.requestedCoverageType)}
            <GraySpacerDiv />
          </CoverageSelectionValueDiv>
        </CoverageSelectionContainerDiv>}
      </div>);
    };
    return (<div style={{ marginTop: '0.5rem' }}>
      <ReviewAndSignHeader title={t(ReviewAndSignText.coverageSelections)} step={0}/>
      {props.coverages.map((coverage, i) => {
            // @ts-ignore
            return coverageBox(coverage, colorOrder[i % 3], i);
        })}
      {/* can use ! since initialInformation.employerConfiguration.productNote must be truthy for displayNote to be truthy. */}
      {displayNote && <span style={{ marginLeft: '1rem' }}><strong><em>{t(initialInformation.employerConfiguration.productNote)}</em></strong></span>}
    </div>);
};
export default SelectedCoverages;
