import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../../../../../hooks/useAuthentication';
import FileSaver from 'file-saver';
import { Button, CompositeTable, Icon } from '@digitools/honest-react';
import PaymentListDetails from './PaymentListDetails';
// @ts-ignore
import styles from './Payment.scss';
import axios from 'axios';
import Media from 'react-media';
import PaymentListMobile from './PaymentListMobile';
import useTealium from '../../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../../constants/tealiumConstants';
const ExpandCollapseCell = ({ data }) => {
    const { trackEvent } = useTealium();
    const paymentRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const toggleExpand = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.PAYMENT_EXPAND_TOGGLE,
            event_name: 'Payment Expand Toggle',
        });
        setExpanded(!expanded);
    };
    const setPaymentHeight = () => {
        // @ts-ignore it yells about this being null no matter how many checks i put around it
        setHeight(paymentRef.current.offsetHeight);
    };
    useEffect(() => {
        setPaymentHeight();
    });
    useEffect(() => {
        setExpanded(false);
    }, [data]);
    return (<div className={styles.paymentWrapper} style={{ paddingBottom: `${height}px` }} data-expanded={expanded}>
      <Button onClick={toggleExpand} size={'small'}>
        {expanded ? <Icon name='subtract' color={'primary'}/> : <Icon name='add' color={'primary'}/>}
      </Button>
      <div ref={paymentRef} style={{ width: '98%' }} className={styles.paymentRow} data-expanded={expanded}>
        <PaymentListDetails claimPayment={data._original}/>
      </div>
    </div>);
};
const PaymentDataTable = ({ claimPayments, empNum, claimNum }) => {
    const { t } = useTranslation();
    const { authHeader } = useAuthentication();
    const [isDownloadError, setIsDownloadError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const downloadPaymentPdf = async (checkNumber) => {
        try {
            setIsDownloading(true);
            const response = await axios.get(`/status-service/report/${empNum}/${claimNum}/${checkNumber}/claimPayment`, {
                responseType: 'blob',
                headers: { Authorization: authHeader() },
            });
            const filename = `LFG Claim Payment #${checkNumber}.pdf`;
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            FileSaver.saveAs(blob, filename);
            setIsDownloadError(false);
            setIsDownloading(false);
        }
        catch (error) {
            setIsDownloadError(true);
        }
    };
    const columns = [
        {
            Cell: ExpandCollapseCell,
            Header: '',
            id: 'expandCollapse',
        },
        {
            Header: t('checkDateHeaderText'),
            accessor: 'checkDate',
            id: 'checkDate',
        },
        {
            Header: t('beginPaymentDateHeaderText'),
            accessor: 'fromDate',
            id: 'fromDate',
        },
        {
            Header: t('endPaymentDateHeaderText'),
            accessor: 'throughDate',
            id: 'throughDate',
        },
        {
            Header: t('checkStatusHeaderText'),
            accessor: 'statusCode',
            id: 'statusCode',
            Cell: ({ data }) => t(data._original.statusCode),
        },
        {
            Header: t('grossBenefitsHeaderText'),
            accessor: 'benefitGross',
            id: 'benefitGross',
        },
        {
            Header: t('netBenefitsHeaderText'),
            accessor: 'benefitNet',
            id: 'benefitNet',
        },
        {
            Header: t('checkNumberHeaderText'),
            accessor: 'checkNumber',
            id: 'checkNumber',
        },
        {
            Header: t('payeeHeaderText'),
            accessor: 'payeeName',
            id: 'payeeName',
            Cell: ({ data }) => <span data-private>{data._original.payeeName}</span>,
        },
        {
            Cell: ({ data }) => {
                const checkNumberToUse = data._original.checkIdentifier; // crappy dance because the current object is weird
                return (<Button size={'small'} data-testid={'downloadPaymentPdf'} buttonType={'text'} onClick={() => downloadPaymentPdf(checkNumberToUse)}>
            <Icon name={'download'} color={'secondary'} style={{ marginLeft: '.5rem' }}/>
          </Button>);
            },
            Header: '',
            accessor: 'checkNumber',
            id: 'download',
        },
    ];
    return (<>
      <Media query='(max-width: 992px)'>
        {matches => matches ? (<PaymentListMobile paymentList={claimPayments}/>) : (<CompositeTable data-test-id='payment-table' data={claimPayments} columns={columns} paginated={claimPayments.length > 5} defaultPageSize={4}/>)}
      </Media>
    </>);
};
export default PaymentDataTable;
