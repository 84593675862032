import React from 'react';
import { ApplicantInformationText, EmploymentInformationText } from '../../../../i18n/ApplicantInformationText';
import useTranslate from '../../../../../../hooks/useTranslate';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import { customize } from '../../../../utils/HelperFunctions';
import { EoiGeneralText } from '../../../../i18n/EoiText';
import { Col } from '@digitools/honest-react/dist/lib/components/Col';
import ReviewAndSignField from './ReviewAndSignField';
import ReviewAndSignHeader from './ReviewAndSignHeader';
import useEoiContext from '../../../../hooks/useEoiContext';
const formatSalary = (salary) => {
    if (salary) {
        return `$${salary}`;
    }
    else {
        return '';
    }
};
const EmploymentInformation = () => {
    const { t } = useTranslate();
    const { interviewAnswers, customContent, initialInformation } = useEoiContext();
    const employee = interviewAnswers && interviewAnswers.applicants && interviewAnswers.applicants.employee;
    const employmentInformation = employee && employee.employmentInformation;
    const getOfficeLocation = (locationId) => {
        const location = initialInformation.employerConfiguration.locations.filter((location) => location.locationCode.toString() === locationId).shift();
        return location ? location.locationName : '';
    };
    return (customContent && employmentInformation ?
        <div style={{ marginTop: '2.5rem' }} data-testid={'review-employment-information'}>
      <ReviewAndSignHeader title={t(ApplicantInformationText.employmentInformation)} step={1}/>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={ReviewAndSignText.activeEmployee} value={employmentInformation.activeEmployee === 'yes' ? t(EoiGeneralText.yes) : t(EoiGeneralText.no)}/>
        </Col>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={ReviewAndSignText.fullTimePartTime} value={employmentInformation.employmentType === 'fullTime' ? t(EmploymentInformationText.fullTime) : t(EmploymentInformationText.partTime)}/>
        </Col>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={ReviewAndSignText.currentAnnualSalary} value={formatSalary(employmentInformation.salary)}/>
        </Col>
      </Row>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={customize(EmploymentInformationText.occupation, customContent, initialInformation)} value={employmentInformation.occupation}/>
        </Col>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={customContent.employeeIdText} value={employmentInformation.employeeId}/>
        </Col>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={EmploymentInformationText.dateOfHire} value={employmentInformation.dateOfHire}/>
        </Col>
      </Row>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={EmploymentInformationText.dateOfRehire} value={employmentInformation.dateOfReHire ? employmentInformation.dateOfReHire : ''}/>
        </Col>
        <Col md={4} sm={12}>
          {initialInformation.employerConfiguration.preference.displayLocationIndicator && employmentInformation.officeLocation &&
                <ReviewAndSignField label={EmploymentInformationText.officeLocation} value={getOfficeLocation(employmentInformation.officeLocation)}/>}
        </Col>
      </Row>
    </div>
        : <></>);
};
export default EmploymentInformation;
