import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAbsencesForLeave, getLeaves } from '../api/RiaApi';
import useAuthentication from 'src/hooks/useAuthentication';
import { Experience } from 'src/types/Experience';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { ExperienceContext } from 'src/providers/ExperienceProvider';
export const RiaDataContext = createContext({
    loading: false,
    leaves: [],
    currentLeave: undefined,
    oldAbsences: [],
    loadingAbsences: false,
    newAbsenceDateTimes: [],
    updatedAbsenceDateTimes: [],
    setCurrentLeave: () => { },
    setLeaves: () => { },
    setNewAbsenceDateTimes: () => { },
    setUpdatedAbsenceDateTimes: () => { },
    callForLeaves: () => { },
    callForAbsences: () => { },
});
/**
 *  Provides RiaDataProvider -
 *  Used to store current and user submitted data about leaves and absences
 */
export const RiaDataProvider = ({ children, }) => {
    const { user } = useAuthentication();
    const [currentLeave, setCurrentLeave] = useState(undefined);
    const [leaves, setLeaves] = useState([]);
    const [newAbsenceDateTimes, setNewAbsenceDateTimes] = useState([]);
    const [updatedAbsenceDateTimes, setUpdatedAbsenceDateTimes] = useState([]);
    const [oldAbsences, setOldAbsences] = useState([]);
    const { trackView } = useTealium();
    const { experience } = useContext(ExperienceContext);
    const { error: errorL, loading: loadingLeaves, data: leavesData, get: callForLeaves } = getLeaves();
    const { error: errorA, loading: loadingAbsences, data: absencesData, get: callForAbsences, } = getAbsencesForLeave(currentLeave?.lveNum);
    // Only call for leaves if verified with S1
    useEffect(() => {
        if (user && user.empNumS1) {
            callForLeaves();
        }
    }, [user]);
    // set Leaves data or mock data
    useEffect(() => {
        /* ~~~ DATA MOCKING  ~~~ */
        // Mock multiple leaves
        // setLeaves(MOCK_LEAVES!);
        // Mock single leave
        // setLeaves(MOCK_SINGLE_LEAVE);
        // setCurrentLeave(MOCK_SINGLE_LEAVE[0]);
        // setLeaves(MOCK_SINGLE_LEAVE);
        // setCurrentLeave(MOCK_SINGLE_LEAVE[0]);
        // Mock new absences
        // setNewAbsenceDateTimes([{absenceDate: '2022-09-20', absenceHours: '01', absenceMinutes: '05', success: true}, {absenceDate: '2021-08-06', absenceHours: '05', absenceMinutes: '15', success: false}])
        /* ~~~ ~~~ ~~~ ~~~ */
        if (leavesData) {
            // Single leave user - preset currentLeave
            if (leavesData.length === 1) {
                setCurrentLeave(leavesData[0]);
                // setCurrentLeave(MOCK_SINGLE_LEAVE[0]);
            }
            setLeaves(leavesData);
            // setLeaves(MOCK_SINGLE_LEAVE);
        }
    }, [leavesData]);
    // Only call for absences once a leave is selected
    useEffect(() => {
        if (currentLeave) {
            callForAbsences(); //currentLeave?.lveNum?
        }
    }, [currentLeave]);
    useEffect(() => {
        if (absencesData) {
            setOldAbsences(absencesData);
        }
    }, [absencesData]);
    //TODO Should move to ReportIntermittentAbsence but was causing dupes or leaves was always empty
    useEffect(() => {
        if (!loadingLeaves && leavesData && !loadingAbsences && experience !== Experience.PUBLIC) {
            trackView({
                page_l3: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
                page_l4: Experience.EMPLOYEE && TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.EMPLOYEE_VIEW,
                user_audience: `My Lincoln Portal ${Experience.EMPLOYEE}`,
                page_l5: leaves.length >= 1 ? '' : 'no leaves yet',
            });
        }
    }, [leaves]);
    const riaDataContext = {
        loading: loadingLeaves, //TODO: could rename this on context
        currentLeave,
        leaves,
        oldAbsences,
        loadingAbsences,
        newAbsenceDateTimes,
        updatedAbsenceDateTimes,
        setLeaves,
        setCurrentLeave,
        setNewAbsenceDateTimes,
        setUpdatedAbsenceDateTimes,
        callForLeaves,
        callForAbsences,
    };
    // console.log('~* Ria Data Context Debug *~', riaDataContext);
    return <RiaDataContext.Provider value={riaDataContext}>{children}</RiaDataContext.Provider>;
};
