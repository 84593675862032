import { Col } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from 'src/packages/cli/hooks/useCliDataContext';
import ReviewField from '../ReviewField';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { Experience } from 'src/types/Experience';
import useCliCustomerConfigContext from '../../../hooks/useCliContext';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { LeaveType } from '../../../providers/CliConfigProvider';
const ReviewCommonFlow = () => {
    const { t } = useTranslate();
    const { absenceData, claimantData } = useCliDataContext();
    const { formLabels } = AboutYourAbsenceText;
    const { leaveTypes, leaveCategoryDays, leaveReasons, claimantCondition, isClaimLeaveCustomer, isLeaveCustomer, paidLeaveStates, } = useCliCustomerConfigContext();
    const { experience } = useExperience();
    const inValidPaidLeaveState = claimantData && claimantData.selectedEmpStateID && paidLeaveStates.includes(claimantData.selectedEmpStateID);
    const getConsecutiveDaysAnswer = (leaveCategory) => {
        switch (leaveCategory) {
            case 'C': {
                return t({ english: 'Yes', spanish: 'Sí' });
            }
            case 'I': {
                return 'No';
            }
            default: {
                return '';
            }
        }
    };
    const leaveCategoryLabel = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? `${t(formLabels.leaveCategory1)} ${leaveCategoryDays} ${t(formLabels.leaveCategory2)}`
        : `${t(formLabels.leaveCategory1ER)} ${leaveCategoryDays} ${t(formLabels.leaveCategory2ER)}`);
    return (<>
      {absenceData?.leaveType && (isLeaveCustomer || isClaimLeaveCustomer || inValidPaidLeaveState) && (<Col xs={12} md={6} lg={6}>
          <ReviewField data-testid='leaveType' label={experience === Experience.EMPLOYEE ? t(formLabels.leaveType) : t(formLabels.leaveTypeER)} value={absenceData && t(leaveTypes.filter(leaveType => leaveType.code == absenceData?.leaveType)[0])}/>
        </Col>)}

      {absenceData?.leaveType !== LeaveType.PREGNANT &&
            (isLeaveCustomer || isClaimLeaveCustomer || inValidPaidLeaveState) && (<Col xs={12} md={6} lg={6}>
            <ReviewField data-testid='leaveCategory' label={leaveCategoryLabel} value={absenceData && getConsecutiveDaysAnswer(absenceData.leaveCategory)}/>
          </Col>)}

      {absenceData?.leaveReason && (<Col xs={12} md={6} lg={6}>
          <ReviewField data-testid={`leaveReason-${absenceData.leaveReason}`} label={CustomEmployeeText(experience === Experience.EMPLOYEE ? t(formLabels.leaveReason) : t(formLabels.leaveReasonER))} value={absenceData && t(leaveReasons.filter(leaveReason => leaveReason.code == absenceData?.leaveReason)[0])}/>
        </Col>)}
      {absenceData?.claimantCondition &&
            absenceData?.leaveType === LeaveType.OWN_ILLNESS &&
            absenceData?.leaveCategory !== 'I' && (<Col xs={12} md={6} lg={6}>
            <ReviewField data-testid={`claimantCondition-${absenceData.claimantCondition}`} label={experience === Experience.EMPLOYEE
                ? t(formLabels.claimantConditionEE)
                : t(formLabels.claimantConditionER)} value={absenceData &&
                t(claimantCondition.filter(claimantCondition => claimantCondition.code == absenceData?.claimantCondition)[0])}/>
          </Col>)}
    </>);
};
export default ReviewCommonFlow;
