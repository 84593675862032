import React, { useState } from 'react';
import { Box, Button, Col, Collapse, DateSelect, InputMessage, Row, SelectInput, themes, } from '@digitools/honest-react';
import { Form } from 'informed';
import { isAfter, isBefore, isEqual, parse } from 'date-fns';
import getLeaveProgramType from '../../../utilities/getLeaveProgramType';
import getLeaveStatus from '../../../utilities/getLeaveStatus';
const AbsenceDetailsFilter = ({ initialData, setFilteredData }) => {
    const [formApi, setFormApi] = useState();
    if (!initialData) {
        return null;
    }
    const validateDateFilter = (values) => {
        if (values.fromDate && !values.throughDate) {
            formApi.setError('throughDate', 'Through date required');
        }
        else if (values.throughDate && !values.fromDate) {
            formApi.setError('fromDate', 'From date required');
        }
        else if (values.fromDate && values.throughDate) {
            const fromDate = parse(values.fromDate, 'P', new Date());
            const throughDate = parse(values.throughDate, 'P', new Date());
            if (isAfter(fromDate, throughDate)) {
                formApi.setError('fromDate', 'From Date must come before Through Date');
            }
        }
    };
    const clearFilterForm = () => {
        // clear out the values and errors in the form
        formApi.setValue('fromDate', undefined);
        formApi.setValue('throughDate', undefined);
        formApi.setValue('program', undefined);
        formApi.setValue('status', undefined);
        formApi.setError('fromDate', undefined);
        formApi.setError('throughDate', undefined);
        formApi.setError('program', undefined);
        formApi.setError('status', undefined);
        setFilteredData(undefined);
    };
    const filterAbsenceDetails = (values) => {
        let filteredData = initialData;
        // // PROGRAM FILTER
        if (values.program) {
            filteredData = filteredData.filter((data) => {
                return values.program === getLeaveProgramType(data);
            });
        }
        // // STATUS FILTER
        if (values.status) {
            filteredData = filteredData.filter((data) => {
                return values.status === getLeaveStatus(data);
            });
        }
        // DATE FILTER
        if (values.fromDate && values.throughDate) {
            const fromDate = parse(values.fromDate, 'P', new Date());
            const throughDate = parse(values.throughDate, 'P', new Date());
            filteredData = filteredData.filter((data) => {
                const leaveDate = parse(data.date, 'P', new Date());
                return ((isBefore(fromDate, leaveDate) || isEqual(fromDate, leaveDate)) &&
                    (isBefore(leaveDate, throughDate) || isEqual(leaveDate, throughDate)));
            });
        }
        setFilteredData(filteredData);
    };
    const programsInData = initialData.map((leave) => getLeaveProgramType(leave));
    let programOptions = [...new Set(programsInData)];
    programOptions = programOptions.map((program) => ({ label: program, value: program }));
    const statusesInData = initialData.map((leave) => getLeaveStatus(leave));
    let statusOptions = [...new Set(statusesInData)];
    statusOptions = statusOptions.map((status) => ({ label: status, value: status }));
    return (<Box style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
      <Collapse title={'Filter'} data-testid='filterPaymentsHeader' type={'alternate'} theme={themes.lincoln}>
        <Form onSubmit={filterAbsenceDetails} getApi={setFormApi} validate={validateDateFilter}>
          <Row>
            <Col md={6} sm={12}>
              <DateSelect data-testid={'filterFromDate'} field={'fromDate'} ariaLabelText={'From Date'} label={'From Date'} theme={themes.lincoln}/>
            </Col>
            <Col md={6} sm={12}>
              <DateSelect data-testid={'filterThroughDate'} field={'throughDate'} ariaLabelText={'Through Date'} label={'Through Date'} theme={themes.lincoln}/>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <SelectInput data-testid={'filterProgram'} field={'program'} ariaLabelText={'Program'} label={'Program'} placeholderText={'Select Program'} 
    //@ts-ignore FIXME: type error
    options={programOptions} theme={themes.lincoln}/>
            </Col>
            <Col md={6} sm={12}>
              <SelectInput data-testid={'filterStatus'} field={'status'} ariaLabelText={'Status'} label={'Status'} placeholderText={'Select Status'} 
    //@ts-ignore FIXME: type error
    options={statusOptions} theme={themes.lincoln}/>
            </Col>
          </Row>
          {formApi && formApi.getState().error && (<InputMessage error={true}>{formApi && formApi.getState().error}</InputMessage>)}
          <Row>
            <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
              <Button type='button' buttonType={'secondary'} onClick={() => clearFilterForm()} theme={themes.lincoln}>
                Clear filter
              </Button>
              <Button data-testid='absence-filter-submit' type='submit' buttonType={'primary'} theme={themes.lincoln}>
                Apply filter
              </Button>
            </div>
          </Row>
        </Form>
      </Collapse>
    </Box>);
};
export default AbsenceDetailsFilter;
