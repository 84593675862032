import React from 'react';
import ReviewAndSignText from '../../../../i18n/ReviewAndSignText';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import useTranslate from '../../../../../../hooks/useTranslate';
const SignaturePageHeader = () => {
    const { t } = useTranslate();
    return (<>
      <Heading data-testid={'signature-heading'} theme={themes.lincoln} elemType={'h1'} elemStyle={'h2'} color={'primary'} style={{ marginBottom: '.5rem' }}>
        {t(ReviewAndSignText.signatureHeader)}
      </Heading>
      <p data-testid={'signature-heading-review'}>{t(ReviewAndSignText.pleaseReview)}</p>
      <hr />
    </>);
};
export default SignaturePageHeader;
