import React from 'react';
import { Row, Col, Container, Alert } from '@digitools/honest-react';
import checkDate from 'status/utilities/checkDate';
import { useTranslation } from 'react-i18next';

const Error = () => (
  <Alert type={'warning'}>
    <span>There are no leave dates to display at this time.</span>
  </Alert>
);

const LeaveOverview = ({ leaveDetails, leave }) => {
  const { t } = useTranslation();

  var freqMode = '';
  var freqPrd = '';
  var epPrd = '';

  if (leaveDetails) {
    freqMode = leaveDetails.int_freq_mode && leaveDetails.int_freq_mode.trim();
    freqPrd = leaveDetails.int_freq_prd && leaveDetails.int_freq_prd.trim();
    epPrd = leaveDetails.int_freq_epsd_prd && leaveDetails.int_freq_epsd_prd.trim();
  }
  var periodText;

  //handle text for the period
  if (freqPrd != 'E') {
    periodText = freqPrd ? t('modeText_' + freqPrd) : '';
  } else {
    // handle episodes case, 'Per episode, 2 episodes per week'
    periodText = t('perEpisodeText') + ', ' + leaveDetails.int_freq_epsd_num_times + ' ';
    periodText = periodText + t('episodeText') + (leaveDetails.int_freq_epsd_num_times > 1 ? 's' : ''); //pluralize if over 1
    periodText = periodText + ' ' + t('perText') + ' ' + t('simpleModeText_' + epPrd);
  }

  const print = () => {
    window.print();
  };

  if (!leaveDetails && !leave) {
    return <Error />;
  }

  return (
    <Container>
      <Row className={'mb-5'}>
        <Col>
          <Row>
            <Col>
              <strong>{t('leaveReceivedText')}</strong>
            </Col>
            <Col>{leave.lveRecdDte && leave.lveRecdDte.split(' ')[0]}</Col>
          </Row>
          <Row>
            <Col>
              <strong>{t('leaveBeginText')}</strong>
            </Col>
            <Col>{checkDate(leave.lveBeginDte)}</Col>
          </Row>
          <Row>
            <Col>
              <strong>{t('leaveEndText')}</strong>
            </Col>
            <Col>{checkDate(leave.lveEndDte)}</Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm={8}>
              <strong>{t('intermittentApprovedFrequencyText')}</strong>
            </Col>
            <Col sm={4}>{leaveDetails && leaveDetails.approvedFrequency ? leaveDetails.int_freq_amt : null}</Col>
          </Row>
          <Row>
            <Col sm={8}>
              <strong>{t('intermittentApprovedModeText')}</strong>
            </Col>
            <Col sm={4}>{freqMode ? t('modeText_' + freqMode) : ''}</Col>
          </Row>
          <Row>
            <Col sm={8}>
              <strong>{t('intermittentApprovedPeriodText')}</strong>
            </Col>
            <Col sm={4}>{periodText}</Col>
          </Row>
          <Row>
            <Col sm={8}>
              <strong>{t('takeoverText')}</strong>
            </Col>
            <Col sm={4}>{leave.takeOver}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <strong>{t('requestedBeginText')}</strong>
            </Col>
            <Col>{checkDate(leave.lveReqBegDte)}</Col>
          </Row>
          <Row>
            <Col>
              <strong>{t('requestedEndText')}</strong>
            </Col>
            <Col>{checkDate(leave.lveReqEndDte)}</Col>
          </Row>
          <Row>
            <Col>
              <strong>{t('determinationDateText')}</strong>
            </Col>
            <Col>{checkDate(leave.lveDtrmDte)}</Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm={8}>
              <strong>{t('lastDayWorkedText')}</strong>
            </Col>
            <Col sm={4}>{checkDate(leave.lveLastWrkDte)}</Col>
          </Row>
          <Row>
            <Col sm={8}>
              <strong>{t('dateCertifiedFromText')}</strong>
            </Col>
            <Col sm={4}>{checkDate(leave.certFromDte)}</Col>
          </Row>
          <Row>
            <Col sm={8}>
              <strong>{t('dateCertifiedThroughText')}</strong>
            </Col>
            <Col sm={4}>{checkDate(leave.certThruDte)}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LeaveOverview;
