import React from 'react';
import FullCoverageDetails from './FullCoverageDetails';
import PartialCoverageDetails from './PartialCoverageDetails';
import { useExperience } from 'status/hooks';
import { decode } from 'status/utilities/hash';
import EmployerDetailRedirect from './EmployerDetailRedirect';
import queryString from 'query-string';
import { withRouter } from 'src/utils/withRouter';
import { useLocation, useParams } from 'react-router-dom';

/**
 * Backstory: Sometimes, employees will only have an empId
 * because they don't exits in S1. An user might want to
 * view details on this employee but all they have to go on is
 * the empId. Therefore, we need a way for users to view
 * this data ( coverage details ) without an empNum. This
 * is where <PartialCoverageDetails> comes into play. Its
 * a component that will render details based on the empId.
 */
const CoverageDetails = () => {
  const { getExperience } = useExperience();
  const location = useLocation();
  const { empNum } = useParams();
  // Grab the emp# Or empId off the url, we will use it to determine what to render
  const decodedParam = decode(empNum);
  const parsed = queryString.parse(location.search);
  const teamView = parsed.from === 'teamView';
  // We cant render all of the coverage details when we just have an empId
  if (decodedParam?.split('-')[0] === 'id') {
    // When we are an ER we need to check if the employer has an emp#
    // if they do then we can use it and route them to the full coverage details!
    if (getExperience() === 'ER') {
      // Pull off the empId
      const empId = decodedParam.split('-')[1];
      // Render the Redirect component
      return <EmployerDetailRedirect empId={empId} fromTeamView={teamView} />;
    }

    // If the user is in the employee experience then just load the partial details
    return <PartialCoverageDetails fromTeamView={teamView} />;
  }

  // If we got here then we are routing to the page with an empNum and can load the full coverage details
  return <FullCoverageDetails fromTeamView={teamView} />;
};

export default withRouter(CoverageDetails);
