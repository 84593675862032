import React, { useMemo, useState } from 'react';
import { Alert, Link, CompositeTable as DataTable, Spinner } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import styles from './ClaimDetails.scss';
import { useExperience } from 'status/hooks';
import useGet from '../../../../../hooks/useGet';
import formatDateMMDDYYY from 'status/utilities/formatDateMMDDYYY';
import titleCase from 'status/utilities/titleCase';
import getMethodSent from 'status/utilities/getMethodSent';
import downloadPdf from 'status/utilities/downloadPdf';
import { compareAsc, parse } from 'date-fns';
import { useLanguage, useUiData } from 'status/hooks';
import CorrespondenceFilter from '../filters/CorrespondenceFilter';
import { decode } from 'status/utilities/hash';

const Error = ({ error, noResults }) => {
  const notFound = noResults || (error.response && error.response.status === 404);
  return (
    <Alert type={notFound ? 'info' : 'error'}>
      {notFound ? (
        'There are no documents to display at this time.'
      ) : (
        <span>
          <strong>Error!</strong>&nbsp;Unable to load correspondence documents.
        </span>
      )}
    </Alert>
  );
};

const openPdfDocument = (get, href, title, i, id) => {
  get({
    url: `/status-service/correspondence/retrieve/document/${id}?docURL=${href}`,
    options: {
      headers: {
        Accept: 'application/pdf;',
        'Content-Type': 'application/json; charset=utf-8',
      },
      responseType: 'blob',
    },
    success: res => downloadPdf(res, title),
  });
};

const NameCell = ({ data }) => {
  return (
    <span>
      {titleCase(data._original.sentToLastName)} {titleCase(data._original.sentToFirstName)}
    </span>
  );
};

const Correspondence = ({ claimNum, claim }) => {
  const { experience } = useExperience();

  const { t } = useTranslation();
  const { language } = useLanguage();
  const [filteredData, setFilteredData] = useState();

  const { error: uiDataError, loading: uiDataLoading, uiData } = useUiData();

  const { error, loading, data } = useGet({
    url: `/status-service/correspondence/search/documents/${experience}/CLAIM/${claim.fundMechnCde.trim()}`,
    options: {
      params: {
        claimLeaveNum: claimNum,
      },
    },
  });

  const {
    error: downloadError,
    loading: downloading,
    get,
  } = useGet({
    noInitial: true,
  });

  const DownloadCell = ({ data }) => {
    return (
      <Link
        onClick={() =>
          openPdfDocument(
            get,
            data._original._links.content.href,
            data._original.documentTitle,
            data._index,
            data._original.docId,
          )
        }>
        {data._original.documentTitle}
      </Link>
    );
  };

  const formatDocToDisplay = doc => {
    return {
      ...doc,
      documentType: titleCase(doc.documentType),
      productTypeCode: doc.productTypeCode && doc.productTypeCode.toUpperCase(),
      documentDate: formatDateMMDDYYY(new Date(doc.documentDate)),
      sentDate: doc.sentDate ? formatDateMMDDYYY(new Date(doc.sentDate)) : '',
      sentToDistributionMethod: getMethodSent(doc.sentToDistributionMethodCode, uiData),
    };
  };

  const formattedData = useMemo(() => {
    if (filteredData) {
      console.log('data filtered');
      return filteredData.map(doc => formatDocToDisplay(doc));
    } else if (data) {
      return data._embedded.documentList.map(doc => formatDocToDisplay(doc));
    }
  }, [data, uiData, language, filteredData]);

  const columns = useMemo(() => {
    if (data) {
      return [
        {
          Header: t('documentType'),
          accessor: 'documentType',
          id: 'documentType',
        },
        {
          Cell: DownloadCell,
          Header: t('description'),
          accessor: 'documentTitle',
          id: 'documentTitle',
        },
        {
          Header: t('productText'),
          accessor: 'productTypeCode',
          id: 'productTypeCode',
        },
        {
          Header: t('documentDate'),
          accessor: 'documentDate',
          id: 'documentDate',
          sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
          Header: t('documentSentDate'),
          accessor: 'sentDate',
          id: 'sentDate',
          sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
          Cell: NameCell,
          Header: t('documentSentToName'),
          accessor: 'sentToLastName',
          id: 'sentToLastName',
        },
        {
          Header: t('documentMethodSent'),
          accessor: 'sentToDistributionMethod',
          id: 'sentToDistributionMethod',
        },
      ];
    }
  }, [data, uiData, language]);

  if (loading) {
    return <Spinner id='claim-correspondence-spinner' />;
  }

  const initialData = data ? data._embedded.documentList : undefined;
  const noResults = initialData && initialData.length === 0;

  if (error || noResults) {
    return <Error error={error} noResults={noResults} />;
  }

  const defaultSorted = [
    {
      id: 'sentDate',
      desc: true,
    },
  ];


  return (
    <div className={styles.tableContainer}>
      <CorrespondenceFilter initialData={initialData} setFilteredData={setFilteredData} />
      <DataTable
        data={formattedData}
        columns={columns}
        paginated={formattedData.length > 10}
        defaultSorted={defaultSorted}
      />
      <span>{t('questionsCaseManagerText')}</span>
    </div>
  );
};

export default Correspondence;
