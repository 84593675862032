import React from 'react';
import { Alert, Col, Box, Collapse, Row, themes, Spinner } from '@digitools/honest-react';
import useGet from '../../../../../hooks/useGet';
import WatchlistTable from './WatchlistTable';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  margin-top: 1rem;
`;

const Watchlist = ({ from }) => {
  const {
    data: watchlistData,
    loading: watchlistLoading,
    error: watchlistError,
  } = useGet({
    url: '/status-service/watch-list',
    handleUnauthorized: true,
  }) || [];

  if (watchlistLoading) {
    return (
      <Box data-public={true} style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        <Collapse data-public={true} title='My watchlist' isOpen={false} type={'alternate'} theme={themes.lincoln}>
          <StyledRow>
            <Spinner id='watchlist-spinner' size='small' />
            <Col lg={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <span style={{ marginTop: '-2.75rem', marginBottom: '2.5rem' }}>
                  We're currently loading your Watchlist
                </span>
              </div>
            </Col>
          </StyledRow>
        </Collapse>
      </Box>
    );
  }

  if (watchlistError) {
    return (
      <Box data-public={true} style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
        <Collapse data-public={true} title='My watchlist' isOpen={false} type={'alternate'} theme={themes.lincoln}>
          <StyledRow>
            <Col lg={12}>
              <Alert type={'error'} closeable={true}>
                <span>
                  <strong>Error!</strong>&nbsp;Something went wrong when retrieving watchlist.
                </span>
              </Alert>
            </Col>
          </StyledRow>
        </Collapse>
      </Box>
    );
  }

  return (
    <Box data-public={true} style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
      <Collapse data-public={true} title='My watchlist' isOpen={false} type={'alternate'} theme={themes.lincoln}>
        <StyledRow>
          <Col lg={12}>
            {watchlistData && watchlistData.length !== 0 ? (
              <WatchlistTable watchlistData={watchlistData} from={from} />
            ) : (
              'There are currently no items on your Watchlist. To add an event, select Watchlist on the details page.'
            )}
          </Col>
        </StyledRow>
      </Collapse>
    </Box>
  );
};

export default Watchlist;
