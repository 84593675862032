import React, { useRef, useState } from 'react';
import { Container, HeaderBar, HeaderButton, HeaderDivider, HeaderDropdownContainer, HeaderIcon, Row, useOutsideClick, themes } from '@digitools/honest-react';
import useAuthentication from '../../hooks/useAuthentication';
import styled, { css } from 'styled-components';
import useCustomerConfig from '../../hooks/useCustomerConfig';
import { format, parseJSON } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { HeaderText } from './HeaderText';
import useExperience from '../../hooks/useExperience';
import useTranslate from '../../hooks/useTranslate';
import { Experience } from 'src/types/Experience';
import { Language } from 'src/types/Language';
import MobileHeader from './MobileHeader';
import Media from 'react-media';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import NavMenuBar from '../NavMenuBar/NavMenuBar';
//@ts-ignore
import styles from './HeaderStyles.scss';
import ExperienceMenu from './ExperienceMenu';
import { getEnvVariable, isProd } from 'src/utils/envUtils';
import CustomerDisplay from './CustomerDisplay';
import ProveText from '../prove/ProveText';
// TODO: this could move to honest
const HeaderSpan = styled('span') `
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    padding: 1rem 1rem;
    text-decoration: none;
    color: white;
    margin: 0;
`;
// FIXME: temporary workaround - HeaderDropdownLink from honest has issues. The wrapped
//  react-router-dom Link within HeaderDropdownLink is complaining about props it doesn't need
// specifically 'component' which is supposed to be optional
const headerDropdownLinkStyles = css `
  display: flex !important;
  font-size: 0.8125rem;
  font-weight: bolder;
  padding: 0.78125rem !important; //🤷‍♂️ funky, but makes the ask for 25px between nav labels work
  text-decoration: none;
  color: white;
  display: block;
`;
export const HeaderDropdownLink = styled(Link) `
  ${headerDropdownLinkStyles}
`;
export const HeaderDropdownExternalLink = styled.a `
  ${headerDropdownLinkStyles}
`;
export const HeaderLink = styled(Link) `
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  font-weight: bolder;
  text-decoration: none;
  color: white;
  margin: 0;
  padding: 1rem 1rem !important;
`;
const SkipToMainLink = styled.a `
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  &:focus {
    position: static;
    width: auto;
    height: auto;
  }
`;
const NonPrintableBody = styled.div `
  @media print {
        display :none;
  }
`;
const Header = () => {
    /* Contexts */
    const { isAuthenticated, user, logout } = useAuthentication();
    const { isCommPrefEnabled } = useCustomerConfig();
    const { toggleLanguage, changeLanguage, setExperience, experience, language } = useExperience();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    /* Header State */
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const [isExperienceMenuOpen, setIsExperienceMenuOpen] = useState(false);
    /* Dropdown Menu Button Refs */
    const profileButtonRef = useRef(null);
    const profileMenuRef = useRef(null);
    const location = useLocation();
    const isMigrateScreen = location.pathname.endsWith('migrate');
    /* Click handling for closing dropdown menus */
    useOutsideClick([profileButtonRef, profileMenuRef], () => {
        if (isProfileMenuOpen) {
            setIsProfileMenuOpen(false);
        }
    });
    const trackPageEvent = (eventName, version) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: eventName,
            event_type: version,
            event_version: TEALIUM_EVENT.EVENT_TYPE.HEADER,
        });
    };
    return (<>
      <Media query='(min-width: 768px)'>
        {matches => (!matches ?
            <MobileHeader />
            :
                <>
            <SkipToMainLink href="#maincontent" className={'d-block text-center my-2'}>Skip to content</SkipToMainLink>
            <HeaderBar className={styles.paddingFix} data-public={true} data-testid={'mlp-header'}>
              <Container theme={themes.lincoln} style={{ height: '100%' }}>
                <Row style={{ display: 'flex', fontSize: '.825rem', alignItems: 'center' }}>
                  {isAuthenticated && user && !isMigrateScreen && <>
                    <div data-public={true} style={{ position: 'relative' }}>

                      <HeaderButton className={styles.fontFix + ' ' + styles.noUnderline} data-private={true} aria-controls={'profile-menu'} aria-haspopup={true} data-testid='profile-menu' ref={profileButtonRef} onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)} style={{ zIndex: 2001 }}>
                        <HeaderIcon className={styles.noUnderline} aria-hidden={true} name='profile'/>
                        <Media query={'(min-width: 992px)'}>
                          {matches => (matches ? `${t(HeaderText.welcome)} ${user.firstName && user.firstName}` : '')}
                        </Media> 

                      </HeaderButton>
                      {isProfileMenuOpen &&
                            <HeaderDropdownContainer onClick={() => setIsProfileMenuOpen(false)} data-public={true} id={'profile-menu'} ref={profileMenuRef} style={{ zIndex: 2000, top: '32px' }}>
                          {/* TODO: inline styles here for container should be put into Honest */}
                          {user?.isLfgUser && !user?.isIdentityVerified &&
                                    <HeaderDropdownLink data-public={true} aria-label={t(ProveText.verifyHeadingAlt)} className={styles.heightFix} data-testid={'accountAccess'} to="/profile/accountAccess" onClick={() => trackPageEvent(ProveText.verifyHeadingAlt.english, 'account access')}>
                            {t(ProveText.verifyHeadingAlt)}
                          </HeaderDropdownLink>}
                          {(user.isLfgUser && (user.auth0Roles?.includes("PA") || (!isProd() && user.auth0Roles?.includes("MLPEmployee") && user?.isIdentityVerified))) ?
                                    <>
                              <HeaderDropdownExternalLink data-testid={'profileLink'} data-public={true} href={`${getEnvVariable('lfgHomeUrl')}/secure/consumer/profileinformation#/consumer`} target="_blank" onClick={() => { trackPageEvent('Profile information'); }}>
                                {t(HeaderText.profile)}
                              </HeaderDropdownExternalLink>
                              <HeaderDropdownExternalLink data-testid={'settingsLink'} data-public={true} href={`${getEnvVariable('lfgHomeUrl')}/secure/consumer/securitysettings#/consumer`} target="_blank" onClick={() => { trackPageEvent('Security settings'); }}>
                                {t(HeaderText.settings)}
                              </HeaderDropdownExternalLink>
                            </>
                                    :
                                        <>
                              <HeaderDropdownLink data-testid={'profileLink'} data-public={true} to='/profile/userInfo' onClick={() => { trackPageEvent('Profile information'); }}>
                                {t(HeaderText.profile)}
                              </HeaderDropdownLink>
                              <HeaderDropdownLink data-testid={'settingsLink'} data-public={true} to='/profile/password' onClick={() => { trackPageEvent('Security settings'); }}>
                                {t(HeaderText.settings)}
                              </HeaderDropdownLink>
                            </>}
                          {isCommPrefEnabled && <HeaderDropdownLink className={styles.heightFix} data-testid={'commPrefLink'} data-public={true} to="/profile/alertsNotifications" onClick={() => trackPageEvent('Alerts & notifications')}>
                            {t(HeaderText.commPrefs)}
                          </HeaderDropdownLink>}
                          
                          {user.roles && (user.roles.includes('employer') || user.roles.indexOf('internal') > -1) && <HeaderDropdownLink className={styles.heightFix} data-testid={'emailNotificationsLink'} data-public={true} to="/admin/emailNotification" onClick={() => trackPageEvent('Email Notifications')}>
                            {t(HeaderText.emailNotifications)}
                          </HeaderDropdownLink>}
                          {user.roles && (user.roles.indexOf('employeradmin') > -1 || user.roles.indexOf('internal') > -1) && user.customerInContext && <HeaderDropdownLink className={styles.heightFix} data-testid={'userAccessLink'} data-public={true} to="/admin/permissions" onClick={() => trackPageEvent('User Access')}>
                            {t(HeaderText.userAccess)}
                          </HeaderDropdownLink>}
                        </HeaderDropdownContainer>}
                    </div>
                    <Media query={'(min-width: 1200px)'}>
                      {matches => (matches ?
                            <><HeaderDivider />
                        
                          <HeaderSpan style={{ flexShrink: 0, fontFamily: 'Roboto Condensed' }}>{user && user.lastLogin ? `${t(HeaderText.lastLogin)} ${format(parseJSON(user?.lastLogin), 'MM/dd/yy')}` : ''}</HeaderSpan></>
                            : <></>)}
                    </Media>
                    <HeaderDivider />
                    <HeaderButton className={styles.fontFix} style={{ fontFamily: 'Roboto Condensed', textTransform: 'uppercase', fontSize: '.8125rem', }} data-testid={'logout'} onClick={() => { trackPageEvent('Log Out'); logout(); }}>
                      {t(HeaderText.logout)}
                    </HeaderButton>
                    {isAuthenticated && user && user.customerInContext &&
                            (<>
                        <ExperienceMenu user={user} trackPageEvent={trackPageEvent}/>
                        {experience == Experience.EMPLOYER && <CustomerDisplay />}
                      </>)}
                  </>}
                  <div style={{ display: 'flex', flexBasis: 1, flexGrow: 2, justifyContent: 'flex-end' }}>
                    {experience !== Experience.EMPLOYER &&
                        <>
                        <HeaderButton className={styles.fontFix} data-testid={'changeLanguage'} onClick={() => { trackPageEvent('Language', (language === Language.ENGLISH ? 'English' : 'Spanish')); toggleLanguage(); }}>
                          <span style={{ textDecoration: language === Language.ENGLISH ? 'underline' : 'none', fontWeight: language === Language.ENGLISH ? 'bold' : 'normal' }}>{HeaderText.language.english}</span>
                          <pre> | </pre>
                          <span style={{ textDecoration: language === Language.SPANISH ? 'underline' : 'none', fontWeight: language === Language.SPANISH ? 'bold' : 'normal' }}>{HeaderText.language.spanish}</span>
                        </HeaderButton>
                        <HeaderDivider />
                      </>}
                    {isAuthenticated && !isMigrateScreen && <>
                      <HeaderLink aria-label={`Contact support`} data-testid="contactSupport" data-public={true} to="/home/contacts" onClick={() => trackPageEvent('Contact and support', 'Logged in')}>
                        {/* <HeaderIcon aria-hidden={true} className={styles.flipH} name="phone" size='xs' /> */}
                          {t(HeaderText.contactSupport)}
                      </HeaderLink>
                    </>}
                    {!isAuthenticated && !isMigrateScreen && <>
                      <HeaderLink data-testid="contactSupport" data-public={true} to="/public/contacts" onClick={() => trackPageEvent('Contact and support', 'Public')}>
                        {/* <HeaderIcon className={styles.flipH} name="phone" size='xs' /> */}
                          {t(HeaderText.contactSupport)}
                      </HeaderLink>
                    </>}
                  </div>
                </Row>
              </Container>
            </HeaderBar>
            <NavMenuBar />
          </>)}
      </Media>
    </>);
};
export default Header;
