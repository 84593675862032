import React, { useContext, useRef } from 'react';
import { Alert, Heading, StepperContext } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import StepperButtons from '../../components/StepperButtons';
import ReviewAboutYou from './ReviewAboutYou';
import ReviewAboutAbsence from './ReviewAboutAbsence';
import ReviewAdditionalInfo from './ReviewAdditionalInfo';
import ReviewPersonalIdentification from './ReviewPersonalIdentification';
import { ReviewText } from 'src/packages/cli/constants/ReviewText';
import ReviewFraudStatement from './ReviewFraudStatement';
import ReviewSubmitButton from './ReviewSubmitButton';
import useAuthentication from '../../../../hooks/useAuthentication';
import useCliDataContext from '../../hooks/useCliDataContext';
import ReviewDocumentUpload from './ReviewDocumentUpload';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { LeaveType } from '../../providers/CliConfigProvider';
import ReviewMedicalContact from './ReviewMedicalContact';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import RouterPrompt from '../../components/RouterPrompt';
import GettingStartedText from '../start/GettingStartedText';
import useOnScreen from 'src/hooks/visibilityChecker';
import ReviewPaymentMethod from './ReviewPaymentMethod';
const Review = () => {
    const { user } = useAuthentication();
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const { absenceData, submitErrors, paymentMethodData } = useCliDataContext();
    const { experience } = useExperience();
    const { hasAdditionalInfo } = useCliCustomerConfigContext();
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const ensureRequestLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ? t(ReviewText.ensureRequestEE) : `${t(ReviewText.ensureRequestER)}`);
    const shouldDisplayMedicalContacts = experience === Experience.EMPLOYEE &&
        (absenceData?.leaveType === LeaveType.OWN_ILLNESS ||
            absenceData?.leaveType === LeaveType.OTHER ||
            absenceData?.leaveType === LeaveType.PREGNANT);
    const docUploadFlag = (experience === Experience.EMPLOYEE && user?.customerInContext?.smallMid) || !user?.customerInContext?.smallMid;
    const errors = !!submitErrors &&
        submitErrors
            .replace('[', '')
            .replace(']', '')
            .split(',')
            .map((message) => <li className={`mb-1`}>{message}</li>);
    return (<div ref={ref}>
      {stepper.currentStep !== t(GettingStartedText.pageTitle) && isVisible && <RouterPrompt />}
      <Heading elemType='h2' color='primary'>
        {t(ReviewText.pageTitle)}
      </Heading>
      <Heading elemType='h5' color='black'>
        {ensureRequestLabel}
      </Heading>
      <ReviewPersonalIdentification />
      <ReviewAboutYou />
      <ReviewAboutAbsence />
      {shouldDisplayMedicalContacts && <ReviewMedicalContact />}
      {experience === Experience.EMPLOYER && hasAdditionalInfo && <ReviewAdditionalInfo />}
      {experience === Experience.EMPLOYEE &&
            user?.customerInContext?.smallMid &&
            paymentMethodData?.preferredpaymentmethod === 'directDeposit' && <ReviewPaymentMethod />}
      <ReviewFraudStatement />
      {docUploadFlag && <ReviewDocumentUpload />}
      <Alert visible={!!submitErrors} type={'error'} data-testid='submit-error-alert' className={'mt-4 mb-5'}>
        <div className={`ml-4`}>{errors}</div>
        {/* <a href='/customer/public/contacts' target='blank' data-testid='contact-us'>{t(ReviewText.contactUs)}.</a> */}
      </Alert>
      <StepperButtons last lastButton={<ReviewSubmitButton />} type='continue' analyticsPageName='review'/>
    </div>);
};
export default Review;
