import React, { useState } from 'react';
import { Stepper } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import RabReport from './RabReport';
import RabReview from './RabReview';
import RabSuccess from './RabSuccess';
const RABREPORT_INITIAL_STATE = {
    numberOfBirth: '1',
    deliveryType: '',
    dateOfBirth: '',
    hours: '',
    minutes: '',
    ampm: 'am',
    lastDayWorked: '',
    hospitalAdmissionDate: '',
    bondingQuestionType: '',
};
const RabHome = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [rabReport, setRabReport] = useState(RABREPORT_INITIAL_STATE);
    const { t: translation } = useTranslation('rabTranslation');
    return (<div data-testid='rabHome'>
      {/* @ts-ignore */}
      <Stepper stepTitles={[translation('stepLabelReport'), translation('stepLabelReview'), translation('stepLabelSuccess')]} onStepChange={setCurrentPage}>
        <RabReport onComplete={setRabReport} rabCustomerConfData={props.rabCustomerConfData}/>
        <RabReview review={true} report={rabReport} claimNumber={props.claimNumber}/>
        <RabSuccess claimNumber={props.claimNumber} rabCustomerConfData={props.rabCustomerConfData} report={rabReport}/>
      </Stepper>
    </div>);
};
export default RabHome;
