import { Collapse, Table, TableBody, TableData, TableHeader, TableHeaderCol, TableRow, ThemeProvider, ToggleSwitch, themes, } from '@digitools/honest-react';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import BdmModalContainer from '../modals/BdmModalContainer';
import BdmBeneficiaryUtils from '../utils/BdmBeneficiaryUtils';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import { BdmDisplayTextEn as LanguageEn } from '../language/BdmDisplayTextEn';
import { BdmDisplayTextEs as LanguageEs } from '../language/BdmDisplayTextEs';
import { Language } from '../../../types/Language';
import { PRIMARY } from '../constants/BdmConstants';
const StyledTable = styled(Table) `
  overflow: hidden;
  margin-bottom: 0;
`;
const BdmBeneficiary = ({ beneficiaries, beneficiariesCount, beneficiaryCategory, equalShares, setEqualShares, setBeneficiaries, updateBeneficiaries, }) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        setHasLoaded(true);
    }, []);
    const totalAllocationPercentage = beneficiaries
        ? BdmBeneficiaryUtils.getTotalAllocationPercentage(beneficiaries, equalShares || false)
        : 0;
    const experienceContext = useContext(ExperienceContext);
    const displayText = experienceContext.language === Language.SPANISH ? LanguageEs : LanguageEn;
    const getCategoryTitle = (beneficiaryCategory) => {
        if (beneficiaryCategory === PRIMARY) {
            return displayText.primaryBeneficiariesTitle;
        }
        else {
            return displayText.contingentBeneficiariesTitle;
        }
    };
    const stylePrimary = { color: totalAllocationPercentage === 100 ? '#000' : '#F00' };
    const styleContingent = {
        color: totalAllocationPercentage === 0 || totalAllocationPercentage === 100 ? '#000' : '#F00',
    };
    const totalAllocationFontColor = beneficiaryCategory === PRIMARY ? stylePrimary : styleContingent;
    return (<ThemeProvider theme={themes.secureSite}>
      <Collapse headerElemType='h3' type='alternate' theme={themes.lincoln} title={`${getCategoryTitle(beneficiaryCategory)}`} isOpen={beneficiaryCategory === PRIMARY}>
        <BdmModalContainer beneficiaries={beneficiaries} updateBeneficiaries={updateBeneficiaries} beneficiaryCategory={beneficiaryCategory} toggleButtonState={equalShares || false} beneficiariesCount={beneficiariesCount}/>
        <StyledTable>
          <TableHeader>
            <TableRow isFilled={true}>
              <TableHeaderCol style={{ width: '64%' }}>{displayText.name}</TableHeaderCol>
              <TableHeaderCol>{displayText.type}</TableHeaderCol>
              <TableHeaderCol>{displayText.allocation} %</TableHeaderCol>
            </TableRow>
          </TableHeader>
          <TableBody>
            {BdmBeneficiaryUtils.generateBeneficiaryRows(beneficiaries, setBeneficiaries, equalShares || false, updateBeneficiaries)}
            {hasLoaded && (<TableRow style={{ background: '#e2edf6' }}>
                <TableData />
                <TableData>
                  <div>
                    <label className={'mr-2'}>{displayText.toggleLabel}</label>
                    <ToggleSwitch aria-label={'Split shares equally'} label={`${beneficiaryCategory} - Split shares equally`} field={`${beneficiaryCategory}SplitSharesEqually`} color={'primary'} toggleSize={'small'} initialValue={equalShares} // TODO: set hook up here - needs to be initially set to the response from the Get beneficiaries call
         onValueChange={setEqualShares}/>
                  </div>
                </TableData>
                <TableData style={{ textAlign: 'right' }}>
                  <span>{displayText.total}: </span>
                  {/* @ts-ignore */}
                  <span id={`${beneficiaryCategory}total-allocation`} style={totalAllocationFontColor}>{totalAllocationPercentage}%</span>
                </TableData>
              </TableRow>)}
          </TableBody>
        </StyledTable>
      </Collapse>
    </ThemeProvider>);
};
export default BdmBeneficiary;
