import React from 'react';
import useGet from '../../../hooks/useGet';

const useStatusDefinitionList = () => {
  const url = '/status-service/customer/statusReasonCode';

  const params = {
    applicationInternalName: 'STATUS_ER',
  };

  const { error, loading, data } = useGet({
    url,
    options: {
      params,
    },
  });

  return {
    statusDefinitionList: data,
    loading,
    error,
  };
};

export default useStatusDefinitionList;
