import React, { useMemo, useContext, useEffect } from 'react';

import { CompositeTable as DataTable, Alert, Icon } from '@digitools/honest-react';
import { Link } from 'react-router-dom';
import { Form, RadioGroup, Radio, useFieldState } from 'informed';
import { useLanguage, useExperience } from 'status/hooks';
import getClaimStatusDefinition from 'status/utilities/getClaimStatusDefinition';
import getProductAttributeCode from 'status/utilities/getProductAttributeCode';
import getLeaveStatusDefinition from 'status/utilities/getLeaveStatusDefinition';
import { encode } from 'status/utilities/hash';
import { useTranslation } from 'react-i18next';
import checkDate from 'status/utilities/checkDate';
import styles from './CoverageDetails.scss';
import { compareAsc, parse } from 'date-fns';
import DocumentUpload from '../../../../../components/documentUpload/DocumentUpload';
import useAuthentication from '../../../../../hooks/useAuthentication';
import CategorizationDocumentUpload from 'src/components/documentUpload/CategorizationDocumentUpload';
import { RtwContext } from "../../../context";
import useTealium from "src/hooks/useTealium";
import { TEALIUM_EVENT } from "src/constants/tealiumConstants";

const EventLinkCell = ({ data }) => {
  const { experience } = useExperience();
  const route = experience === 'ER' ? 'employer' : 'employee';
  const { trackEvent } = useTealium();
  let to = `/status/${route}/${encode(data._original.empNum)}/${data._original.url}/${encode(data.event)}/details`;
  to = data._original.fromTeamView ? `${to}?from=teamView` : to;
  if (data._original.search) {
    to = `${to}?search=${data._original.search}&searchCrit=${data._original.searchCrit}`;
  }
  return (
    <Link
      to={to}
      onClick={() =>
        trackEvent({
          event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
          event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
          event_name: data.event,
          event_results: data.product.toLowerCase(),
          event_version: 'details',
        })
      }>
      {data.event}
    </Link>
  );
};

const displayRtw = (exp, user) => {
  const applicationPermissions = user?.applicationPermissions;
  if (exp === 'ER') {
    return applicationPermissions?.indexOf('statusER.claimLeaveStatus.displayRtw') > -1;
  } else {
    return applicationPermissions?.indexOf('statusEE.claimLeaveStatus.displayRtw') > -1;
  }
}


const uploadDocumentsCell = ({ data }) => {
  // @ts-ignore
  // TODO: This looks like it's coming from an undefined context
  const { language } = useLanguage();
  const { experience } = useExperience();
  const { t } = useTranslation();
  const { saveRtwNavigation } = useContext(RtwContext);
  const { user } = useAuthentication();
  const { trackEvent } = useTealium();
  const route = experience === 'ER' ? 'employer' : 'employee';
  let to = `/status/${route}/${encode(data._original.empNum)}/${data._original.url}/${encode(data.event)}/details`;
  to = data._original.fromTeamView ? `${to}?from=teamView` : to;
  let rtwFlag = false;
  let docsNetStatusEn = ['In Progress', 'Open', 'Pended'];
  let docsNetStatusEs = ['En Progreso', 'Abierto', 'Pendiente'];
  let s1NetStatusEn = ['Pending', 'Approved', 'Pended'];
  let s1NetStatusEs = ['Pendiente', 'Aprobadoo'];
  let leaveDocsnetEn = ['Pending', 'Approved'];
  let leaveDocsnetEs = ['Pendiente', 'Aprobadoo'];

  if (displayRtw(experience, user)) {
    if (language === 'en') {
      if (data.product === 'Leave') {
        rtwFlag =
          data._original.crdClm === '0' &&
          leaveDocsnetEn.includes(data.status) &&
          data.leaveCategory === 'Continuous';
      } else {
        rtwFlag = user.customerInContext.smallMid
          ? docsNetStatusEn.includes(data.status)
          : s1NetStatusEn.includes(data.status);
      }
    } else {
      if (data.product === 'Leave') {
        rtwFlag =
          data._original.crdClm === '0' &&
          leaveDocsnetEs.includes(data.status) &&
          data.leaveCategory === 'Continuo';
      } else {
        rtwFlag = user.customerInContext.smallMid
          ? docsNetStatusEs.includes(data.status)
          : s1NetStatusEs.includes(data.status);
      }
    }
  }

  return (
    <div>
      {user.customerInContext.smallMid &&
        <CategorizationDocumentUpload
          eventNum={data.event}
          productName={data.coverage.trim()}
          displayLabel={true}
          renderAsLink={true}
          key={'CategorizationDocumentUpload' + data.event}
        />}
      {!user.customerInContext.smallMid &&
        <DocumentUpload
          eventNum={data.event}
          productName={data.coverage.trim()}
          displayLabel={true}
          renderAsLink={true}
          key={'docUpload' + data.event}
        />}
      {rtwFlag ? (
        <div style={{ wordBreak: 'break-all', fontSize: '12px', display: 'flex', marginTop: '15%' }}>
          <Icon name='calendar-marked-dates' color={'secondary'} style={{ paddingRight: '.25rem' }} />
          <Link
            to={to}
            data-testid='rtwnavigation'
            onClick={() => {
              saveRtwNavigation(true);
              trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                event_name: 'return to work',
              });
            }}>
            {t('tab')}
          </Link>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const NotFoundError = () => <Alert type={'info'}>No claims or leaves were found for this employee.</Alert>;
const Error = () => (
  <Alert type={'error'}>Something went wrong when loading claims and leaves for this employee.</Alert>
);

const AllTable = ({ params, claims, leaves, empNum, error, statusDefinitionList, prodAttCodes, fromTeamView }) => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const tableFilterState = useFieldState('tableFilter');
  const { RemoveRtwNavigation } = useContext(RtwContext);
  const tableFilterValue = tableFilterState ? tableFilterState.value : '';
  const showClaims = tableFilterValue === 'all' || tableFilterValue === 'claims';
  const showLeaves = tableFilterValue === 'all' || tableFilterValue === 'leaves';

  useEffect(() => {
    RemoveRtwNavigation();
  }, []);
  if (claims?.length === 0 && leaves?.length === 0) {
    return <NotFoundError />;
  }

  if (error && error.response.status !== 404) {
    return <Error />;
  }

  const formattedData = useMemo(() => {
    const data = [];

    // Format claims
    if (claims && showClaims) {
      claims.forEach(claim => {
        const status = getProductAttributeCode(
          language,
          prodAttCodes,
          'Claim',
          'Status',
          claim.clmSttusCde,
          user.customerInContext?.smallMid,
        );

        const statusDefinition = getClaimStatusDefinition(
          language,
          statusDefinitionList,
          claim.prodType,
          claim.clmSttusCde,
          claim.clmClosureReasCde,
          claim.clmPendReasCde,
          user.customerInContext?.smallMid,
        );

        const formattedClaim = {
          product: 'Claim',
          event: claim.clmNum,
          receivedDate: claim.clmRecdDte && claim.clmRecdDte.split(' ')[0],
          determinationDate: checkDate(claim.clmDtrmDte),
          coverage: claim.prodType,
          status,
          statusDefinition,
          uploadDocuments: '',
          empNum,
          url: 'claims',
          fromTeamView: fromTeamView,
          ...params,
        };
        data.push(formattedClaim);
      });
    }

    // Format Leaves
    if (leaves && showLeaves) {
      leaves.forEach(leave => {
        const status = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Status', leave.lveSttusCde);

        const statusDefinition = getLeaveStatusDefinition(
          language,
          statusDefinitionList,
          leave.lveType,
          leave.lveSttusCde,
          leave.lveSttusReasCde,
        );

        const formattedLeave = {
          product: 'Leave',
          event: leave.lveNum,
          receivedDate: leave.lveRecdDte && leave.lveRecdDte.split(' ')[0],
          determinationDate: checkDate(leave.lveDtrmDte),
          coverage: 'Leave',
          status,
          statusDefinition,
          uploadDocuments: '',
          empNum,
          url: 'leaves',
          crdClm: leave.coordClmNum,
          fromTeamView: fromTeamView,
          leaveCategory: getProductAttributeCode(language, prodAttCodes, 'Leave', 'Category', leave.lveCatg),
          ...params,
        };
        data.push(formattedLeave);
      });
    }

    return data;
  }, [leaves, claims, showClaims, showLeaves, language]);

  const columns = useMemo(() => {
    var cols = [
      {
        Header: t('productText'),
        accessor: 'product',
        id: 'product',
      },
      {
        Cell: EventLinkCell,
        Header: t('eventNumberText'),
        accessor: 'event',
        id: 'event',
      },
      {
        Header: t('receivedDateText'),
        accessor: 'receivedDate',
        id: 'receivedDate',
        sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
      },
      {
        Header: t('coverageText'),
        accessor: 'coverage',
        id: 'coverage',
      },
      {
        Header: t('leaveCategoryText'),
        accessor: 'leaveCategory',
        id: 'leaveCategory',
      },
      {
        Header: t('statusText'),
        accessor: 'status',
        id: 'status',
      },
      {
        Header: t('statusDefinitionText'),
        accessor: 'statusDefinition',
        id: 'statusDefinition',
      },
      {
        Header: t('determinationDateText'),
        accessor: 'determinationDate',
        id: 'determinationDate',
        sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
      }
    ];
    if (!user?.customerInContext?.smallMid || (user?.customerInContext?.smallMid)) {
      cols.push({
        Cell: uploadDocumentsCell,
        Header: t('claimManagement'),
        accessor: 'uploadDocuments',
        id: 'uploadDocuments',
      });
    }
    user?.customerInContext?.smallMid && cols.splice(4, 1);
    return cols;
  }, [leaves, claims, language]);

  const defaultSorted = [
    {
      id: 'receivedDate',
      desc: true,
    },
  ];

  return (
    <div>
      {user?.customerInContext?.smallMid ? (
        <>
          <RadioGroup field='tableFilter' initialValue='all' hidden={true}></RadioGroup>
        </>
      ) : (
        <div className={styles.allTableFilterGroup}>
          <span className={styles.allTableFilterHeader}>Show:</span>
          <RadioGroup field='tableFilter' initialValue='all'>
            <label>
              <Radio value='all' />
              All
            </label>
            <label>
              <Radio value='claims' />
              Claims
            </label>
            <label>
              <Radio value='leaves' />
              Leaves
            </label>
          </RadioGroup>
        </div>
      )}
      <DataTable
        data={formattedData}
        columns={columns}
        defaultSorted={defaultSorted}
        paginated={formattedData.length > 10}
      />
    </div>
  );
};

const FormTable = props => {
  return (
    <Form>
      <AllTable {...props} />
    </Form>
  );
};

export default FormTable;
