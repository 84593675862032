import { Heading, Row, Col, themes } from '@digitools/honest-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../../hooks/useAuthentication';
import RabViewConfirmationModal from '../modal/RabViewConfirmationModal';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
const RabSuccess = (props) => {
    const { t: translation } = useTranslation('rabTranslation');
    const { user } = useAuthentication();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.RAB,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.RAB.SUCCESS,
        });
    }, []);
    return (<div data-testid='rabSuccess' data-public={true} style={{ marginTop: '2rem' }}>
      <Heading elemType={'h2'} theme={themes.lincoln} color={'primary'}>
        {translation('stepLabelSuccess').toString()}
      </Heading>
      <Row>
        <Heading elemType={'h3'} theme={themes.lincoln} data-private={true}>
          {translation('confirmationText').replace('{claimantFirstName}', user.firstName)}
        </Heading>
      </Row>
      <Row>
        <Col>
          <>
            {translation('claimAppliedConfText')}
            &nbsp;{props.claimNumber}.&nbsp;
            {translation('caseManagerText')}
          </>
        </Col>
      </Row>
      <Row>
        <Col>{props.rabCustomerConfData.customConfirmationText}</Col>
      </Row>
      <Row>
        <Col>
          <RabViewConfirmationModal claimNumber={props.claimNumber} report={props.report}/>
        </Col>
      </Row>
    </div>);
};
export default RabSuccess;
