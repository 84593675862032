import { Alert, Box, Button, Col, Form, Loader, Row, TextInput, themes } from '@digitools/honest-react';
import { Heading } from '@digitools/honest-react/dist/lib/components/Heading';
import queryString from 'query-string';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'src/utils/withRouter';
import styled, { css } from 'styled-components';
import Notifications, { FunctionalArea } from '../../../../components/Notifications/Notifications';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useRequiredField from '../../../../hooks/fieldValidation/useRequiredField';
import useAuthentication from '../../../../hooks/useAuthentication';
import useExperience from '../../../../hooks/useExperience';
import usePortalMessenger from '../../../../hooks/usePortalMessenger';
import usePortalStatus from '../../../../hooks/usePortalStatus';
import useTealium from '../../../../hooks/useTealium';
import useTranslate from '../../../../hooks/useTranslate';
import { EventAction, GoogleAnalyticsContext, } from 'src/providers/GoogleAnalyticsProvider';
import { UnexpectedErrorContext } from 'src/providers/UnexpectedErrorProvider';
import { Language } from 'src/types/Language';
import { gaLabelFactory } from 'src/utils/gaUtils';
import getDisplayText from '../../../../utils/languageDisplay';
import * as actions from '../actions';
import { LoginDisplayTextEn } from '../i18n/LoginDisplayTextEn';
import { LoginDisplayTextEs } from '../i18n/LoginDisplayTextEs';
import reducer, { initialState } from '../reducer';
import EmailVerificationHelperModal from './EmailVerificationHelperModal';
import { getEnvVariable } from 'src/utils/envUtils';
import { EEMigrationLoginText } from './../i18n/EEMigrationLoginText';
import { StyledBox } from '../../vanityPage/components/StyledComponents';
const GA_FEATURE_PREFIX = 'LOGIN';
const generateGALabel = gaLabelFactory(GA_FEATURE_PREFIX);
const disabledOverlayRules = css `
  opacity: 0.4;
  pointer-events: none;
`;
const DisabledOverlay = styled.div `
  ${({ maintenanceModeEnabled }) => (maintenanceModeEnabled ? disabledOverlayRules : undefined)};
`;
const lfgHomeLogin = `${getEnvVariable('lfgLoginUrl')}`;
const LoginBox = ({ location, enableEmployeeMigration, isEmployee, isEmployer, }) => {
    const { trackError } = useContext(GoogleAnalyticsContext);
    const { login, logout, user } = useAuthentication();
    const { language } = useExperience();
    const { setIsMessagingDisabled } = useContext(UnexpectedErrorContext);
    const { isStatusLoading, maintenanceModeEnabled, maintenanceModeBackdoorAccessEnabled } = usePortalStatus();
    const { addMessage, clearMessages } = usePortalMessenger();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const isBackDoor = location.pathname === '/backdoor/login';
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, LoginDisplayTextEn, LoginDisplayTextEs, language]);
    const validateUsernameRequired = useRequiredField({
        [Language.ENGLISH]: 'Username',
        [Language.SPANISH]: 'Nombre de usuario',
    });
    const validatePasswordRequired = useRequiredField({
        [Language.ENGLISH]: 'Password',
        [Language.SPANISH]: 'Contraseña',
    });
    const [loginAlert, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        if (user) {
            logout();
        }
        if (sessionStorage.getItem('isLfgUser') === 'true')
            window.location.href = getEnvVariable('lfgHomeUrl') + `/secure/logout`;
        setIsMessagingDisabled(false);
        const values = queryString.parse(location.search);
        if (values.success === 'true' &&
            values.message === 'Your email was verified. You can continue using the application.') {
            dispatch(actions.emailVerified());
        }
        if (values.success === 'true' && values.message === 'Your account has been unblocked.') {
            dispatch(actions.accountUnblocked());
        }
        if (values.success === 'false' && values.message === 'Access expired.' && values.verificationEmail === 'true') {
            dispatch(actions.emailVerificationExpired());
        }
        if (values.success === 'false' && values.message === 'Access expired.' && values.changePassword === 'true') {
            dispatch(actions.passwordEmailExpired());
        }
        // Scheduled Maintenance Portal Message
        if (maintenanceModeEnabled) {
            addMessage({
                type: 'warning',
                title: [LoginDisplayTextEn['portalDownTitle'], LoginDisplayTextEs['portalDownTitle']],
                message: [<>{LoginDisplayTextEn['portalDownBody']}</>, <>{LoginDisplayTextEs['portalDownBody']}</>],
            });
        }
        return () => {
            clearMessages();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const clearError = () => {
        dispatch(actions.clear());
    };
    const handleLoginError = (err) => {
        if (err && err.code === 'access_denied') {
            trackError(EventAction.CLICK, generateGALabel('auth0-login-access_denied'));
            dispatch(actions.accessDenied());
        }
        else if (err && err.code === 'too_many_attempts') {
            trackError(EventAction.CLICK, generateGALabel('auth0-login-too_many_attempts'));
            dispatch(actions.tooManyAttempts());
        }
        else {
            trackError(EventAction.CLICK, generateGALabel('auth0-login-unexpected'));
            dispatch(actions.unexpected());
        }
        setIsLoading(false);
    };
    const handleSubmit = async ({ username, password }) => {
        dispatch(actions.clear());
        setIsLoading(true);
        try {
            login(username, password, handleLoginError);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: TEALIUM_EVENT.EVENT_NAME.LOGIN,
                event_type: TEALIUM_EVENT.EVENT_TYPE.LOGIN,
                event_version: TEALIUM_EVENT.EVENT_VERSION.ACCOUNT_LOOKUP,
                event_status: TEALIUM_EVENT.EVENT_STATUS.SUCCESS,
            });
        }
        catch (e) {
            const { response: { status = 500 } = { status: 500 } } = e;
            if (status === 400 || status === 401) {
                dispatch(actions.userProfileNotFound());
            }
            else {
                trackError(EventAction.CLICK, generateGALabel('login-generic-error'));
            }
            setIsLoading(false);
        }
    };
    const trackForgotUsername = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: enableEmployeeMigration ? TEALIUM_EVENT.EVENT_NAME.FORGOT_PASSWORD_AND_USERNAME : TEALIUM_EVENT.EVENT_NAME.FORGOT_PASSWORD,
            event_type: TEALIUM_EVENT.EVENT_TYPE.LOGIN,
            event_version: TEALIUM_EVENT.EVENT_VERSION.ACCOUNT_LOOKUP,
        });
    };
    const trackRegisterUser = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: TEALIUM_EVENT.EVENT_NAME.REGISTER,
            event_type: TEALIUM_EVENT.EVENT_TYPE.LOGIN,
            event_version: TEALIUM_EVENT.EVENT_VERSION.ACCOUNT_LOOKUP,
        });
    };
    const trackLearnMoreEvent = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: TEALIUM_EVENT.EVENT_NAME.LET_US_HELP,
            event_type: TEALIUM_EVENT.EVENT_TYPE.LOGIN,
            event_version: TEALIUM_EVENT.EVENT_VERSION.ACCOUNT_LOOKUP,
        });
    };
    const trackEmailVerificationHelp = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'email verification help',
            event_type: enableEmployeeMigration ? TEALIUM_EVENT.EVENT_TYPE.MIGRATION : TEALIUM_EVENT.EVENT_TYPE.LOGIN,
        });
    };
    const format = (value) => (value != null ? value.trim() : value);
    const parse = (value) => (value != null ? value.trim() : value);
    const RegisterAccountEmailVerify = () => {
        return (<>
        <Row>
          <Col>
            {enableEmployeeMigration ? isEmployer ? t(EEMigrationLoginText.newErOrBroker) : 'Employees' : t(EEMigrationLoginText.noOnlineAccount)}{' '}
            {enableEmployeeMigration && isEmployee ?
                <a aria-label={'register for an account'} href='https://www.lincolnfinancial.com' onClick={trackRegisterUser} data-testid={'register-link'}>
                {t(EEMigrationLoginText.registerNow)}
              </a> :
                <Link aria-label={'register for an account'} to={'/public/registration'} onClick={trackRegisterUser} data-testid={'register-link'}>
                {enableEmployeeMigration ? t(EEMigrationLoginText.registerNow2) : t(EEMigrationLoginText.registerNow)}
              </Link>}
          </Col>
        </Row>
        {!enableEmployeeMigration && <Row>
          <Col>
            <EmailVerificationHelperModal trackEmailVerificationHelp={trackEmailVerificationHelp} enableEmployeeMigration={enableEmployeeMigration}/>
          </Col>
        </Row>}
      </>);
    };
    //TODO: collapse into main jsx - no need for sub-comp
    const EEMigrationLoginHeading = () => {
        return (<>
        <Row>
          <Col xl={12}>
            <Heading elemType={'h2'} elemStyle={'h3'} data-public={true} color='primary' theme={themes.lincoln} style={{ fontSize: '30px' }}>
              {getLocalDisplayText('login')}
            </Heading>
          </Col>
          <Col xl={12} className={'mt-3 mb-4'} data-public={true}>
              <Link aria-label={'forgot username or password'} to={{ pathname: '/public/forgot-info', search: isEmployee ? '?employee=true' : '?employer=true' }} onClick={trackForgotUsername} data-testid={'forgot-username-link'}>
                {getLocalDisplayText('forgotUserNamePassword')}
              </Link>
          </Col>
        </Row>
      </>);
    };
    const BoxToUse = enableEmployeeMigration ? StyledBox : Box;
    return (<BoxToUse id='LoginBox' className={enableEmployeeMigration ? '' : 'p-4'}>
    <Loader id='login-spinner' isShown={isLoading || isStatusLoading} data-testid={'loading-indicator'}>
      <Notifications functionalArea={FunctionalArea.LOGIN}/>
      <DisabledOverlay maintenanceModeEnabled={(maintenanceModeEnabled || maintenanceModeBackdoorAccessEnabled) && !isBackDoor} data-testid={'maintenance-overlay'}>
        <EEMigrationLoginHeading />
        {!!loginAlert && (<Alert data-public={true} type={loginAlert.type} data-testid={'alert-message'}>
            <div>
              <strong>{getLocalDisplayText(loginAlert.alertTitle)}</strong> {getLocalDisplayText(loginAlert.alertBody)}
            </div>
            <br />
            {enableEmployeeMigration ? (<div>
                {t(EEMigrationLoginText.additionalLoginFailedInfo)}
                <Link to={lfgHomeLogin}>LincolnFinancial.com</Link>
              </div>) : (<></>)}
          </Alert>)}

        {location?.state?.state?.error?.message && (<>
            {location?.state?.state?.error?.message.startsWith('Unable to configure verification page') ? (<Alert data-public={true} type={'warning'} data-testid={'tpcookies-message'}>
                <div>
                  <strong>{getLocalDisplayText('notice')}</strong>
                  {getLocalDisplayText('thirdPartyCookies')}
                </div>
              </Alert>) : (<Alert data-public={true} type={'error'} data-testid={'auth0-alert'}>
                <div>
                  <strong>{getLocalDisplayText('unexpectedTitle')}</strong>
                  {getLocalDisplayText('auth0error')}
                </div>
              </Alert>)}
          </>)}
        <Form onSubmit={handleSubmit}>
          <TextInput data-public={true} data-testid={'username-field'} field={'username'} format={format} label={getLocalDisplayText('username')} placeholder={getLocalDisplayText('usernamePlaceholder')} onChange={clearError} parse={parse} validate={validateUsernameRequired} validateOnBlur={true}/>

          <TextInput data-private={true} data-testid={'password-field'} field={'password'} label={getLocalDisplayText('password')} placeholder={getLocalDisplayText('passwordPlaceholder')} onChange={clearError} type={'password'} validate={validatePasswordRequired} validateOnBlur={true} toggleShowPassword={true}/>

          <Button data-public={true} buttonType={'primary'} type={'submit'} className={'mt-3 d-block w-100'} data-testid={'submit-button'}>
            {getLocalDisplayText('login')}
          </Button>
        </Form>
        <Row>
          <Col className={'mt-3'}>
            <RegisterAccountEmailVerify />
          </Col>
        </Row>
        {isEmployee && <Row>
          <Col>
            {t(EEMigrationLoginText.whereToLogIn)}
            <Link data-testid="get-help-link" onClick={trackLearnMoreEvent} to={{
                pathname: '/public/migration-help',
                search: `?from=ee-login-help${language === Language.SPANISH ? '&lang=es' : ''}`,
            }}>
                {t(EEMigrationLoginText.getHelp)}
            </Link>
          </Col>
        </Row>}
        
      </DisabledOverlay>
    </Loader>
    </BoxToUse>);
};
export default withRouter(LoginBox);
