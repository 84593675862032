import React from 'react';
import LanguageToggle from '../../../utils/LanguageToggle';
import { validationDisplayTextEs } from "src/utils/language/validationDisplayTextEs";
import { validationDisplayTextEn } from "src/utils/language/validationDisplayTextEn";
import AsGeneralText from '../i18n/AsVerificationText';
import { AboutYouText } from '../constants/AboutYouText';
import { addDays, format, isBefore, isEqual, parse } from 'date-fns';
export const fieldRequired = (value) => {
    if (!value) {
        return <LanguageToggle en={AsGeneralText.verifyRequiredError.english} es={AsGeneralText.verifyRequiredError.spanish}/>;
    }
    return undefined;
};
export const validateEmail = (email) => {
    const regex = RegExp(/^([0-9a-zA-Z]([-\.\w]*[0-9a-zA-Z])*@([-\_.]*[0-9a-zA-Z]*[0-9a-zA-Z])*.[a-zA-Z]{2,9})$/i);
    if (!email) {
        return fieldRequired(email);
    }
    else if (!email.match(regex) || email.length >= 140) {
        return <LanguageToggle en={validationDisplayTextEn.invalidEmail} es={validationDisplayTextEs.invalidEmail}/>;
    }
    return undefined;
};
export const validateDateIsOnOrBeforeToday = (input, t, required) => {
    const today = new Date();
    const inputDate = new Date(input);
    if (input && inputDate > today) {
        return <LanguageToggle en={AsGeneralText.dateFutureError.english} es={AsGeneralText.dateFutureError.spanish}/>;
    }
};
export const validateAddress = (address) => {
    if (!address) {
        return undefined;
    }
    else if (!address.match(/^[0-9A-Za-z .,?#'-]{1,30}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidAddress} es={validationDisplayTextEs.invalidAddress}/>;
    }
    return undefined;
};
export const validateCity = (city) => {
    if (!city) {
        return undefined;
    }
    if (!city.match(/^[ a-zA-Z.'-]{1,30}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidCity} es={validationDisplayTextEs.invalidCity}/>;
    }
    return undefined;
};
export const validateTextArea = (value) => {
    const regex = /^[-a-zA-Z0-9' .,:;_#/!*+=?&<>$@%(){}\s]{0,250}$/;
    if (!value) {
        return fieldRequired(value);
    }
    else if (!regex.test(value)) {
        return <LanguageToggle en={AsGeneralText.invalidCharactersMessage.english} es={AsGeneralText.invalidCharactersMessage.spanish}/>;
    }
};
export const validatePostalCode = (postalCode, claimantData) => {
    const invalidPostalError = <LanguageToggle en={validationDisplayTextEn.invalidPostal} es={validationDisplayTextEs.invalidPostal}/>;
    //@ts-ignore  
    const selectedCountryID = claimantData.claimantData.empCtryCde;
    const canadaPostalCodeRegex = /^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/;
    const usaPostalCodeRegex = /^\d{5}(?:[-]\d{4})?$/;
    if (!postalCode) {
        return undefined;
    }
    else if (selectedCountryID === 'USA') {
        if (!postalCode.match(usaPostalCodeRegex))
            return invalidPostalError;
    }
    else if (selectedCountryID === 'CAN') {
        if (!canadaPostalCodeRegex.test(postalCode))
            return invalidPostalError;
    }
};
export const validateName = (name, t) => {
    const regex = RegExp(/^([-\\.'a-zA-Z]|^[-\\.'a-zA-Z][-\\.' a-zA-Z]{0,33}[-\\.'a-zA-Z])$/gm);
    if (name && !regex.test(name)) {
        return <LanguageToggle en={AboutYouText.nameError.english} es={AboutYouText.nameError.spanish}/>;
    }
    else if (!name) {
        return fieldRequired(name);
    }
    return undefined;
};
export const validateAccommodationDetail = (value, accommodationType) => {
    const regex = /^[-a-zA-Z0-9' .,:;_#/!*+=?&<>$@%(){}\s]{0,250}$/;
    if (!regex.test(value)) {
        return <LanguageToggle en={AsGeneralText.invalidCharactersMessage.english} es={AsGeneralText.invalidCharactersMessage.spanish}/>;
    }
    else if (!value && accommodationType === "13") {
        return fieldRequired(value);
    }
};
export const isDateInGivenRange = (days, receivedDate) => {
    if (!receivedDate) {
        return fieldRequired(receivedDate);
    }
    else {
        const datePolicy = parse(format(addDays(new Date(), -days), 'P'), 'P', new Date());
        const formattedReceivedDate = parse(receivedDate, 'P', new Date());
        if (isBefore(formattedReceivedDate, datePolicy)) {
            return 'The date you provided is outside your company\'s policy';
        }
        return undefined;
    }
};
export const handleDateChange = (receivedDate, receivedRequestDate, errorFor) => {
    if (!receivedDate && errorFor === 'eventReceivedDate') {
        return fieldRequired(receivedDate);
    }
    else {
        if (receivedDate && receivedRequestDate) {
            if (receivedDate > receivedRequestDate) {
                if (errorFor === 'anticipatedEndDate')
                    return 'Date must be after the Requested Start Date';
                else if (errorFor === 'eventReceivedDate')
                    return 'Date must be equal to or after Event Received Date.';
                else if (errorFor === 'startDate')
                    return 'Date must be equal before the anticipated End Date.';
            }
        }
        return undefined;
    }
};
export const validateEndDate = (requestedStartDate, anticipatedEndDate) => {
    // const formattedRequestedStartDate = format(new Date(requestedStartDate), 'P');
    if (isBefore(parse(anticipatedEndDate, 'P', new Date()), new Date(requestedStartDate))) {
        return (<LanguageToggle en={AsGeneralText.endDateIsBefore.english} es={AsGeneralText.endDateIsBefore.spanish}/>);
    }
    else if (isEqual(new Date(anticipatedEndDate), new Date(requestedStartDate))) {
        return (<LanguageToggle en={AsGeneralText.endDateIsEqual.english} es={AsGeneralText.endDateIsEqual.spanish}/>);
    }
    else {
        return undefined;
    }
};
export const validateCost = (cost) => {
    const regex = RegExp('^\\d{1,14}(?:\\.\\d{1,2})?$');
    if (cost && !cost.match(regex)) {
        return 'Enter valid dollar amount greater than or equal to 0 and less than or equal to 999999999.';
    }
    return undefined;
};
export const normalizeNewLines = (value) => {
    if (!value) {
        return value;
    }
    return value.replace(/[\r\n]/g, '');
};
