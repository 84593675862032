import { Col, Heading, Loader, Row } from '@digitools/honest-react';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useExperience from '../../../../hooks/useExperience';
import { EventAction, EventCategory, GoogleAnalyticsContext, } from 'src/providers/GoogleAnalyticsProvider';
import { gaLabelFactory } from 'src/utils/gaUtils';
import getDisplayText from '../../../../utils/languageDisplay';
import ForgotInfoDisplayTextEn from '../i18n/ForgotInfoDisplayTextEn';
import ForgotInfoDisplayTextEs from '../i18n/ForgotInfoDisplayTextEs';
import ForgotPassword from './ForgotPassword';
import ForgotUsername from './ForgotUsername';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import { withRouter } from 'src/utils/withRouter';
import { getEnvVariable } from 'src/utils/envUtils';
const lfgHomeLogin = `${getEnvVariable('lfgLoginUrl')}`;
const GA_FEATURE_PREFIX = 'FORGOT_INFO';
const generateGALabel = gaLabelFactory(GA_FEATURE_PREFIX);
const ForgotInfo = () => {
    const { trackEvent } = useContext(GoogleAnalyticsContext);
    const [isLoading, setIsLoading] = useState(false);
    const { language, experience } = useExperience();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.FORGOT_INFO,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.FORGOT_INFO.VIEW,
        });
    }, []);
    const getLocalDisplayText = (key) => getDisplayText.apply({}, [key, ForgotInfoDisplayTextEn, ForgotInfoDisplayTextEs, language]);
    const trackBackToLogin = () => {
        trackEvent(EventCategory.LINK, EventAction.CLICK, generateGALabel('back-to-login'));
    };
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const from = urlParams.get('employee');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const isEmployee = urlParams.get('employee');
    console.log('hello employee is', isEmployee);
    // const isEmployer = urlParams.get('employer');
    // console.log('hello employer is', isEmployer);
    const loginUrlParam = isEmployee ? '?employee=true' : '?employer=true';
    return (<div data-public={true}>
      <Loader id='forgot-info-spinner' isShown={isLoading} data-testid={'loading-indicator'}>
        <Row className={'mt-3'}>
          <Col offset={'md-3'} md={6} className={'position-relative'}>
          <div className={'mb-3'}>
              <Link to={`/public/login${loginUrlParam}`} onClick={trackBackToLogin} data-testid={'forgot-username-link'}>
                {getLocalDisplayText('backToLogin')}
              </Link>
            </div>
            {getEnvVariable("enableEmployeeMigration") && <><Heading elemType={'h1'} elemStyle={'h2'} color={'primary'} style={{ fontWeight: '400', fontSize: '30px', marginBottom: '.5rem' }}>
                {getLocalDisplayText('movedMessage')}
                <br />
            </Heading>
            {getLocalDisplayText('mightHaveMoved')} <Link to={lfgHomeLogin}>{getLocalDisplayText('here')}</Link>.
            <br />
            <br />
            </>}
            <div style={{ marginBottom: '1rem' }}>
              <ForgotUsername setIsLoading={setIsLoading}/>
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <ForgotPassword setIsLoading={setIsLoading}/>
            </div>
          </Col>
        </Row>
      </Loader>
    </div>);
};
export default withRouter(ForgotInfo);
