import React from 'react';
import { ApplicantInformationText, ContactInformationText } from '../../../../i18n/ApplicantInformationText';
import useTranslate from '../../../../../../hooks/useTranslate';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import ReviewAndSignField from './ReviewAndSignField';
import { Col } from '@digitools/honest-react/dist/lib/components/Col';
import { EOIHelpText } from '../../../../i18n/EoiText';
import ReviewAndSignHeader from './ReviewAndSignHeader';
import useEoiContext from '../../../../hooks/useEoiContext';
const ContactInformation = () => {
    const { t } = useTranslate();
    const { interviewAnswers, initialInformation } = useEoiContext();
    const employee = interviewAnswers && interviewAnswers.applicants && interviewAnswers.applicants.employee;
    const contactInformation = employee && employee.contactInformation;
    const getPhoneNumberType = (phoneNumberType) => {
        switch (phoneNumberType) {
            case 'HM': {
                return t(ContactInformationText.typeHome);
            }
            case 'OFF': {
                return t(ContactInformationText.typeWork);
            }
            case 'MBL': {
                return t(ContactInformationText.typeMobile);
            }
            default: {
                return '';
            }
        }
    };
    const getCommunicationPreference = (communicationPreference) => {
        switch (communicationPreference) {
            case 'EML': {
                return t(EOIHelpText.emailText);
            }
            case 'USP': {
                return t(EOIHelpText.mailText);
            }
            default: {
                return '';
            }
        }
    };
    const formatCountry = (countryCode) => {
        const country = initialInformation.locations.countries.filter((location) => location.value === countryCode);
        if (country && country.length > 0) {
            return country.shift().label;
        }
        else {
            return countryCode;
        }
    };
    return (contactInformation && initialInformation ?
        <div style={{ marginTop: '2.5rem' }} data-testid={'review-contact-info'}>
      <ReviewAndSignHeader title={t(ApplicantInformationText.contactInformation)} step={1}/>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={3} sm={12}>
          <ReviewAndSignField label={ContactInformationText.phoneNumberType} value={getPhoneNumberType(contactInformation.phoneNumberType)}/>
        </Col>
        <Col md={3} sm={12}>
          <ReviewAndSignField label={ContactInformationText.phoneNumber} value={contactInformation.phoneNumber}/>
        </Col>
        <Col md={6} sm={12}>
          <ReviewAndSignField label={EOIHelpText.emailText} value={contactInformation.emailAddress}/>
        </Col>
      </Row>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={6} sm={12}>
          <ReviewAndSignField label={ContactInformationText.communicationPreference} value={getCommunicationPreference(contactInformation.communicationPreference)}/>
        </Col>
      </Row>

      <div style={{ display: 'flex', marginTop: '1rem', marginBottom: '0.5rem', marginLeft: '2rem', flexDirection: 'column' }}>
        <Label theme={themes.lincoln}>{t(ContactInformationText.residentialAddress)}:</Label>
        {contactInformation.useAsMailingAddress &&
                <div style={{ display: 'flex', alignItems: 'center', color: themes.lincoln.palette.accent1Darker }}>
            <Icon name={'check-circle'} theme={themes.lincoln} style={{ marginRight: '0.25rem' }} color={'accent1Darker'}/> 
            {t(ContactInformationText.useAsMyMailingAddress)}
          </div>}
      </div>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={6} sm={12}>
          <ReviewAndSignField label={ContactInformationText.residentialAddress1} value={contactInformation.residentialAddressField1}/>
        </Col>
        <Col md={6} sm={12}>
          <ReviewAndSignField label={ContactInformationText.residentialAddress2} value={contactInformation.residentialAddressField2}/>
        </Col>
      </Row>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={3} sm={12}>
          <ReviewAndSignField label={ContactInformationText.country} value={formatCountry(contactInformation.residentialCountry)}/>
        </Col>
        <Col md={3} sm={12}>
          <ReviewAndSignField label={ContactInformationText.state} value={contactInformation.residentialState}/>
        </Col>
        <Col md={3} sm={12}>
          <ReviewAndSignField label={ContactInformationText.mailingCity} value={contactInformation.residentialCity}/>
        </Col>
        <Col md={3} sm={12}>
          <ReviewAndSignField label={ContactInformationText.zipCode} value={contactInformation.residentialZipCode}/>
        </Col>
      </Row>

      {!contactInformation.useAsMailingAddress &&
                <>
        <Label theme={themes.lincoln} style={{ marginTop: '1rem', marginBottom: '0.5rem', marginLeft: '2rem' }}>{t(ContactInformationText.mailingAddress)}:</Label>
        <Row style={{ marginLeft: '1rem' }}>
          <Col md={6} sm={12}>
            <ReviewAndSignField label={ContactInformationText.mailingAddress1} value={contactInformation.mailingAddressField1}/>
          </Col>
          <Col md={6} sm={12}>
            <ReviewAndSignField label={ContactInformationText.mailingAddress2} value={contactInformation.mailingAddressField2}/>
          </Col>
        </Row>
        <Row style={{ marginLeft: '1rem' }}>
          <Col md={3} sm={12}>
            <ReviewAndSignField label={ContactInformationText.country} value={formatCountry(contactInformation.mailingCountry)}/>
          </Col>
          <Col md={3} sm={12}>
            <ReviewAndSignField label={ContactInformationText.state} value={contactInformation.mailingState}/>
          </Col>
          <Col md={3} sm={12}>
            <ReviewAndSignField label={ContactInformationText.mailingCity} value={contactInformation.mailingCity}/>
          </Col>
          <Col md={3} sm={12}>
            <ReviewAndSignField label={ContactInformationText.zipCode} value={contactInformation.mailingZipCode}/>
          </Col>
        </Row>
      </>} 
    </div>
        : <></>);
};
export default ContactInformation;
