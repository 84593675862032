import React, { useEffect } from 'react';
import { Alert, Button, Col, Heading, Row } from '@digitools/honest-react';
import { useNavigate } from 'react-router-dom';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useExperience from 'src/hooks/useExperience';
import useTealium from 'src/hooks/useTealium';
import useTranslate from 'src/hooks/useTranslate';
import { Experience } from 'src/types/Experience';
import ImportantMessageBox from 'src/packages/cli/components/ImportantMessageBox';
import { StepsDiv, StickyDiv } from '../../components/LayoutComponents';
import { RIA_TEXT } from '../../constants/RiaConstants';
import useRiaConfig from '../../hooks/useRiaConfig';
import useRiaData from '../../hooks/useRiaData';
import ConfirmationLeaveCard from './ConfirmationLeaveCard';
const Confirmation = () => {
    const { t } = useTranslate();
    const { newAbsenceDateTimes, updatedAbsenceDateTimes, currentLeave, leaves, setNewAbsenceDateTimes, setCurrentLeave, setUpdatedAbsenceDateTimes, callForAbsences, } = useRiaData();
    const { riaConfig } = useRiaConfig();
    const navigate = useNavigate();
    const { trackView, trackEvent } = useTealium();
    const { experience } = useExperience();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.RIA,
            page_l4: experience === Experience.EMPLOYEE
                ? TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_EE
                : TEALIUM_PAGE_VIEW.PAGE_L4.HOME_PAGE.HOME_PAGE_ER,
            page_l5: 'absence confirmation',
        });
    }, []);
    const returnToStart = () => {
        setNewAbsenceDateTimes([]);
        navigate('/ria');
        setUpdatedAbsenceDateTimes([]);
        callForAbsences();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'report more time off',
            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
            event_version: 'report absence',
        });
        navigate('/ria');
    };
    const returnToStartSingle = () => {
        setNewAbsenceDateTimes([]);
        setUpdatedAbsenceDateTimes([]);
        setCurrentLeave(undefined);
        navigate('/ria');
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'return to select leave',
            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
            event_version: 'report absence',
        });
        navigate('/ria');
    };
    const returnToLeaveDetails = () => {
        setUpdatedAbsenceDateTimes([]);
        setNewAbsenceDateTimes([]);
        navigate('/ria', { state: { skipToLeave: true } });
        callForAbsences();
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'report more time off',
            event_type: TEALIUM_EVENT.EVENT_TYPE.REPORT_INTERMITTENT_ABSENCE,
            event_version: 'report absence',
        });
        navigate('/ria', { state: { skipToLeave: true } });
    };
    let failCount = 0;
    {
        newAbsenceDateTimes &&
            newAbsenceDateTimes.forEach(absence => {
                if (!absence.success) {
                    failCount++;
                }
            });
    }
    {
        updatedAbsenceDateTimes &&
            updatedAbsenceDateTimes.forEach(absence => {
                if (!absence.success) {
                    failCount++;
                }
            });
    }
    const confirmationAlert = () => {
        const count = newAbsenceDateTimes.length + updatedAbsenceDateTimes.length;
        if (failCount > 0) {
            return (<Alert data-testid={'submission-partial-error-alert'} type={'error'} aria-labelledby='submission-partial-error-alert'>
          {t(RIA_TEXT.submitUnsuccess)}
        </Alert>);
        }
        else {
            return (<Alert data-testid={'submission-success-alert'} type={'success'} aria-labelledby='submission-success-alert'>
          {t(count > 1 ? RIA_TEXT.submitMultiSuccess : RIA_TEXT.submitSingleSuccess)}
        </Alert>);
        }
    };
    return (<div data-public={true}>
      {confirmationAlert()}

      <Heading elemType='h1' color='grayDarkest' style={{ marginBottom: '0.875rem', marginTop: '1.5rem' }}>
        {t(RIA_TEXT.confirmationHeading)}
      </Heading>
      <br />

      {failCount === 0 && riaConfig?.confirmationText && t(riaConfig?.confirmationText) && (<ImportantMessageBox>{riaConfig?.confirmationText && t(riaConfig?.confirmationText)}</ImportantMessageBox>)}

      {currentLeave && (<Row>
          <Col lg={10} md={12}>
            <ConfirmationLeaveCard leave={currentLeave} absences={newAbsenceDateTimes}/>
          </Col>
        </Row>)}
      {/* <ConfirmationLeaveCard leave={MOCK_LEAVES[0]} absences={[{absenceDate: '2022-09-20', absenceHours: '01', absenceMinutes: '05', success: true}, {absenceDate: '2021-08-06', absenceHours: '05', absenceMinutes: '15', success: false}] }/> */}
      <StickyDiv>
        <StepsDiv>
          {leaves.length === 1 && (<Button data-testid='report-more-time' buttonType='secondary' onClick={returnToStart}>
              {t(RIA_TEXT.reportMoreTimeButton)}
            </Button>)}
          {leaves.length > 1 && (<Button data-testid='report-more-time-multiple-leaves' buttonType='secondary' onClick={returnToLeaveDetails}>
              {t(RIA_TEXT.reportMoreTimeButton)}
            </Button>)}
          {leaves.length > 1 && (<Button className={'mb-3 mb-md-0'} style={{ margin: 0 }} data-testid='return-to-leave-select' buttonType='secondary' onClick={returnToStartSingle}>
              {t(RIA_TEXT.returnToSelectLeave)}
            </Button>)}
        </StepsDiv>
      </StickyDiv>
    </div>);
};
export default Confirmation;
