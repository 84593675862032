import { default as React, useState } from 'react';
import { Heading, Alert, Col, Row, themes, Spinner, Icon, Button } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import CaseManager from './CaseManager';
import { useLanguage, useExperience } from 'status/hooks';
import getProductAttributeCode from 'status/utilities/getProductAttributeCode';
import getClaimStatusDefinition from 'status/utilities/getClaimStatusDefinition';
import getDiagnosticCategory from 'status/utilities/getDiagnosticCategory';
import getDisabilityCause from 'status/utilities/getDisabilityCause';
import titleCase from 'status/utilities/titleCase';
import checkDate from 'status/utilities/checkDate';
import { Link } from 'react-router-dom';
import { encode } from 'status/utilities/hash';
import WatchlistCheckBox from '../../WatchlistCheckBox/WatchlistCheckBox';
import DocumentUpload from '../../../../../components/documentUpload/DocumentUpload';
import { Info } from '../../EmployeeDetails/EmployeeDetails';
import FileSaver from 'file-saver';
import useAuthentication from '../../../../../hooks/useAuthentication';
import axios from 'axios';
import Glossary from 'status/components/Glossary/Glossary';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import CategorizationDocumentUpload from 'src/components/documentUpload/CategorizationDocumentUpload';
import { getEnvVariable } from '../../../../../utils/envUtils';

const formatClassDescription = desc => {
  const code = desc.split('-')[0];
  let description = desc.split('-')[1];
  description = description
    .split(' ')
    .map(word => titleCase(word))
    .join(' ');
  return `${code} - ${description}`;
};

const ClaimError = ({ error }) => (
  <Alert type={'error'}>
    {error.response && error.response.status === 404 ? (
      'No claim found.'
    ) : (
      <span>
        <strong>Error!</strong> Something went wrong when loading claim details.
      </span>
    )}
  </Alert>
);

const WatchListError = () => (
  <Alert type={'error'}>
    <span>
      <strong>Error!</strong> Cannot load or modify watchlist at this moment.
    </span>
  </Alert>
);

// status-service is converting the productTypes returned from S1 in the helper-library PaidLeaveProductCodeEnum.java
// Need to convert it back here to determine Status Reason.
const getConvertedProductType = productType => {
  if (productType === 'WAPL') {
    return 'PLVA';
  }
  if (productType === 'MAPL') {
    return 'PLVB';
  }
  return productType;
};

const BasicInfo = ({
  params,
  claim,
  leaveError,
  empNum,
  associatedLeave,
  prodAttCodes,
  statusDefinitionList,
  updateStatus,
  isSmallMidCustomer,
}) => {
  const { language } = useLanguage();
  const { experience } = useExperience();
  const { t } = useTranslation();
  const { trackEvent } = useTealium();

  const productAttribute = getProductAttributeCode(
    language,
    prodAttCodes,
    'Claim',
    'Status',
    claim.clmSttusCde,
    isSmallMidCustomer,
  );
  updateStatus({ status: productAttribute });

  const statusDef = getClaimStatusDefinition(
    language,
    statusDefinitionList,
    getConvertedProductType(claim.prodType),
    claim.clmSttusCde,
    claim.clmClosureReasCde,
    claim.clmPendReasCde,
    isSmallMidCustomer,
  );

  const expPrefix = experience === 'EE' ? 'employee' : 'employer';

  if (associatedLeave) {
    associatedLeave.to = `/status/${expPrefix}/${encode(empNum)}/leaves/${encode(associatedLeave.lveNum)}/details`;
    if (params.search) {
      associatedLeave.to = `${associatedLeave.to}?search=${params.search}&searchCrit=${params.searchCrit}`;
    }
    if (params.from) {
      associatedLeave.to = `${associatedLeave.to}?from=${params.from}`;
    }
  }

  return (
    <>
      <Row style={{ marginTop: '1rem' }}>
        <Col lg={6}>
          <Info text={t('statusText')} info={productAttribute} />
          <Info text={t('coverageText')} info={claim.prodType} />
          {experience === 'ER' && claim.classCde && <Info text={t('benefitClassText')} info={claim.classCde} restricted={true} />}
          {experience === 'ER' && claim.prodType && claim.prodType.trim() === 'PLNY' && (
            <Info
              text={t('classDescriptionText')}
              info={claim.clsDescription && formatClassDescription(claim.clsDescription)}
            />
          )}
          {claim.clmCloseDte && <Info text={t('closedDateText')} info={checkDate(claim.clmCloseDte)} />}
        </Col>
        <Col lg={6}>
          <Info text={t('disabilityCauseText')} info={getDisabilityCause(claim.clmDisabCause)} />
          {claim.claimDiagnosticCategory && (
            <Info text={t('diagnosticCategoryText')} info={getDiagnosticCategory(claim.claimDiagnosticCategory)} />
          )}
          <Info
            text={t('associatedLeaveText')}
            info={
              associatedLeave ? (
                <Link
                  to={associatedLeave.to}
                  onClick={() =>
                    trackEvent({
                      event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                      event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                      event_name: associatedLeave.lveNum,
                      event_results: 'leave',
                      event_version: 'details',
                    })
                  }>
                  {associatedLeave.lveNum}
                </Link>
              ) : null
            }
          />
        </Col>
        <Col lg={12}>
          <Info text={t('statusReasonText')} info={statusDef} />
        </Col>
      </Row>
    </>
  );
};

const ClaimInfo = ({
  claim,
  employeeName,
  params,
  leaveData,
  leaveError,
  empNum,
  error,
  watchlistError,
  prodAttCodes,
  statusDefinitionList,
  claimId,
  updateStatusCallback,
  isSmallMidCustomer,
}) => {
  const { t } = useTranslation();
  const { experience } = useExperience();
  const { authHeader, user } = useAuthentication();
  const { trackEvent } = useTealium();
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  if (error) {
    return <ClaimError error={error} />;
  }

  let associatedLeave;

  if (leaveData) {
    // We need to do shitty stuff and find the matching leave gross but we have to
    const leaves = [...leaveData._embedded['leave-claims'], ...leaveData._embedded.leaves];
    associatedLeave = leaves.filter(elem => elem.coordClmNum === claim.clmNum)[0];
  }
  const updateStatus = status => {
    if (updateStatusCallback) {
      updateStatusCallback(status);
    }
  };

  const downloadClaimDetails = async () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
      event_name: 'download claim details',
      event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
      event_version: 'details'
    });
    try {
      setIsDownloading(true);
      const response = await axios.get(`/status-service/report/${empNum}/${claimId}/claimSummary`, {
        responseType: 'blob',
        headers: { Authorization: authHeader() },
      });
      const reportName = `${claimId}-ClaimDetails.pdf`;
      const blob = new Blob([response.data]);
      setIsDownloadError(false);
      setIsDownloading(false);
      FileSaver.saveAs(blob, reportName);
    } catch (error) {
      setIsDownloadError(true);
      setIsDownloading(false);
    }
  };

  const DownloadClaimSummaryError = () => (
    <Alert
      data-testid={'download-claim-details-alert'}
      type={'error'}
      closeable={true}
      onClose={() => setIsDownloadError(false)}>
      <span>
        <strong>Error!</strong> There was a problem downloading the claim details. Please try again later.
      </span>
    </Alert>
  );

  return (
    <>
      {watchlistError && <WatchListError />}
      <Row data-public={true} style={{ marginBottom: '1rem', marginTop: '1.5rem' }}>
        <Col md={8} xs={12}>
          <div style={{ display: 'inline-block' }}>
            <Heading elemType={'h2'} style={{ marginRight: '1rem' }} color={'primary'}>
              {`${t('claimDetailsText')} #${claim.clmNum}`}
            </Heading>
          </div>
          {experience === 'ER' && (
            <div style={{ display: 'inline-block' }}>
              <WatchlistCheckBox eventId={claimId} eventType={'CLAIM'} />
            </div>
          )}
          <BasicInfo
            claim={claim}
            empNum={empNum}
            params={params}
            leaveError={leaveError}
            associatedLeave={associatedLeave}
            statusDefinitionList={statusDefinitionList}
            prodAttCodes={prodAttCodes}
            updateStatus={updateStatus}
            isSmallMidCustomer={isSmallMidCustomer}
          />

          {(getEnvVariable('enableCategorizationForS1') && experience === 'EE') || user?.customerInContext?.smallMid ? (
            <Row >
              <Col xl={12} xs={12} style={{ marginBottom: '1rem'}} >
                <CategorizationDocumentUpload 
                  eventNum={claim.clmNum}
                  productName={claim.prodType}
                  displayLabel={true}
                  renderAsLink={false}
                  key={'CategorizationDocumentUpload' + claim.clmNum}
                  data-testid="CategorizationDocumentUpload"
                  page = 'details'
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xl={6} xs={12} style={{ marginBottom: '1rem' }}>
                <DocumentUpload
                  eventNum={claim.clmNum}
                  productName={claim.prodType}
                  displayLabel={true}
                  theme={themes.lincoln}
                />
              </Col>
              {experience === 'ER' && (
                <Col xl={6} xs={12} style={{ marginBottom: '1rem' }}>
                  <Button
                    data-testid={'downloadClaimDetails'}
                    buttonType={'secondary'}
                    size={'medium'}
                    type={'button'}
                    onClick={downloadClaimDetails}
                    disabled={isDownloading}
                    aria-label='Download Claim Details'
                    style={{ textTransform: 'inherit' }}>
                    {isDownloading ? (
                      <Spinner
                        id='claim-info-spinner'
                        size='small'
                        style={{ float: 'left', margin: '0px', marginTop: '-.25rem', marginRight: '.5rem' }}
                      />
                    ) : (
                      <Icon name='download' color={'secondary'} style={{ paddingRight: '.25rem' }} />
                    )}
                    Download Claim Details
                  </Button>
                </Col>
              )}
            </Row>
          )}
          {isDownloadError && <DownloadClaimSummaryError />}
        </Col>
        <Col md={4} xs={12}>
          <CaseManager
            caseManager={claim.caseManager}
            claimNum={claim.clmNum}
            offNum={claim.clmOffNum}
            employeeName={employeeName}
          />
          <Glossary />
        </Col>
      </Row>
    </>
  );
};

export default ClaimInfo;
