import React, { useEffect } from 'react';
import { Modal, Button, Heading, Row, Col, Box, themes } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import { RabConfirmation } from '../components/RabReview';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
const CloseButton = ({ hideModal }) => {
    const { t: translation } = useTranslation('rabTranslation');
    const { trackEvent } = useTealium();
    const handleOnClick = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.CLOSE_RAB_VIEW_SUBMISSION,
            event_name: 'Close Rab View Submission'
        });
        hideModal();
    };
    return (<Button buttonType={'secondary'} type={'button'} onClick={handleOnClick} data-testid={'closeButton'} aria-label={translation('closeText').toString()}>
      {translation('closeText').toString()}
    </Button>);
};
const PrintButton = ({ hideModal }) => {
    const { t: translation } = useTranslation('rabTranslation');
    return (<Button buttonType={'primary'} type={'button'} onClick={print} data-testid={'print'} aria-label={`${translation('printText')}`}>
      {`${translation('printText')}`}
    </Button>);
};
const ViewConfirmationButton = ({ showModal }) => {
    const { t: translation } = useTranslation('rabTranslation');
    const { trackView, trackEvent } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.RAB,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.RAB.CONFIRMATION_MODAL,
        });
    }, []);
    const handleOnClick = async () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_RAB_SUBMISSION,
            event_name: 'View Rab Submission'
        });
        showModal();
    };
    return (<Button buttonType={'primary'} type={'button'} onClick={handleOnClick} data-testid='viewConfirmationButton' aria-label={`${translation('confirmationViewPrintText')}`}>
      {`${translation('confirmationViewPrintText').toString()}`}
    </Button>);
};
const RabViewConfirmationModal = (props) => {
    const { t: translation } = useTranslation('rabTranslation');
    const { trackEvent } = useTealium();
    const print = () => () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.PRINT,
            event_name: 'Print Rab Submission'
        });
        window.print();
    };
    const headerComponent = () => {
        return (<Heading elemType={'h1'} theme={themes.lincoln}>
        {translation('viewSubmissionHeaderText').toString()}
      </Heading>);
    };
    return (<Modal theme={themes.lincoln} style={{ margin: '2rem', overflowX: 'scroll' }} headerComponent={headerComponent()} modalOpenRenderButton={prop => <ViewConfirmationButton {...prop}/>} footerPrimaryRenderButton={PrintButton} footerSecondaryRenderButton={CloseButton} size={'large'} disabledBackdropClick={true}>

      <Heading elemType={'h2'} theme={themes.lincoln} color={'primary'} data-testid='viewConfirmation'>
        <Row style={{ marginBottom: '5px' }}>
          <Col sm={6} md={6} lg={6}>
            {translation('reportABirthHeaderText').toString()}
          </Col>
        </Row>
      </Heading>
      <Box>
        <Row>
          <Col xs={12} sm={12}>
            <RabConfirmation review={false} claimNumber={props.claimNumber} report={props.report}/>
          </Col>
        </Row>
      </Box>
    </Modal>);
};
export default RabViewConfirmationModal;
