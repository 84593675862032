import React from 'react';
import { Radio, RadioGroup, themes, Row, Col, Heading, TextInput, InputMessage, Button } from '@digitools/honest-react';
import { Scope } from 'informed';
import { useState } from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import { fieldRequired, ssnValidation, empIdValidation } from '../../../eoi/utils/FormFieldValidations';
import { CliVerificationText } from '../../constants/CliVerificationText';
import useCliCustomerConfigContext from '../../hooks/useCliContext';
import useCliDataContext from '../../hooks/useCliDataContext';
import useAuthentication from 'src/hooks/useAuthentication';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import { PersonalInformationText } from '../../constants/PersonalInformationText';
const IdentityVerificationForm = ({ formState }) => {
    const { experience } = useExperience();
    const { t } = useTranslate();
    const [notFoundError, setNotFoundError] = useState(false);
    const { user } = useAuthentication();
    const { claimantData } = useCliDataContext();
    const cliConfigContext = useCliCustomerConfigContext();
    const { empIdText, empId, lastName } = useCliCustomerConfigContext();
    let radioSelected = 'ssn';
    const initialEmpIDValue = () => {
        if (cliConfigContext?.autoPrepop) {
            if (user?.ssoCargo?.employeeId) {
                return user?.ssoCargo?.employeeId;
            }
            return claimantData?.empID;
        }
    };
    if (initialEmpIDValue())
        radioSelected = 'empID';
    const empIdValue = experience === Experience.EMPLOYEE ? initialEmpIDValue() : empId;
    const verificationHeadingLabel = CustomEmployeeText(experience === Experience.EMPLOYEE ?
        t(CliVerificationText.verificationHeadingEE) :
        `${t(CliVerificationText.verificationHeadingER)}`);
    return (
    // @ts-ignore
    <Scope scope='claimantData'>
            <div style={{ borderLeft: `3px solid ${themes.lincoln.palette.primary}`, paddingLeft: '1rem' }}>
                <Row>
                    <Col md={12} data-public={true}>
                        <Heading data-testid='cli-verification-heading' elemType={'h3'} elemStyle={'h3'} className={`mb-0`}>
                            {verificationHeadingLabel}
                        </Heading>
                    </Col>
                </Row>

                {(cliConfigContext && cliConfigContext.lookupByEmpId) && (cliConfigContext && cliConfigContext.lookupBySsn) && (<Row className={`mt-0 mb-4`} data-public={true} data-testid='subHeading'>
                        <Col md={12}>
                            <strong>{t(CliVerificationText.verificationSubHeading)}</strong>
                        </Col>
                    </Row>)}

                <Row>
                    {((cliConfigContext && cliConfigContext.lookupBySsn) && (cliConfigContext && cliConfigContext.lookupByEmpId)) && (<Col md={'auto'} data-testid='verification-radio-group' data-public={true}>
                            <RadioGroup label={t(CliVerificationText.verifyRadioButtonLegend)} hideLabel={true} field={'verificationType'} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={radioSelected} data-public={true}>
                                <Radio containerStyle={{ marginBottom: '.5rem' }} data-private={true} data-testid='radio-ssn' id='showSsn' label={t(CliVerificationText.ssnLabel)} name={'verificationType'} theme={themes.lincoln} value={'ssn'}/>
                                <Radio containerStyle={{ marginBottom: '.5rem' }} data-public={true} data-testid='radio-empID' id='showEmpID' label={empIdText && t(empIdText)} name={'verificationType'} theme={themes.lincoln} value={'empID'}/>
                            </RadioGroup>
                        </Col>)}
                    {((cliConfigContext && (cliConfigContext.lookupBySsn && !cliConfigContext.lookupByEmpId)) || formState?.values?.claimantData?.['verificationType'] === 'ssn') && (<Col lg={3} className={'mt-1 mb-3 mb-lg-0'}>
                            <TextInput data-testid='verification-ssn-field' field={'ssn'} label={t(CliVerificationText.ssnLabel)} theme={themes.lincoln} validate={(ssn) => {
                setNotFoundError(false);
                return ssnValidation(ssn);
            }} autoComplete="off" type={'textmask'} ariaLabelText={'ssn'} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} initialValue={claimantData && claimantData.ssn} skinny={true} data-private={true} 
        // @ts-ignore not existing in type - should function though
        autocomplete='off'/>
                        </Col>)}

                    {((cliConfigContext && (!cliConfigContext.lookupBySsn && cliConfigContext.lookupByEmpId)) || formState?.values?.claimantData?.['verificationType'] === 'empID') && (<Col lg={3} className={'mt-1 mb-3 mb-lg-0'}>
                            <TextInput data-testid='verification-empID-field' field={'empID'} label={empIdText && t(empIdText)} theme={themes.lincoln} type={'text'} ariaLabelText={'empId'} validate={(empId) => {
                setNotFoundError(false);
                return empIdValidation(empId);
            }} validateOnBlur={true} validateOnChange={true} iconColor={'secondaryDark'} optional={false} initialValue={empIdValue} skinny={true}/>
                        </Col>)}

                    {experience === Experience.EMPLOYER &&
            <Col lg={3} className={'mt-1 mb-3 mb-lg-0'}>
                            <TextInput data-testid="verification-last-name-field" data-private={true} field={'lastName'} label={t(PersonalInformationText.lastName)} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} ariaLabelText={t(PersonalInformationText.lastName)} skinny={true} initialValue={lastName}/>
                        </Col>}
                    <Col style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto' }} lg={3}>
                        <Button style={{ width: '100%' }} data-testid='verification-submit-button' buttonType={'primary'} theme={themes.lincoln} type='submit'>
                            {t(CliVerificationText.verifyButton)}
                        </Button>
                    </Col>

                    <InputMessage data-testid='not-found-error' error={notFoundError} style={{ marginTop: '-1rem' }} data-public={true}>
                        {t(CliVerificationText.verificationNotFound)}
                    </InputMessage>

                </Row>
            </div>
            <hr />
        </Scope>);
};
export default IdentityVerificationForm;
