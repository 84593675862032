import React, { useContext, useEffect, useState } from 'react';
import Radio from '@digitools/honest-react/dist/lib/components/Radio';
import RadioGroup from '@digitools/honest-react/dist/lib/components/Radio/RadioGroup';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import DateInput from '@digitools/honest-react/dist/lib/components/DateInput';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import { Scope, useFormState } from 'informed';
import styled from 'styled-components';
import { VerificationTypeCode } from '../../../providers/EoiProvider';
import { dateMask, currencyMask, occupationMask, employeeIdMask } from '../../../utils/InputMasks';
import useTranslate from '../../../../../hooks/useTranslate';
import { EmploymentInformationText } from '../../../i18n/ApplicantInformationText';
import { customize, tomorrowDateString } from '../../../utils/HelperFunctions';
import { EoiGeneralText } from '../../../i18n/EoiText';
import { ExperienceContext } from '../../../../../providers/ExperienceProvider';
import { Language } from '../../../../../types/Language';
import { fieldRequired, selectInputErrorMessage, validateSalary } from '../../../utils/FormFieldValidations';
import { format } from 'date-fns';
import useEoiContext from '../../../hooks/useEoiContext';
const RadioContainer = styled('div') `
  display: flex;
  flex-direction: row;
  @media(max-width: 500px){
    flex-direction: column;
  }
  > label {
    margin-right: 1rem;
  }
`;
const displayLocationIndicator = (initialInformation) => {
    return initialInformation
        && initialInformation.employerConfiguration
        && initialInformation.employerConfiguration.preference
        && initialInformation.employerConfiguration.preference.displayLocationIndicator;
};
const officeLocations = (initialInformation) => {
    // TODO: made an assumption that we want locationId here to send back to rtmus but might need to be locationCode or something else.
    // will revisit when we talk about the payload to send to eoi-service.
    return initialInformation.employerConfiguration.locations.map(location => {
        return ({
            label: location.locationName,
            value: location.locationCode,
        });
    });
};
const EmploymentInformation = (props) => {
    const { initialInformation, customContent } = useEoiContext();
    const { t } = useTranslate();
    const { language } = useContext(ExperienceContext);
    const [dateOfHire, setDateOfHire] = useState();
    const formState = useFormState();
    useEffect(() => {
        // @ts-ignore
        if (formState && formState.values && formState.values.employee && formState.values.employee.employmentInformation && formState.values.employee.employmentInformation.dateOfHire) {
            // @ts-ignore
            if (dateOfHire !== formState.values.employee.employmentInformation.dateOfHire) {
                // @ts-ignore
                setDateOfHire(formState.values.employee.employmentInformation.dateOfHire);
            }
        }
    }, [formState]);
    // get prepop values from interviewAnswersApplicant
    let employmentInformation = { disableEmployeeId: false, disableDateOfHire: false, disableOccupation: false };
    if (props.interviewAnswersApplicant.employmentInformation) {
        employmentInformation = props.interviewAnswersApplicant.employmentInformation;
    }
    return (customContent && initialInformation ?
        // @ts-ignore
        <Scope scope={'employmentInformation'}>
        <div style={{ marginBottom: '1rem' }} data-testid={'employment-information'} data-public={true}>
          <div style={{ marginBottom: '1rem' }} data-testid={'active-employee-div'}>
            <RadioGroup label={t(customize(EmploymentInformationText.activeEmployee, customContent, initialInformation, true))} field={'activeEmployee'} validateOnBlur={true} validateOnChange={true} theme={themes.lincoln} validate={fieldRequired} initialValue={employmentInformation.activeEmployee}>
              <RadioContainer>
                <Radio name='activeEmployee' theme={themes.lincoln} label={t(EoiGeneralText.yes)} value={'yes'} data-testid={'active-employee-radio-yes'}/>
                <Radio name='activeEmployee' theme={themes.lincoln} label={t(EoiGeneralText.no)} value={'no'} data-testid={'active-employee-radio-no'}/>
              </RadioContainer>
            </RadioGroup>
          </div>
          <div style={{ marginBottom: '1rem' }} data-testid={'employment-type-div'}>
            <RadioGroup label={t(customize(EmploymentInformationText.employmentType, customContent, initialInformation, true))} field={'employmentType'} validateOnBlur={true} validateOnChange={true} theme={themes.lincoln} validate={fieldRequired} initialValue={employmentInformation.employmentType}>
              <RadioContainer>
                <Radio name='employmentType' theme={themes.lincoln} label={t(EmploymentInformationText.fullTime)} value={'fullTime'} data-testid={'employment-type-radio-yes'}/>
                <Radio name='employmentType' theme={themes.lincoln} label={t(EmploymentInformationText.partTime)} value={'partTime'} data-testid={'employment-type-radio-no'}/>
              </RadioContainer>
            </RadioGroup>
          </div>
          <Row data-testid={'annual-salary-row'}>
            <Col md={6} lg={6} xl={6}>
              <Label theme={themes.lincoln} style={{ marginBottom: '0.5rem' }} data-testid={'annual-salary-label'}>{t(EmploymentInformationText.whatIsYourAnnualSalary)}</Label>
              <TextInput theme={themes.lincoln} label={t(EmploymentInformationText.salary)} placeholder={t(EmploymentInformationText.enterSalary)} field={'salary'} mask={currencyMask} data-testid={'annual-salary-input'} validateOnBlur={true} validateOnChange={true} validate={validateSalary} initialValue={employmentInformation.salary}/>            
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }} data-testid={'employment-info-additional'}>
            <Col sm={12}>
              <Label theme={themes.lincoln} style={{ marginBottom: '0.5rem' }} data-testid={'please-provide-label'}>
                {t(EmploymentInformationText.pleaseProvideTheFollowing)}
              </Label>
            </Col>
            <Col md={6} sm={12}>
              <TextInput theme={themes.lincoln} label={language === Language.ENGLISH ? t(customize(EmploymentInformationText.occupation, customContent, initialInformation)) : t(customize(EmploymentInformationText.occupation, customContent, initialInformation, true))} placeholder={language === Language.ENGLISH ? t(customize(EmploymentInformationText.occupation, customContent, initialInformation)) : t(customize(EmploymentInformationText.occupation, customContent, initialInformation, true))} field={'occupation'} data-testid={'occupation-input'} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} mask={occupationMask} initialValue={employmentInformation.occupation} disabled={employmentInformation.disableOccupation}/>
            </Col>
            <Col md={6} sm={12}>
              <TextInput theme={themes.lincoln} label={t(customContent.employeeIdText)} placeholder={t(customContent.employeeIdText)} field={'employeeId'} data-testid={'employee-id-input'} validateOnBlur={true} validateOnChange={true} validate={initialInformation.employerConfiguration.preference.applicantIdentificationMethodCode === VerificationTypeCode.SSN ? undefined : fieldRequired} // only validate this (aka make required) when the verification method is either employee id or both.
         mask={employeeIdMask} initialValue={employmentInformation.employeeId} disabled={employmentInformation.disableEmployeeId}/>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={language === Language.ENGLISH ? 3 : 6} data-testid={'hire-date-div'}>
              <DateInput between={['01/01/1900', tomorrowDateString()]} theme={themes.lincoln} field={'dateOfHire'} label={t(EmploymentInformationText.dateOfHire)} mask={dateMask} lang={language} data-testid={'date-of-hire-input'} initialValue={employmentInformation.dateOfHire ? format(new Date(employmentInformation.dateOfHire), 'P') : undefined} disabled={employmentInformation.disableDateOfHire}/>
            </Col>
            <Col md={6} lg={language === Language.ENGLISH ? 3 : 6} data-testid={'rehire-date-div'}>
              <DateInput between={dateOfHire ? [dateOfHire, tomorrowDateString()] : undefined} theme={themes.lincoln} field={'dateOfReHire'} label={t(EmploymentInformationText.dateOfRehire)} mask={dateMask} 
        // @ts-ignore
        optional={!(formState && formState.employee && formState.employee.employmentInformation && formState.employee.employmentInformation.dateOfRehire)} lang={language} validateOnBlur={true} validateOnChange={true} initialValue={employmentInformation.dateOfReHire} data-testid={'date-of-rehire-input'}/>
            </Col>
            {displayLocationIndicator(initialInformation) &&
                <Col md={6} lg={6} data-testid={'office-location-div'}>
              <SelectInput field={'officeLocation'} label={t(EmploymentInformationText.officeLocation)} options={officeLocations(initialInformation)} theme={themes.lincoln} placeholderText={t(EmploymentInformationText.officeLocation)} data-testid={'office-location-input'} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={employmentInformation.officeLocation} errorMessage={selectInputErrorMessage}/>
            </Col>}
          </Row>
          </div>
      </Scope>
        : <></>);
};
export default EmploymentInformation;
