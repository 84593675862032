import React from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import useExperience from '../../../../hooks/useExperience';
import { GraySpacerDiv } from '../../../../components/Formatting/StyledComponents';
import { numberOnlyMask, dateMask } from '../../utils/InputMasks';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import DateInput from '@digitools/honest-react/dist/lib/components/DateInput';
import RadioGroup from '@digitools/honest-react/dist/lib/components/Radio/RadioGroup';
import Radio from '@digitools/honest-react/dist/lib/components/Radio';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { fieldRequired } from '../../utils/FormFieldValidations';
import Checkbox from '@digitools/honest-react/dist/lib/components/Checkbox';
import { CheckboxGroup } from '@digitools/honest-react';
import { QuestionsText } from '../../i18n/QuestionsText';
const validateRadio = (value) => {
    return (value === undefined ? 'Required' : undefined);
};
// TODO: FIXME: SOME OF THESE QUESTIONS ARE OPTIONAL??? HOW AM I SUPPOSED TO KNOW
// example = date of recovery (if applicable) - can't see anything on the payload that indicates if this is optional or not
const AdditionalQuestion = ({ additionalQuestion, applicant, matchingResponse, }) => {
    const { t } = useTranslate();
    const { language } = useExperience();
    // TODO: translate all the static text here (and on Qualifying Questions table too now that i think of it)
    // TODO: add validation functions to all these bad bois
    let field;
    switch (additionalQuestion.responseFormatTypeCode) {
        case 'I':
            field = (<RadioGroup label='Yes or No' hideLabel={true} field={'value'} validate={fieldRequired} validateOnChange={true} validateOnBlur={true} initialValue={matchingResponse ? matchingResponse.value[0] : undefined}>
          <Row>
            <Radio name={additionalQuestion.questionCode} label={'Yes'} value={"true"} theme={themes.lincoln} data-testid={additionalQuestion.questionCode + 'yes'} containerStyle={{ marginRight: '1rem' }}/>
            <Radio name={additionalQuestion.questionCode} label={'No'} value={"false"} theme={themes.lincoln} data-testid={additionalQuestion.questionCode + 'no'}/>
          </Row>
        </RadioGroup>);
            break;
        case 'SCD':
            const options = additionalQuestion.responseOptions.map((responseOption) => {
                return ({
                    label: t(responseOption.text),
                    value: responseOption.responseCode
                });
            });
            field = (<SelectInput label={'Select one'} placeholderText={'Select one'} field={'value'} options={options} validate={fieldRequired} validateOnBlur={true} validateOnChange={true} initialValue={matchingResponse ? matchingResponse.value[0] : undefined} theme={themes.lincoln}/>);
            break;
        case 'MCD':
            const multipleOptions = additionalQuestion.responseOptions.map((responseOption) => {
                return ({
                    label: t(responseOption.text),
                    value: responseOption.responseCode
                });
            });
            field = (<CheckboxGroup validate={(values) => !values || values.length < 1 ? t(QuestionsText.reflectiveQuestionValidationError) : undefined} validateOnChange={true} validateOnBlur={true} 
            //@ts-ignore //FIXME: type error with honest's initialValue type
            initialValue={matchingResponse}>
          {multipleOptions.map((option, i) => {
                    const initialValue = matchingResponse ? matchingResponse.value.indexOf(option.value.toString()) > -1 : undefined;
                    return <Checkbox theme={themes.lincoln} field={`${applicant}-${option.value}`} label={option.label} key={i} initialValue={initialValue}/>;
                })}
        </CheckboxGroup>);
            break;
        case 'NUM':
            field = (<TextInput label={''} field={'value'} mask={numberOnlyMask} validate={fieldRequired} validateOnBlur={true} validateOnChange={true} initialValue={matchingResponse ? matchingResponse.value[0] : undefined} theme={themes.lincoln}/>);
            break;
        case 'DTE':
            field = (<DateInput field={'value'} lang={language} label={'Enter date'} optional={additionalQuestion.questionCode === '000309'} validate={fieldRequired} validateOnBlur={true} validateOnChange={true} mask={dateMask} initialValue={matchingResponse ? matchingResponse.value[0] : undefined} theme={themes.lincoln}/>);
            break;
        default:
            field = `SOMETHING NEW!!! NOT IMPLEMENTED "${additionalQuestion.questionCode}"`;
            break;
    }
    return (<>
      <div style={{ display: 'none' }}>
        <TextInput hidden={true} hideLabel={true} label={''} field={'questionCode'} initialValue={additionalQuestion.questionCode}/>
      </div>
      <Row style={{ marginBottom: '1rem', }}>
        <Col>{t(additionalQuestion.text)}</Col><Col>{field}</Col>
      </Row>
      <GraySpacerDiv style={{ height: '1rem', marginBottom: '1rem' }}/>
    </>);
};
export default AdditionalQuestion;
