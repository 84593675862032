const leaveStatus = data => {
  const code = data && data.status && data.status.trim();
  const trimmedAbsReqTimeCode =
    data && data._original && data._original.requestTimeCode && data._original.requestTimeCode.trim();

  const mappings = [
    { code: 'AP', leaveStatus: 'Applied' },
    { code: 'NA', leaveStatus: 'Not Applied' },
    { code: 'NE', leaveStatus: 'Not Applied' },
  ];

  var foundStatus = mappings.find(element => element.code === code);
  if (foundStatus) return foundStatus.leaveStatus;
  else if (trimmedAbsReqTimeCode === 'NA') {
    return 'Not Applied';
  }

  return '';
};

export default leaveStatus;
