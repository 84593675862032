import { Container, Heading, Icon, themes } from '@digitools/honest-react';
import React from 'react';
import Media from 'react-media';
import { getEnvVariable } from 'src/utils/envUtils';
import useCustomerConfig from '../../hooks/useCustomerConfig';
import useTranslate from '../../hooks/useTranslate';
import NavLink from '../NavMenuBar/NavMenu/NavLink';
import { TEALIUM_EVENT } from '../../constants/tealiumConstants';
import useTealium from '../../hooks/useTealium';
const NavItemFooterL3Style = {
    display: 'block',
    color: 'white',
    font: 'Roboto-Condensed',
    fontSize: '1rem',
    marginBottom: '.25rem',
};
const NavItemFooterL2Style = {
    display: 'block',
    color: 'white',
    font: 'Roboto-Condensed',
    fontWeight: 500,
    fontSize: '1rem',
    marginTop: 0,
    marginBottom: '.25rem',
};
const FooterNavigation = () => {
    const { navigationMenu } = useCustomerConfig();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    const getNavigationUrl = (navLink) => {
        let url = '';
        if (!navLink.cnvgSso && !navLink.lamsSSO && !navLink.l4bSso) {
            url = navLink.url;
            return url;
        }
        else {
            return url;
        }
    };
    const trackFooterEvent = (label) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: label,
            event_type: TEALIUM_EVENT.EVENT_TYPE.HOME_PAGE,
            event_version: TEALIUM_EVENT.EVENT_TYPE.FOOTER,
        });
    };
    const newOnClickFn = (navLink) => {
        let ssoGroup = '';
        if (navLink.cnvgSso) {
            ssoGroup = 'cnvg';
        }
        else if (navLink.lamsSSO) {
            ssoGroup = 'lams';
        }
        else if (navLink.l4bSso) {
            ssoGroup = 'l4b';
        }
        if (navLink.cnvgSso || navLink.lamsSSO || navLink.l4bSso) {
            window.open(getEnvVariable('mlpHomeUrl') + '/customer/ssologin/' + ssoGroup);
        }
    };
    return (<Media query='(max-width: 768px)'>
      {matches => (matches ? null :
            <div data-testid='footer-nav' style={{ display: 'flex', width: '100%', color: 'white', backgroundColor: themes.lincoln.palette.grayDark }}>
          <Container style={{ display: 'flex', justifyContent: 'space-between', padding: '2rem', paddingLeft: '0', paddingRight: '0' }} theme={themes.lincoln}>
            {navigationMenu && navigationMenu.map((navMenu, index) => <div id='categoryWrapper' style={{ display: 'inline-block', margin: '1rem' }} key={`NavMenu-${index}`}>
                <Heading theme={themes.lincoln} elemType='h4' elemStyle='h4' color='secondaryLighter' style={{ margin: 0, textTransform: 'uppercase', lineHeight: '2rem' }}>
                  {t(navMenu.navLabel)}
                </Heading>
                {navMenu.navLink &&
                        <NavLink data-testid={'navlink'} data-public={true} style={NavItemFooterL2Style} cnvgSso={navMenu.navLink.cnvgSso} lamsSSO={navMenu.navLink.lamsSSO} l4bSso={navMenu.navLink.l4bSso} externalLink={navMenu.navLink.externalLink} url={getNavigationUrl(navMenu.navLink)} onClick={() => {
                                newOnClickFn(navMenu.navLink ? navMenu.navLink : {});
                                trackFooterEvent(navMenu.navLabel.english);
                            }} angular={navMenu.navLink.angular} label={navMenu.navLabel}>
                    <Icon size='xs' style={{ marginLeft: '.5rem' }} color='white' name='chevron-right-solid'/>
                  </NavLink>}
                {navMenu.navGroups && navMenu.navGroups.map((navGroup, index) => <React.Fragment key={`navMenu-${index}`}>
                    {navGroup.groupLabel &&
                            <Heading theme={themes.lincoln} elemType='h5' elemStyle='h4' color='white' style={{ margin: 0, fontSize: '1.125rem', fontWeight: 500 }}>
                        {t(navGroup.groupLabel)}
                      </Heading>}
                    <div style={{ marginTop: '.5rem', marginBottom: '1rem' }} id='groupWrapper'>
                      {navGroup.navLinks.map((navLink, index) => <NavLink data-testid={'navlink'} data-public={true} style={NavItemFooterL3Style} angular={navLink.angular} cnvgSso={navLink.cnvgSso} lamsSSO={navLink.lamsSSO} l4bSso={navLink.l4bSso} externalLink={navLink.externalLink} url={getNavigationUrl(navLink)} onClick={() => {
                                newOnClickFn(navLink);
                                trackFooterEvent(navLink.label.english);
                            }} key={`navMenu-${index}`} label={navLink.label}>
                          <Icon size='xs' style={{ marginLeft: '.5rem' }} color='white' name='chevron-right-solid'/>
                        </NavLink>)}
                    </div>
                  </React.Fragment>)}
              </div>)}
          </Container>
        </div>)}
    </Media>);
};
export default FooterNavigation;
