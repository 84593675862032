import { TabContainer, TabSection } from '@digitools/honest-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../../../../hooks/useAuthentication';
import NotesTable from '../NotesTable/NotesTable';
import AbsenceDetails from '../LeaveDetails/AbsenceDetails';
import Correspondence from '../LeaveDetails/Correspondence';
import LeaveBalance from '../LeaveDetails/LeaveBalance';
import LeaveOverview from '../LeaveDetails/LeaveOverview';
import RtwDetails from '../ClaimDetails/RtwDetails';
import { RtwContext } from '../../../context';
import useGet from '../../../../../hooks/useGet';

// Helper funcition to determine if we can show correspondence
const showCorrespondance = (exp, userMeta) => {
  const applicationPermissions = userMeta.applicationPermissions;
  if (exp === 'ER') {
    return applicationPermissions.indexOf('statusER.claimLeaveStatus.viewCorrespondence') > -1;
  } else {
    return applicationPermissions.indexOf('statusEE.claimLeaveStatus.viewCorrespondence') > -1;
  }
};

const displayRtw = (exp, userMeta) => {
  const applicationPermissions = userMeta.applicationPermissions;
  if (exp === 'ER') {
    return applicationPermissions.includes('statusER.claimLeaveStatus.displayRtw');
  } else {
    return applicationPermissions.includes('statusEE.claimLeaveStatus.displayRtw');
  }
};

// HElper function to determine visibility of RTW Tab
const showRTW = (experience, leaveData, user) =>
  user?.customerInContext?.smallMid || !displayRtw(experience, user)
    ? false
    : leaveData.coordClmNum === '0' &&
      (leaveData.lveSttusCde === 'PE' || leaveData.lveSttusCde === 'IP' || leaveData.lveSttusCde === 'AP') &&
      leaveData.lveCatg === 'C';

const hasViewLeaveNotesPermission = (exp, userMeta) =>
  exp === 'ER' && userMeta.applicationPermissions.includes('statusER.claimLeaveStatus.viewLeaveNotes');

const LeaveTabs = ({ leaveId, leaveData, employeeData, leaveDetailsData, error, userMeta, experience, basicInfo }) => {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const { rtwNavigation } = useContext(RtwContext);
  let employeeInfo = { firstName: employeeData.empFirstName, lastName: employeeData.empLastName };
  if (error) {
    return null;
  }

  const showCorrespondanceTab = showCorrespondance(experience, userMeta);
  const showRTWTab = showRTW(experience, leaveData, userMeta);
  const tabs = [
    <TabSection name={t('leaveOverviewText')} key={1}>
      <LeaveOverview leave={leaveData} leaveDetails={leaveDetailsData && leaveDetailsData.content[0]} />
    </TabSection>,
    <TabSection name={t('leaveBalanceText')} key={2}>
      <LeaveBalance leave={leaveData} leaveId={leaveId} />
    </TabSection>,
  ];
  //RTW details
  if (showRTWTab) {
    tabs.push(
      <TabSection name={t('tab')} key={3}>
        <RtwDetails RtwProps={leaveData} product='leave' employeeInfo={employeeInfo} />
      </TabSection>,
    );
  }
  //End of RTW tab

  if (showCorrespondanceTab) {
    tabs.push(
      <TabSection name={t('correspondenceText')} key={4}>
        <Correspondence label={t('correspondenceText')} leaveNum={leaveId} />
      </TabSection>,
    );
  }

  // TODO: Update showNotesTab to actually check something
  const showNotesTab = hasViewLeaveNotesPermission(experience, userMeta);

  if (showNotesTab) {
    const {
      data: notesData,
      error: notesError,
      loading: notesLoading,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useGet({
      url: '/status-service/notes/leave',
      options: {
        params: { leaveNum: leaveId },
      },
    });
    const claimNoteBasicDetails = {
      clmLveNumber: leaveId,
      determinationDate: leaveData.lveDtrmDte,
      status: basicInfo.status,
      employeeName: basicInfo.employeeFullName,
      location: basicInfo.location,
      subsidiary: basicInfo.subsidiary,
      userName: userMeta.firstName,
      reportType: 'Leave',
    };

    tabs.push(
      <TabSection name={t('leaveNotesText')} key={5}>
        <NotesTable
          noteBasicDetails={claimNoteBasicDetails}
          notes={notesData}
          error={notesError}
          loading={notesLoading}
        />
      </TabSection>,
    );
  }

  tabs.push(
    <TabSection name={t('absenceDetails')}>
      <AbsenceDetails
        leave={leaveData}
        leaveDetails={leaveDetailsData && leaveDetailsData.content[0]}
        employee={employeeData}
      />
    </TabSection>,
  );

  return (
    <TabContainer initialActiveTab={rtwNavigation ? t('tab') : ''}>
      {tabs.map(tab => {
        return tab;
      })}
    </TabContainer>
  );
};

export default LeaveTabs;
