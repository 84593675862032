import React from 'react';
import useGet from '../../../../../hooks/useGet';
import { useTranslation } from 'react-i18next';

import {
  Heading,
  Alert,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCol,
  TableBody,
  TableData,
  Spinner,
} from '@digitools/honest-react';

import getLeaveBalanceAsOfDate from 'status/utilities/getLeaveBalanceAsOfDate';
import checkDate from 'status/utilities/checkDate';

const Error = ({ error, noData }) => {
  const notFound = noData || (error.response && error.response.status === 404);
  return (
    <Alert type={notFound ? 'info' : 'error'}>
      {notFound ? (
        'There is no leave balance data to display at this time.'
      ) : (
        <span>
          <strong>Error!</strong>&nbsp;Something went wrong when loading balance.
        </span>
      )}
    </Alert>
  );
};

const LeaveBalanceRow = ({ leaveBalance }) => {
  return (
    <TableRow>
      <TableData>{leaveBalance.program}</TableData>
      <TableData>{leaveBalance.hoursUsed}</TableData>
      <TableData>{leaveBalance.hoursRemain}</TableData>
      <TableData>{leaveBalance.lve_pgm_cde}</TableData>
      <TableData>{leaveBalance.lvePgmStateCde}</TableData>
      <TableData>{leaveBalance.lvePgmQualdCde === 'QF' ? 'Yes' : 'No'}</TableData>
      <TableData>{checkDate(leaveBalance.lvePgmQualdThruDte)}</TableData>
      <TableData>{leaveBalance.lvePgmEligCde === 'EL' ? 'Yes' : 'No'}</TableData>
      <TableData>{checkDate(leaveBalance.lvePgmEligThruDte)}</TableData>
    </TableRow>
  );
};

const LeaveBalance = ({ leaveId, leave }) => {
  const { t } = useTranslation();

  const {
    error: leaveRemainError,
    loading: loadingLeaveRemain,
    data: leaveRemainData,
  } = useGet({
    url: '/status-service/storproc/search/leaveRemain',
    options: {
      params: {
        leaveId,
        leaveType: leave.lveType,
      },
    },
  });

  if (loadingLeaveRemain) {
    return <Spinner id='leave-balance-spinner' center={true} />;
  }

  if (leaveRemainError || (leaveRemainData && leaveRemainData.content.length === 0)) {
    return <Error error={leaveRemainError} noData={leaveRemainData && leaveRemainData.content.length === 0} />;
  }

  return (
    <div>
      <div className='mb-2'>
        <Heading elemType={'h3'} color={'primary'}>
          {' '}
          {`${t('leaveBalanceText')} ${t('asOfText')} ${getLeaveBalanceAsOfDate(leave)}`}
        </Heading>
      </div>
      <Table>
        <TableHeader>
          <TableRow isFilled={true}>
            <TableHeaderCol>{t('leaveProgramText')}</TableHeaderCol>
            <TableHeaderCol>{t('programHoursUsedText')}</TableHeaderCol>
            <TableHeaderCol>{t('programHoursRemainingText')}</TableHeaderCol>
            <TableHeaderCol>{t('programType')}</TableHeaderCol>
            <TableHeaderCol>{t('state')}</TableHeaderCol>
            <TableHeaderCol>{t('qualified')}</TableHeaderCol>
            <TableHeaderCol>{t('qualifiedThrough')}</TableHeaderCol>
            <TableHeaderCol>{t('eligible')}</TableHeaderCol>
            <TableHeaderCol>{t('eligibleThrough')}</TableHeaderCol>
          </TableRow>
        </TableHeader>
        <TableBody>
          {leaveRemainData.content.map((leaveBalance, i) => (
            <LeaveBalanceRow key={`${leaveBalance.lve_num}-${i}`} leaveBalance={leaveBalance} />
          ))}
        </TableBody>
      </Table>
      <div>{t('programHoursDescriptionText')}</div>
    </div>
  );
};

export default LeaveBalance;
