import React from 'react';
import Modal from '@digitools/honest-react/dist/lib/components/Modal';
import Link from '@digitools/honest-react/dist/lib/components/Link';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import getDisplayText from '../../../utils/languageDisplay';
import IMfaAuthyInstructionsDisplayTextEn from '../i18n/IMfaAuthyInstructionsDisplayTextEn';
import IMfaAuthyInstructionsDisplayTextEs from '../i18n/IMfaAuthyInstructionsDisplayTextEs';
// @ts-ignore
import authyStep1 from 'src/assets/Authy-01-App.png';
// @ts-ignore
import authyStep2 from 'src/assets/Authy-02-Phone-Number.png';
// @ts-ignore
import authyStep4 from 'src/assets/Authy-04-Select-MLP.png';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
const MfaAuthyInstructionsModal = (props) => {
    const { trackEvent } = useTealium();
    const OpenMfaAuthyInstructionsModalButton = ({ showModal }) => {
        const handleOnClick = () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_type: TEALIUM_EVENT.EVENT_TYPE.OPEN_AUTHY_MODAL,
                event_name: 'Authy Instructions'
            });
            showModal();
        };
        return (<Button id='#authyInstructionsModal' onClick={handleOnClick} buttonType={'text'} className={'p-0'} data-testid='open-btn'>
        {getDisplayText('link', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language)}
      </Button>);
    };
    const step1Title = getDisplayText('step1Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step1TextA = getDisplayText('step1TextA', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step1TextB = getDisplayText('step1TextB', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step2Title = getDisplayText('step2Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step2Text = getDisplayText('step2Text', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step3Title = getDisplayText('step3Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step3Text = getDisplayText('step3Text', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step3Italic = getDisplayText('step3Italic', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step4Title = getDisplayText('step4Title', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step4Text = getDisplayText('step4Text', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    const step4Italic = getDisplayText('step4Italic', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language);
    return (<Modal containerMaxHeight={'90%'} hasCloseIcon={true} headerText={getDisplayText('heading', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language)} modalOpenRenderButton={OpenMfaAuthyInstructionsModalButton}>
      <p>
        {getDisplayText('body', IMfaAuthyInstructionsDisplayTextEn, IMfaAuthyInstructionsDisplayTextEs, props.language)}
      </p>
      <ol>
        <li>
          <strong>{step1Title} </strong>
          {step1TextA}
          <Link href={'https://www.authy.com/install'} target={'_blank'} data-testid='authy-link'>
            https://www.authy.com/install
          </Link>
          {step1TextB}
        </li>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={authyStep1} style={{ width: '200px', margin: '1rem' }} alt='Authy app store logo.'/>
        </div>
        <li>
          <strong>{step2Title} </strong>
          {step2Text}
        </li>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={authyStep2} style={{ width: '300px', margin: '1rem' }} alt='Authy setup page, asking for cell phone number.'/>
        </div>
        <li>
          <strong>{step3Title}</strong>
          {step3Text} <i>{step3Italic}</i>
        </li>
        <li>
          <strong>{step4Title}</strong>
          {step4Text} <i>{step4Italic}</i>
        </li>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={authyStep4} style={{ width: '250px', margin: '1rem' }} alt='Example of Authy app displaying portal token.'/>
        </div>
      </ol>
    </Modal>);
};
export default MfaAuthyInstructionsModal;
