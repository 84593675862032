import { Button, Icon, Modal, Spinner, StepperContext, themes } from '@digitools/honest-react';
import { useFormState } from 'informed';
import React, { useContext, useEffect, useRef, useState } from 'react';
import useTranslate from '../../../hooks/useTranslate';
import styled from 'styled-components';
import { SaveForLaterText } from '../../eoi/i18n/SaveForLaterText';
import useCliDataContext from '../hooks/useCliDataContext';
import { DeleteInterviewData, SaveInterviewData, UpdateInterviewData } from '../api/CliApiCalls';
import useExperience from '../../../hooks/useExperience';
import useAuthentication from 'src/hooks/useAuthentication';
import { addDays, format, startOfToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import useCliCustomerConfigContext from '../hooks/useCliContext';
import useCliContext from '../hooks/useCliContext';
import { deriveInterviewAnswers } from '../utils/apiUtils';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
import { getEventStatusByCurrentStep } from '../utils/eventStatusStepUtil';
const StyledLaterButton = styled(Button) `
  @media (max-width: 1200px) {
    padding: 0 1rem;
  }
`;
const StyledDeleteButton = styled(Button) `
  @media (max-width: 1200px) {
    padding: 0 1rem;
  }
`;
const SaveForLaterButton = ({ analyticsPageName }) => {
    const { setClaimantData, setAbsenceData, setContactData, claimantData, absenceData, contactData, isDirectDepositSuccess, paymentMethodData, setIsSflModalOpen, setPaymentMethodData, setIsDirectDepositSuccess, } = useCliDataContext();
    const { saveForLaterEntries, setSaveForLaterEntries } = useCliContext();
    const { trackEvent } = useTealium();
    const { t } = useTranslate();
    const formState = useFormState();
    const { authHeader, user } = useAuthentication();
    const { language, experienceCode } = useExperience();
    let navigate = useNavigate();
    const stepper = useContext(StepperContext);
    const cliConfigContext = useCliCustomerConfigContext();
    const sflDays = cliConfigContext.saveForLaterDays ? cliConfigContext.saveForLaterDays : 0;
    const sflApplicationExpirationDate = cliConfigContext && format(addDays(startOfToday(), sflDays), 'P');
    const [displaySaveForLaterModal, setDisplaySaveForLaterModal] = useState(false);
    const [success, setSuccess] = useState(true);
    const [loading, setLoading] = useState(false);
    const setIsOpenRef = useRef(() => { });
    const setControl = (setIsOpen) => {
        setIsOpenRef.current = setIsOpen;
    };
    useEffect(() => {
        if (displaySaveForLaterModal) {
            setIsOpenRef.current(true);
            setDisplaySaveForLaterModal(true);
        }
        else if (!displaySaveForLaterModal) {
            setIsOpenRef.current(false);
            setDisplaySaveForLaterModal(false);
        }
    }, [displaySaveForLaterModal]);
    const ReturnToApplicationButton = ({ hideModal }) => {
        const handleOnClick = () => {
            setIsSflModalOpen(false);
            hideModal();
            setDisplaySaveForLaterModal(false);
        };
        return (<Button type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-return-button'}>
        {t(SaveForLaterText.returnToApplication)}
      </Button>);
    };
    const SaveButton = ({ showModal }) => {
        const interviewAnswers = deriveInterviewAnswers({
            claimantData,
            absenceData,
            contactData,
            formState,
        });
        const handleOnClick = async () => {
            setIsSflModalOpen(true);
            showModal();
            setLoading(true);
            if (!formState.invalid) {
                try {
                    setDisplaySaveForLaterModal(true);
                    if (claimantData) {
                        // @ts-ignore
                        setClaimantData({ ...claimantData, ...formState?.values?.claimantData });
                        // @ts-ignore
                        setAbsenceData({ ...absenceData, ...formState?.values?.absenceData });
                        // @ts-ignore
                        setContactData({ ...contactData, ...formState?.values?.contactData });
                        if (!saveForLaterEntries?.rowID) {
                            SaveInterviewData({
                                savedData: interviewAnswers,
                                language: language,
                                title: experienceCode,
                                //@ts-ignore
                                preferredEmailAddress: user?.emailAddress,
                            }, authHeader()).then((response) => {
                                setSaveForLaterEntries({
                                    rowID: response?.data?.rowID,
                                    updatedDateAndTime: response?.data?.lastUpdatedDateTime,
                                });
                            });
                        }
                        else if (saveForLaterEntries?.rowID) {
                            UpdateInterviewData({
                                savedData: interviewAnswers,
                                language: language,
                                title: experienceCode,
                                rowID: saveForLaterEntries?.rowID,
                                //@ts-ignore
                                preferredEmailAddress: user?.emailAddress,
                            }, authHeader()).then((response) => {
                                setSaveForLaterEntries((prevState) => {
                                    return {
                                        ...prevState,
                                        updatedDateAndTime: response?.data?.lastUpdatedDateTime,
                                    };
                                });
                            });
                        }
                    }
                }
                catch (error) {
                    setSuccess(false);
                    console.error('Error sending data to endpoint');
                }
            }
            setLoading(false);
        };
        return (<StyledLaterButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-save-button'}>
        <Icon aria-hidden={true} name={'save'} color={'secondary'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        {t(SaveForLaterText.saveForLater)}
      </StyledLaterButton>);
    };
    const ExitApplicationButton = () => {
        const handleOnClick = () => {
            navigate(`/employee/home`);
            setIsSflModalOpen(false);
            setPaymentMethodData({});
            if (isDirectDepositSuccess) {
                setIsDirectDepositSuccess(false);
            }
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'save for later',
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_version: analyticsPageName,
                event_status: getEventStatusByCurrentStep(stepper, t),
            });
        };
        return (<Button type={'button'} buttonType={'primary'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-exit-button'}>
        {t(SaveForLaterText.exitApplication)}
      </Button>);
    };
    const DeleteApplicationButton = ({ hideModal }) => {
        const handleOnClick = async () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'delete application',
                event_type: TEALIUM_EVENT.EVENT_TYPE.CLAIM_LEAVE_INTAKE,
                event_version: analyticsPageName,
                event_status: getEventStatusByCurrentStep(stepper, t),
            });
            try {
                await DeleteInterviewData(saveForLaterEntries?.rowID, authHeader());
                setSaveForLaterEntries(null);
                setClaimantData({});
                setAbsenceData({});
                setContactData({});
                setPaymentMethodData({});
                navigate(`/employee/home`);
                setIsSflModalOpen(false);
            }
            catch (error) {
                console.log('Error deleting application', error);
                t(SaveForLaterText.saveForLaterDeleteErrorMessage);
                hideModal();
            }
        };
        return (<Button data-testid='sfl-delete-application' type={'button'} buttonType={'primary'} theme={themes.lincoln} onClick={handleOnClick}>
        {t(SaveForLaterText.deleteApplicationModal)}
      </Button>);
    };
    // Delete Modal wraps this button
    const DeleteButton = ({ showModal }) => {
        const handleOnClick = () => {
            setIsSflModalOpen(true);
            showModal();
        };
        return (<StyledDeleteButton type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={handleOnClick} data-testid={'sfl-delete-button'} style={{ marginLeft: '0rem' }}>
        <Icon aria-hidden={true} name={'trash'} color={'primaryDark'} size={'small'} style={{ marginRight: '0.5rem' }}/>
        <span style={{ color: themes.lincoln.palette.primaryDark }}>{t(SaveForLaterText.deleteApplication)}</span>
      </StyledDeleteButton>);
    };
    const isDDSelected = () => (analyticsPageName === 'payment method' || analyticsPageName === 'review') &&
        paymentMethodData?.preferredpaymentmethod === 'directDeposit';
    return (<>
      <Modal headerText={t(SaveForLaterText.deleteApplication)} modalOpenRenderButton={saveForLaterEntries?.rowID ? DeleteButton : () => null} footerPrimaryRenderButton={DeleteApplicationButton} footerSecondaryRenderButton={ReturnToApplicationButton} footerContentPosition={'flex-start'} theme={themes.lincoln} data-testid={'sfl-delete-modal'}>
        <>
          <p>
            <strong>{t(SaveForLaterText.deleteApplicationHeaderSuccess)}</strong>
          </p>
          <p className={`mt-1`}>{t(SaveForLaterText.deleteApplicationTextSuccess)}</p>
        </>
      </Modal>

      <Modal headerText={t(SaveForLaterText.saveForLater)} modalOpenRenderButton={SaveButton} footerPrimaryRenderButton={ExitApplicationButton} footerSecondaryRenderButton={ReturnToApplicationButton} footerContentPosition={'flex-start'} theme={themes.lincoln} getControl={setControl} data-testid={'sfl-save-modal'}>
        {loading ? (<Spinner id='sfl-buttons-spinner'/>) : success ? (<>
            <p>
              <strong>
                {isDDSelected()
                ? t(SaveForLaterText.paymentSavedApplicationHeaderSuccess)
                : t(SaveForLaterText.savedApplicationHeaderSuccess)}
              </strong>
            </p>
            <p className={`mt-1`}>
              {isDDSelected()
                ? t(SaveForLaterText.paymentSavedApplicationTextSuccess)
                : t(SaveForLaterText.savedApplicationTextSuccess)}
              <strong>{sflApplicationExpirationDate}</strong>
              {'.'}
            </p>
          </>) : (<>
            <p>
              <strong>{t(SaveForLaterText.savedApplicationHeaderError)}</strong>
            </p>
            <p className={`mt-1`}>{t(SaveForLaterText.savedApplicationTextError)}</p>
          </>)}
      </Modal>
    </>);
};
export default SaveForLaterButton;
