import React from 'react';
import { Col, Collapse, Row } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import styles from './Payment.scss';
const DataRow = ({ label, value }) => {
    return (<Row className={'mr-0'}>
      <Col>{label}</Col>
      <Col style={{ textAlign: 'right' }}>{value}</Col>
    </Row>);
};
const CheckDetailsMobile = ({ checkData }) => {
    const { t } = useTranslation();
    return (<Row data-testid='payment-list-mobile-details-collapsible'>
      <Col>
        <Collapse title={`${t('checkDetailsHeaderText')}`}>
          <DataRow label={t('checkTotalOffsetAmountText')} value={checkData.totalOffsetDeductions ? checkData.totalOffsetDeductions : '$0'}/>
          <DataRow label={t('checkAdjustedBenefitGrossAmountText')} value={checkData.adjustedBenefitGross}/>
          <DataRow label={t('colaAmountText')} value={checkData.cola}/>
          <Row>
            <Col>
              <Collapse title={`${t('checkTaxWithheldAmountText')}`} headerRightDisplay={() => <>{checkData.totalTaxWithheld}</>}>
                <DataRow label={`${t('checkFederalTaxWithheldAmountText')}`} value={checkData.totalFederalTaxWithheld}/>
                <DataRow label={`${t('checkStateTaxWithheldAmountText')}`} value={checkData.totalStateTaxWithheld}/>
                <DataRow label={`${t('checkLoclTaxWithheldAmountText')}`} value={checkData.localTaxWithheld}/>
                <DataRow label={<strong>{`${t('checkFicaTaxWithheldAmountText')}`}</strong>} value={checkData.ficaTaxWithheld}/>
                <DataRow label={<div className={styles.ficaTab}>{`${t('checkSsoaiTaxWithheldAmountText')}`}</div>} value={checkData.ssoaiTaxWithheld}/>
                <DataRow label={<div className={styles.ficaTab}>{`${t('checkMedicareTaxWithheldAmountText')}`}</div>} value={checkData.medicareTaxWithheld}/>
              </Collapse>

              <Collapse title={`${t('checkTotalDeductionAmountText')}`} headerRightDisplay={() => <>{checkData.totalOtherDeductions}</>}>
                {checkData.checkOtherDeductions &&
            checkData.checkOtherDeductions.length > 0 &&
            checkData.checkOtherDeductions.map((otherDeduction) => (<>
                      <DataRow label={'Begin Date'} value={otherDeduction.fromDate}/>
                      <DataRow label={'End Date'} value={otherDeduction.throughDate}/>
                      <DataRow label={'Reason'} value={otherDeduction.reason}/>
                      <DataRow label={'Amount'} value={otherDeduction.amount}/>
                      <hr />
                    </>))}
                {(!checkData.checkOtherDeductions || checkData.checkOtherDeductions.length <= 0) && (<span>{t('noInformationText').toString()}</span>)}
              </Collapse>
              <Collapse title={`${t('checkTotalOffsetAmountText')}`} headerRightDisplay={() => <>{checkData.totalOffsetDeductions}</>}>
                {checkData.checkOffsetDeductions &&
            checkData.checkOffsetDeductions.length > 0 &&
            checkData.checkOffsetDeductions.map((offsetDeduction) => (<>
                      <DataRow label={'Begin Date'} value={offsetDeduction.fromDate}/>
                      <DataRow label={'End Date'} value={offsetDeduction.throughDate}/>
                      <DataRow label={'Reason'} value={offsetDeduction.reason}/>
                      <DataRow label={'Amount'} value={offsetDeduction.amount}/>
                      <hr />
                    </>))}
                {(!checkData.checkOffsetDeductions || checkData.checkOffsetDeductions.length <= 0) && (<span>{t('noInformationText').toString()}</span>)}
              </Collapse>
              <Row style={{ marginTop: '.5rem' }}>
                <Col>
                  <DataRow label={<strong>Wages</strong>} value={<strong>Taxable</strong>}/>
                  <DataRow label={'Federal'} value={checkData.federalWage}/>
                  <DataRow label={'Medicare'} value={checkData.medicareWage}/>
                  <DataRow label={'OASDI'} value={checkData.ssoiWage}/>
                  <DataRow label={'State'} value={checkData.stateWage}/>
                  <DataRow label={''} value={<strong>Non-Taxable</strong>}/>
                  <DataRow label={'Federal'} value={checkData.nonTaxableFederal}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Collapse>
      </Col>
    </Row>);
};
export default CheckDetailsMobile;
