import React from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import { Scope } from 'informed';
import ApplicantContainer from '../../components/ApplicantContainer';
import Label from '@digitools/honest-react/dist/lib/components/Label';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import ReflexiveQuestion from './ReflexiveQuestion';
import useEoiContext from '../../hooks/useEoiContext';
import { QuestionsText } from '../../i18n/QuestionsText';
import { BorderLessGrayBox } from '../../../../components/Formatting/StyledComponents';
import styled from 'styled-components';
import { themes, CheckboxGroup } from '@digitools/honest-react';
const IndentDivNonMobile = styled('div') `
  @media (min-width: 501px) {
    margin-left: 1rem
  };
  margin-bottom: 2rem;
`;
const getInitialGroupValues = (interviewAnswers, applicant, dependentId) => {
    if (interviewAnswers.reflexiveAnswers) {
        let answers;
        if (applicant === 'dependent') {
            answers = interviewAnswers.reflexiveAnswers.dependents && interviewAnswers.reflexiveAnswers.dependents.filter((dependent) => dependent.dependentId === dependentId).shift();
            answers = answers && answers.responses ? answers.responses : [];
        }
        else {
            answers = interviewAnswers.reflexiveAnswers[applicant] && interviewAnswers.reflexiveAnswers[applicant].responses;
        }
        if (answers) {
            // convert the initialValues to match that of the Checkboxes - the wierd unique id stuff
            const result = answers.map((answer) => {
                return `value-${applicant}${dependentId ? dependentId : ''}${answer.questionCode}`;
            });
            return result;
        }
    }
    return [];
};
const findMatchingQuestionText = (questionCode, initialInformation) => {
    if (initialInformation && initialInformation.medicalQuestions) {
        const matchingQuestion = initialInformation.medicalQuestions.filter((medicalQuestion) => medicalQuestion.questionCode === questionCode).shift();
        if (matchingQuestion) {
            return matchingQuestion.text;
        }
    }
    return { english: '', spanish: '' };
};
const ApplicantReflexiveQuestions = ({ applicantReflexiveQuestions, applicant, firstName, lastName, dependentIndex, dependentId, instructions, }) => {
    const { t } = useTranslate();
    const { initialInformation, interviewAnswers } = useEoiContext();
    return (<>
      {/* @ts-ignore */}
      <Scope scope={applicant === 'dependent' ? `dependents[${dependentIndex}]` : applicant}>
        <ApplicantContainer type='name' applicant={applicant} firstName={firstName} lastName={lastName}>
          {/*  TODO: meg wants questions indented to align with title not icon/left side */}
          <br />
          <p>{t(instructions)}</p>
          {applicantReflexiveQuestions.map((reflexiveQuestionSet, reflexiveQuestionSetIndex) => {
            if (reflexiveQuestionSet.questions.length > 0) {
                // This offset needed to get all responses to sit after one another, otherwise scope indexes for response[] overlap multiple response sets in the same indexes 
                let reflexiveQuestionResponseIndexOffset = 0;
                for (let x = 0; x < reflexiveQuestionSetIndex; x++) {
                    reflexiveQuestionResponseIndexOffset += applicantReflexiveQuestions[x].questions.length;
                }
                return (<>
                  <BorderLessGrayBox style={{ display: 'block' }}>
                    <label htmlFor='question' style={{ color: themes.lincoln.palette.grayDark }}>
                      <strong>You answered yes to:</strong>
                    </label>
                    <p id='question'>
                      {t(findMatchingQuestionText(reflexiveQuestionSet.originalQuestionCode, initialInformation))}
                    </p>
                  </BorderLessGrayBox>
                  <br />
                  {applicant === 'dependent' && dependentId &&
                        <div style={{ display: 'none' }}>
                      <TextInput hidden={true} hideLabel={true} label={''} field={'dependentId'} initialValue={dependentId}/>
                    </div>}
                  <IndentDivNonMobile>
                    <Label style={{ marginBottom: '1rem', color: themes.lincoln.palette.grayDark }}>{t(QuestionsText.selectAllThatApply)}</Label>
                    {reflexiveQuestionSet.questions && reflexiveQuestionSet.questions.length > 0 &&
                        <CheckboxGroup field={reflexiveQuestionSet.originalQuestionCode} label={'Select all that apply'} hideLabel={true} validate={(values) => values && values.length > 0 ? undefined : t(QuestionsText.reflectiveQuestionValidationError)} validateOnChange={true} validateOnBlur={true} 
                        //@ts-ignore FIXME: typing error
                        initialValue={() => getInitialGroupValues(interviewAnswers, applicant, dependentId)}>
                        {reflexiveQuestionSet.questions.map((question, reflexiveQuestionIndex) => {
                                return <ReflexiveQuestion key={reflexiveQuestionIndex} dependentIndex={dependentIndex} dependentId={dependentId} index={reflexiveQuestionResponseIndexOffset + reflexiveQuestionIndex} applicant={applicant} reflexiveQuestion={question} originalQuestionCode={reflexiveQuestionSet.originalQuestionCode}/>;
                            })}
                      </CheckboxGroup>}

                  </IndentDivNonMobile>
                </>);
            }
        })}
        </ApplicantContainer>
      </Scope>
    </>);
};
export default ApplicantReflexiveQuestions;
