import React, { useEffect } from 'react';
import { Alert, Heading, Spinner } from '@digitools/honest-react';
import styles from './CoverageDetails.scss';
import queryString from 'query-string';
import { useStatusDefinitionList, useProductAttributeCodes, useAuth0, useLanguage, useExperience } from 'status/hooks';
import useGet from '../../../../../hooks/useGet';
import { withRouter } from 'src/utils/withRouter';
import CoverageTabs from './CoverageTabs';
import titleCase from 'status/utilities/titleCase';
import { decode } from 'status/utilities/hash';
import EmployeeDetails from 'status/components/EmployeeDetails/EmployeeDetails';
import TeamViewEmployeeActions from './TeamViewEmployeeActions';
import EmployeeEligibilityDetails from './EmployeeEligibilityDetails';
import ReturnToViewTeamMember from './ReturnToViewTeamMember';
import ReturnToSearch from './ReturnToSearch';
import Notifications, { FunctionalArea } from '../../../../../components/Notifications/Notifications';
import { useTranslation } from 'react-i18next';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
import { useParams } from 'react-router-dom';

const EmployeeError = ({ error }) => (
  <Alert type={'error'}>
    {error.response && error.response.status === 404 ? (
      'No employee found.'
    ) : (
      <span>
        <strong>Error!</strong>&nbsp;Something went wrong when retrieving this employee.
      </span>
    )}
  </Alert>
);

// Helper function to determine if we can show EOI
const showEOI = (userMeta, experience) => {
  if (experience === 'ER') {
    return false;
  }
  const applicationPermissions = userMeta.applicationPermissions;
  return applicationPermissions.indexOf('statusEE.eoiStatus') > -1;
};

const FullCoverageDetails = ({ match, fromTeamView }) => {
  const parsed = queryString.parse(location.search);
  const { userMeta } = useAuth0();
  const { language } = useLanguage();
  const { getExperience } = useExperience();
  const { t } = useTranslation();
  const experience = getExperience();
  const { trackView } = useTealium();
  const pathParams = useParams();

  useEffect(() => {
    trackView({
      page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.STATUS,
      page_l4:
        experience === 'ER'
          ? TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.EMPLOYER_VIEW
          : TEALIUM_PAGE_VIEW.PAGE_L4.STATUS.EMPLOYEE_VIEW,
      page_l5: TEALIUM_PAGE_VIEW.PAGE_L5.STATUS.COVERAGE_DETAILS
    });
  }, []);

  const empNum = decode(pathParams.empNum);

  const params = { empNum };

  const {
    error: claimError,
    loading: loadingClaims,
    data: claimData,
  } = useGet({
    url: '/status-service/claims/search/searchByEmpNum',
    options: {
      params,
    },
    handleUnauthorized: true,
  });

  const {
    error: leaveError,
    loading: loadingLeaves,
    data: leaveData,
  } = useGet({
    url: '/status-service/leaves/search/searchByEmpNum',
    options: {
      params,
    },
    noInitial: userMeta.customerInContext.smallMid,
    handleUnauthorized: true,
  });

  const {
    error: employeeError,
    loading: loadingEmployee,
    data: employeeData,
  } = useGet({
    url: '/status-service/employee/search/searchByEmpNum',
    options: {
      params,
    },
  });

  const showEOITab = showEOI(userMeta, experience);
  const {
    error: eoiError,
    loading: loadingEoi,
    data: eoiData,
  } = useGet(
    {
      url: '/status-service/eoi/status',
      options: {
        params: {
          languageCode: language,
        },
      },
      noInitial: !showEOITab,
    },
    [language],
  );

  const { error: statusDefError, loading: statusDefLoading, statusDefinitionList } = useStatusDefinitionList();

  const { error: prodAttError, loading: prodAttLoading, productAttributeCodes } = useProductAttributeCodes();

  const loading = loadingEoi || loadingClaims || loadingLeaves || loadingEmployee || statusDefLoading || prodAttLoading;

  if (loading) {
    return <Spinner id='full-coverage-details-spinner' />;
  }

  if (employeeError) {
    return <EmployeeError error={employeeError} />;
  }
  const name = `${titleCase(employeeData.empFirstName)} ${titleCase(employeeData.empLastName)}`;

  console.log('empData', employeeData)
  console.log('empError', employeeError)
  console.log('claimData', claimData)
  console.log('claimError', claimError)
  return (
    <div className={styles.container}>
      <Notifications functionalArea={FunctionalArea.COVERAGE_DETAILS} />
      {fromTeamView ? <ReturnToViewTeamMember empId={employeeData.empId} /> : null}
      {experience === 'ER' && !fromTeamView ? <ReturnToSearch parsed={parsed} /> : null}
      <Heading data-testid='status-header'>{t('statusHeader')}</Heading>
      <EmployeeDetails empNum={empNum} empEmailAddress={employeeData.empEmailAddr} />
      <EmployeeEligibilityDetails empId={employeeData.empId} empNum={empNum} />
      {fromTeamView ? <TeamViewEmployeeActions empId={employeeData.empId} /> : null}
      <CoverageTabs
        claimData={claimData}
        leaveData={leaveData}
        employeeData={employeeData}
        eoiData={eoiData}
        eoiError={eoiError}
        showEOITab={showEOITab}
        empNum={empNum}
        prodAttCodes={productAttributeCodes}
        statusDefinitionList={statusDefinitionList}
        claimError={claimError}
        leaveError={leaveError || prodAttError || statusDefError}
        fromTeamView={fromTeamView}
      />
    </div>
  );
};

export default withRouter(FullCoverageDetails);
