import { Box, Col, Row } from '@digitools/honest-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckDetailsMobile from './CheckDetailsMobile';
const PaymentListMobile = ({ paymentList }) => {
    const { t } = useTranslation();
    return (<>
            {paymentList.map((check, i) => {
            return (<>
                        <Box key={`${i}-h`} type='header'>Check #{check.checkNumber}</Box>
                        <Box key={i} data-test-class='payment-list-mobile' data-testid="paymentListMobile">
                            <Row className={'mr-0'}>
                                <Col>{t('checkDateHeaderText').toString()}</Col>
                                <Col style={{ textAlign: 'right' }}>{check.checkDate}</Col>
                            </Row>
                            <Row className={'mr-0'}>
                                <Col>{t('beginPaymentDateHeaderText').toString()}</Col>
                                <Col style={{ textAlign: 'right' }}>{check.fromDate}</Col>
                            </Row>
                            <Row className={'mr-0'}>
                                <Col>{t('endPaymentDateHeaderText').toString()}</Col>
                                <Col style={{ textAlign: 'right' }}>{check.throughDate}</Col>
                            </Row>
                            <Row className={'mr-0'}>
                                <Col>{t('checkStatusHeaderText').toString()}</Col>
                                <Col style={{ textAlign: 'right' }}>{t(check.statusCode).toString()}</Col>
                            </Row>
                            <Row className={'mr-0'}>
                                <Col>{t('grossBenefitsHeaderText').toString()}</Col>
                                <Col style={{ textAlign: 'right' }}>{t(check.benefitGross).toString()}</Col>
                            </Row>
                            <Row className={'mr-0'}>
                                <Col>{t('netBenefitsHeaderText').toString()}</Col>
                                <Col style={{ textAlign: 'right' }}>{t(check.benefitNet).toString()}</Col>
                            </Row>
                            <Row className={'mr-0'}>
                                <Col>{t('payeeHeaderText')}</Col>
                                <Col data-private style={{ textAlign: 'right' }}>{t(check.payeeName)}</Col>
                            </Row>
                            <CheckDetailsMobile checkData={check}/>
                        </Box>
                    </>);
        })}
        </>);
};
export default PaymentListMobile;
