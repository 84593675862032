import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from '@digitools/honest-react';

const ReturnToSearch = ({ parsed }) => {
  const { t } = useTranslation();

  const to = parsed.search
    ? `/status/employer/search?search=${parsed.search}&searchCrit=${parsed.searchCrit}&from=detailPage`
    : '/status/employer/search';

  return (
    <div className='mb-4 d-flex align-items-center'>
      <Icon name='long-arrow-left-regular' size='small' className='mr-2' />
      <Link to={to}>{t('returnToSearch')}</Link>
    </div>
  );
};
export default ReturnToSearch;
