import { Alert, Box, Container, Heading, Loader, Row, Spinner, themes } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
import useAuthentication from '../../../hooks/useAuthentication';
import useTealium from '../../../hooks/useTealium';
import WatchList from '../../status/components/pages/Watchlist/Watchlist';
import { getHierarchyByEmpId, getHierarchyByName } from '../api';
import EmployeeSearchResultSection from '../components/EmployeeSearchResultSection';
import Hierarchy from '../components/Hierarchy';
import Search from '../components/Search';
function isTeamViewReadOnlyForInternalUsers(user) {
    let teamViewReadOnly = false;
    if (user.customerInContext !== undefined && user.applicationPermissions !== undefined) {
        teamViewReadOnly = user.applicationPermissions.indexOf('teamView.readOnly') > -1;
    }
    return teamViewReadOnly;
}
const TeamViewContainer = () => {
    const { user, authHeader } = useAuthentication();
    const { trackEvent } = useTealium();
    const [search, setSearch] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState('info');
    const [hierarchyData, setHierarchyData] = useState();
    const [hierarchyDataByEmployeeName, setHierarchyDataByEmployeeName] = useState([]);
    const [access, setAccess] = useState(false);
    const [showEmployeeSearchResultSection, setShowEmployeeSearchResultSection] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false);
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.TEAM_VIEW,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.TEAM_VIEW.VIEW,
        });
    }, []);
    useEffect(() => {
        setInitialLoad(true);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.ONLOAD,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_TEAM_MEMBER,
            event_name: 'View Team Member',
        });
        setLoading(true);
        const teamViewReadOnly = isTeamViewReadOnlyForInternalUsers(user);
        if (user.ssoCargo !== undefined && user.ssoCargo.employeeId !== undefined) {
            setAccess(true);
            getSearchByEmpId(authHeader(), user.ssoCargo.employeeId);
        }
        else if (teamViewReadOnly) {
            setAccess(true);
        }
        setLoading(false);
    }, [user]);
    const onClose = () => {
        setError(false);
        setErrorMessage('');
    };
    const getSearchByEmpId = async (header, empId) => {
        setInitialLoad(false);
        setErrorMessage('');
        setError(false);
        getHierarchyByEmpId(header, empId)
            .then((responseData) => {
            setHierarchyData(responseData.data);
            setLoading(false);
        })
            .catch(e => {
            setError(true);
            if (e.message.includes('403')) {
                setErrorMessage(`You do not have access to view employee id ${empId}`);
                setErrorType('error');
            }
            else if (e.message.includes('404')) {
                setErrorMessage(`No data found`);
                setErrorType('info');
            }
            else {
                setErrorMessage('Error occured while fetching employee hierarchy');
                setErrorType('error');
            }
            setLoading(false);
        });
    };
    const alert = error ? (<Alert type={errorType} closeable={true} onClose={onClose} data-testid='errorAlert' style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      {errorMessage}
    </Alert>) : (<></>);
    const getSearchByName = (lastName, firstName) => {
        setInitialLoad(false);
        setErrorMessage('');
        setError(false);
        getHierarchyByName(authHeader(), lastName, firstName)
            .then((responseData) => {
            if (responseData.data && responseData.data.length === 1) {
                const dataByEmpName = responseData.data[0];
                setHierarchyDataByEmployeeName([]);
                getSearchByEmpId(authHeader(), dataByEmpName.empId);
            }
            else {
                setHierarchyDataByEmployeeName(responseData.data);
                setLoading(false);
                setShowEmployeeSearchResultSection(true);
            }
        })
            .catch(e => {
            if (e.response.status === 404) {
                setErrorMessage('No data found or you may not have access to view employee');
                setErrorType('info');
            }
            else {
                setErrorMessage('Error occured while fetching employee hierarchy');
                setErrorType('error');
            }
            setHierarchyDataByEmployeeName([]);
            setError(true);
            setLoading(false);
        });
    };
    const onHandleSearch = async (values) => {
        setLoading(true);
        if (values.searchCriteria === 'empId') {
            getSearchByEmpId(authHeader(), values.search);
        }
        else if (values.searchCriteria === 'empName') {
            const lastName = values.search.split(',')[0];
            const firstName = values.search.split(',')[1];
            getSearchByName(lastName, firstName);
        }
        setSearch(true);
    };
    const getHierarchy = (empId) => {
        getSearchByEmpId(authHeader(), empId);
    };
    const searchResult = showEmployeeSearchResultSection ? (loading ? (<Spinner id='team-view-container-spinner1'/>) : hierarchyDataByEmployeeName.length === 0 ? ('') : (<EmployeeSearchResultSection getHierarchy={getHierarchy} searchResult={hierarchyDataByEmployeeName}/>)) : (<></>);
    if (!access && !loading) {
        return (<Alert type='error' closeable={false} data-testid='noAccess' data-public={true}>
        You dont have access to view Team View, please contact your system administrator!
      </Alert>);
    }
    const hierarchy = loading ? (<Spinner id='team-view-container-spinner2'/>) : (<Hierarchy hierarchyData={hierarchyData} search={search} getHierarchy={getHierarchy} initialLoad={initialLoad} internalUser={isTeamViewReadOnlyForInternalUsers(user)} error={error}/>);
    return (<Container>
      <Loader id='team-view-container-spinner' isShown={loading} data-testid={'loading-indicator'}>
        <Row data-public={true} style={{ marginBottom: '1.5rem' }}>
          <Heading elemType={'h1'} data-testid={'teamViewHeading'}>
            Team view
          </Heading>
        </Row>
        <WatchList from={'teamView'}/>
        <Box data-public={true} style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
          <Heading elemType={'h4'} style={{ borderBottom: '1px solid #d8d8d8', paddingBottom: '.75rem', paddingLeft: 0 }}>
            Employee search
          </Heading>
          <Search onHandleSearch={onHandleSearch}/>
          {alert}
          {searchResult}
          {hierarchy}
        </Box>
      </Loader>
    </Container>);
};
export default TeamViewContainer;
