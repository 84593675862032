import React, { useContext, useEffect } from 'react';
import { Box, Icon, StepperContext } from '@digitools/honest-react';
import { returnLabelByValue } from './../../utils/reasonForTimeOffTypes';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../../constants/tealiumConstants';
const ConfirmationScreen = ({ employeeName, timeOffValues, transactionNumber, triggerFormReset, resetState }) => {
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.USAA,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.USAA.CONFIRMATION,
        });
    }, []);
    const home = () => {
        trackClickWithPage('Return to Home', '/uaMobile/confirmation');
        triggerFormReset();
        stepper.goToStep(0);
        resetState();
    };
    const stepper = useContext(StepperContext);
    return (<>
      <span>
        <Icon style={{ color: 'green' }} name={'lock'}/>
        <strong> Your Unplanned Time Off Request was successfully submitted.</strong>
      </span>
      <br />
      <br />
      <Box color={'grayLighter'}>
        <p><strong>Transaction Number: </strong>{transactionNumber}</p>
        <p data-testid='timeoff'><strong>Time Off Date: </strong>{timeOffValues.date}</p>
        <p><strong>Reason: </strong>{returnLabelByValue(timeOffValues.reasonForTimeOff)}</p>
      </Box>
      <>
        <p>An email notification was sent to :</p>
        <p><strong data-private={true}>{`${employeeName}'s `}</strong>Manager</p>
        <p>Your time sheet will be updated shortly. Please check your time sheet to validate the information and prevent any pay issues.</p>
      </>
      <br />
      <br />
      <>
        <a onClick={home}>
          <span>
            <Icon name={'home'}/>
          </span> Return to Home
        </a>
      </>
    </>);
};
export default ConfirmationScreen;
