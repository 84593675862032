import React, { useState } from 'react';
import { Scope, useFormState } from 'informed';
import TextInput from '@digitools/honest-react/dist/lib/components/TextInput';
import SelectInput from '@digitools/honest-react/dist/lib/components/SelectInput';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { phoneNumberMask } from '../../../utils/InputMasks';
import AddressForm from './AddressForm';
import useValidEmail from '../../../../../hooks/fieldValidation/useValidEmail';
import useValidPhone from '../../../../../hooks/fieldValidation/useValidPhone';
import { fieldRequired } from '../../../utils/FormFieldValidations';
import useTranslate from '../../../../../hooks/useTranslate';
import { ContactInformationText } from '../../../i18n/ApplicantInformationText';
import { EOIHelpText } from '../../../i18n/EoiText';
import useExperience from '../../../../../hooks/useExperience';
import { Language } from '../../../../../types/Language';
import useEoiContext from '../../../hooks/useEoiContext';
const ContactInformation = (props) => {
    const { customContent, initialInformation, setEoiError } = useEoiContext();
    const { t } = useTranslate();
    const { language } = useExperience();
    const formState = useFormState();
    const [displayMailingAddress, setDisplayMailingAddress] = useState(true);
    const validateEmail = useValidEmail(true);
    const validatePhone = useValidPhone(true);
    const hideMailingAddress = (hideMailing) => {
        setDisplayMailingAddress(!hideMailing);
    };
    if (!initialInformation || !customContent) {
        setEoiError(true);
    }
    const communicationPreferenceOptions = [
        {
            label: 'Email',
            value: 'EML',
        },
        {
            label: t(EOIHelpText.mailText),
            value: 'USP',
        },
    ];
    const phoneNumberTypes = [
        {
            label: t(ContactInformationText.typeHome),
            value: 'HM',
        },
        {
            label: t(ContactInformationText.typeWork),
            value: 'OFF',
        },
        {
            label: t(ContactInformationText.typeMobile),
            value: 'MBL',
        },
    ];
    // get prepop values from interviewAnswersApplicant
    let contactInformation = {};
    if (props.interviewAnswersApplicant.contactInformation) {
        contactInformation = props.interviewAnswersApplicant.contactInformation;
    }
    return (
    // @ts-ignore
    <Scope scope={'contactInformation'}>
      <div style={{ marginBottom: '1rem', marginTop: '1rem' }} data-testid={'contact-information'} data-public={true}>
        <Row>
          <Col sm={12} md={6} lg={language === Language.SPANISH ? 6 : 3}>
            <SelectInput theme={themes.lincoln} validate={fieldRequired} validateOnChange={true} validateOnBlur={true} label={t(ContactInformationText.phoneNumberType)} placeholderText={t(ContactInformationText.phoneNumberTypePlaceholder)} aria-label={t(ContactInformationText.phoneNumberType)} field={'phoneNumberType'} options={phoneNumberTypes} initialValue={contactInformation.phoneNumberType} data-testid={'contact-info-phone-number-type'}/>
          </Col>
          <Col sm={12} md={6} lg={language === Language.SPANISH ? 6 : 3}>
            <TextInput theme={themes.lincoln} validate={validatePhone} validateOnChange={true} validateOnBlur={true} label={t(ContactInformationText.phoneNumber)} aria-label={t(ContactInformationText.phoneNumber)} field={'phoneNumber'} mask={phoneNumberMask} initialValue={contactInformation.phoneNumber} data-testid={'contact-info-phone-number'}/>
          </Col>
          <Col sm={12} md={6}>
            <TextInput theme={themes.lincoln} validate={validateEmail} validateOnChange={true} validateOnBlur={true} label={'Email'} aria-label={'Email'} field={'emailAddress'} initialValue={contactInformation.emailAddress} data-testid={'contact-info-email-address'}/>
          </Col>
        </Row>
        <Row style={{ marginTop: 0 }}>
          <Col sm={12} md={6}>
            <SelectInput theme={themes.lincoln} placeholderText={t(ContactInformationText.communicationPreference)} label={t(ContactInformationText.communicationPreference)} aria-label={t(ContactInformationText.communicationPreference)} options={communicationPreferenceOptions} field={'communicationPreference'} initialValue={contactInformation.communicationPreference ? contactInformation.communicationPreference : 'EML'} validate={fieldRequired} validateOnChange={true} validateOnBlur={true}/>
          </Col>
        </Row>
        <AddressForm type={'residential'} hideMailingAddress={hideMailingAddress} states={props.states} provinces={props.provinces} countries={props.countries} interviewAnswersApplicant={props.interviewAnswersApplicant}/>
        {displayMailingAddress &&
            <AddressForm type={'mailing'} states={props.states} provinces={props.provinces} countries={props.countries} interviewAnswersApplicant={props.interviewAnswersApplicant}/>}
      </div>
    </Scope>);
};
export default ContactInformation;
