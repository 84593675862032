import { Alert, Heading } from '@digitools/honest-react';
import React, { useContext, useEffect } from 'react';
import { ExperienceContext } from '../../providers/ExperienceProvider';
import getDisplayText from '../../utils/languageDisplay';
import { SsoLogoutPageDisplayTextEn } from './i18n/SsoLogoutPageDisplayTextEn';
import { SsoLogoutPageDisplayTextEs } from './i18n/SsoLogoutPageDisplayTextEs';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
import { withRouter } from 'src/utils/withRouter';
const SsoLogoutPage = () => {
    const { language } = useContext(ExperienceContext);
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.SSO_LOGOUT,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.SSO_LOGOUT.VIEW,
        });
    }, []);
    return (<>
      <Alert data-testid={'alert'} type={'success'}>
        <b>{getDisplayText('alertStaticText', SsoLogoutPageDisplayTextEn, SsoLogoutPageDisplayTextEs, language)}</b>
      </Alert>
      {/* <p>{getDisplayText('alertStaticText', SsoLogoutPageDisplayTextEn, SsoLogoutPageDisplayTextEs, language)}</p> */}
      <Heading>{getDisplayText('title', SsoLogoutPageDisplayTextEn, SsoLogoutPageDisplayTextEs, language)}</Heading>


      <p>{getDisplayText('bodySection1', SsoLogoutPageDisplayTextEn, SsoLogoutPageDisplayTextEs, language)}</p>
      <p>{getDisplayText('bodySection2', SsoLogoutPageDisplayTextEn, SsoLogoutPageDisplayTextEs, language)}</p>
    </>);
};
export default withRouter(SsoLogoutPage);
