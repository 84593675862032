import { Form } from 'informed';
import { Alert, Button, Heading, Icon, Loader, Table, TableBody, TableData, TableHeader, TableHeaderCol, TableRow, themes, } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../../hooks/useAuthentication';
import { isUSAA } from '../public/usaa/utils/CustomerUtils';
import { downloadUnplannedAbsence, formatDate, formatDuration, formatTime, getAbsenceReason, getAbsenceType, GetInitialAbsences, SubmitUnplannedAbsence, } from './api/UnplannedAbsenceApi';
import UnplannedAbsenceForm from './components/UnplannedAbsenceForm';
import useTealium from '../../hooks/useTealium';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from '../../constants/tealiumConstants';
import DeleteAbsenceButton from './components/DeleteAbsenceButton';
const UnplannedAbsence = () => {
    const navigate = useNavigate();
    const { user, authHeader } = useAuthentication();
    const { trackView, trackEvent } = useTealium();
    const [showUAForm, setShowUAForm] = useState(false);
    const [failureMessage, setFailureMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [pdfError, setPdfError] = useState(false);
    const [absenceToEdit, setAbsenceToEdit] = useState();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.UNPLANNED_ABSENCE,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.UNPLANNED_ABSENCE.VIEW,
        });
    }, []);
    const showFailureMessage = (message) => {
        setSuccessMessage('');
        setFailureMessage(message);
        window.scrollTo(0, 0);
    };
    const showSuccessMessage = (message) => {
        setSuccessMessage(message);
        setFailureMessage('');
        window.scrollTo(0, 0);
    };
    const onSuccess = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: 'Submit unplanned absence',
            event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
            event_status: TEALIUM_EVENT.EVENT_STATUS.COMPLETE,
        });
        setShowUAForm(false);
        showSuccessMessage('Your absence has been successfully saved.');
        getLeaves();
    };
    const onFailure = (e) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
            event_name: e.message,
            event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
            event_version: e.message,
        });
        showFailureMessage('There was a problem submitting your absence.');
    };
    const { post: submitAbsencePost, loading: submitAbsenceLoading } = SubmitUnplannedAbsence(onSuccess, onFailure);
    const { loading, data, error, get: getLeaves } = GetInitialAbsences();
    const hasAbsences = data?.absences?.length > 0;
    useEffect(() => {
        if (user && user.customerId && !isUSAA(user.customerId)) {
            navigate('/employee/home');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, data, error, loading]);
    useEffect(() => {
        if (data?.absences.length && data?.absences.length < 1) {
            // Trigger event for case where form auto-displays
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                event_name: 'Show unplanned absence form',
                event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
                event_status: TEALIUM_EVENT.EVENT_STATUS.START,
            });
        }
    }, [data]);
    const onAlertClose = () => {
        setFailureMessage('');
        setSuccessMessage('');
    };
    const handleAddAbsence = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_name: 'Show unplanned absence form',
            event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
            event_status: TEALIUM_EVENT.EVENT_STATUS.START,
        });
        setAbsenceToEdit(undefined);
        setShowUAForm(true);
    };
    const handleCancel = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'Cancel unplanned absence',
            event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
            event_status: TEALIUM_EVENT.EVENT_STATUS.CANCEL,
        });
        setAbsenceToEdit(undefined);
        setShowUAForm(false);
    };
    const submitAbsence = (values) => {
        const absResource = {
            spansMidnight: values.spansMidnight,
            absType: values.absType,
            absenceDate: values.date,
            startTime: !(values.spansMidnight === false && values.absType === 'OUT')
                ? `${values.startTimeHours}:${values.startTimeMinutes}`
                : undefined,
            endTime: !(values.spansMidnight === false && values.absType === 'OUT')
                ? `${values.endTimeHours}:${values.endTimeMinutes}`
                : undefined,
            duration: values.spansMidnight === false && values.absType === 'OUT'
                ? `${values.durationHours}:${values.durationMinutes}`
                : undefined,
            absReason: values.absReason,
            hasAssociatedLeave: values.hasLeave,
            transType: absenceToEdit ? 'C' : 'A',
            deathFollowsPolicy: values.immediateFamily,
            additionalDates: values.additionalDates,
            associatedLeaveNumber: values.leaveId || values.leaveIdTextInput,
            associatedTransNum: absenceToEdit?.transNum,
        };
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: absenceToEdit ? 'Submit edited unplanned absence' : 'Submit unplanned absence',
            event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
        });
        // console.log('__+=+=+=__+=+=+=__+=+=+=__+=+=+=__+=+=+=__+=+=+=__+=+=+=__+=+=+=__+=+=+=__+=+=+=_');
        // console.log(absResource);
        submitAbsencePost({ payload: absResource });
    };
    const getEmployeeName = (data) => {
        return data && data.absenteeName && data.absenteeName !== 'SELF'
            ? data.absenteeName
            : `${user?.firstName} ${user?.lastName}`;
    };
    const editAbsence = (absence) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'Show edit unplanned absence form',
            event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
            event_status: TEALIUM_EVENT.EVENT_STATUS.START,
            event_version: absence.transNum,
        });
        setAbsenceToEdit(absence);
        setShowUAForm(true);
    };
    const onDownloadClick = async (absence) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_name: 'Download Unplanned Absence',
            event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
        });
        try {
            setPdfError(false);
            const employeeName = getEmployeeName(data);
            const response = await downloadUnplannedAbsence(absence, employeeName, authHeader());
            const filename = `${employeeName} - Absence ${absence.transNum}`;
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            saveAs(blob, filename);
        }
        catch (error) {
            setPdfError(true);
        }
    };
    return (<Loader isShown={loading}>
      <div data-public={true}>
        <Heading elemType={'h1'}>Unplanned Absence Home</Heading>
        <p style={{ fontSize: '1.5rem' }} data-private={true}>
          You are managing absences on{' '}
          {data && data.absenteeName && data.absenteeName !== 'SELF'
            ? data.absenteeName + "'s behalf"
            : ' your own behalf'}
          .
        </p>
        <p>This secure site provides you with a fast and convenient way to report unplanned absences from work. </p>
        <p>
          {hasAbsences
            ? `Previously submitted unplanned absences that can be edited or cancelled are shown below. To make changes to
            these absences, use the appropriate icon next to the unplanned absence you want to alter. To report a new
            unplanned absence, select 'Add.'`
            : `There are no previously submitted unplanned absences that can be edited or cancelled. To report a new unplanned absence, complete and submit the form below.`}
        </p>
        {/* begin dashboard table comp */}
        {hasAbsences && (<div className={'d-flex align-items-center justify-content-between'}>
            <Heading elemType={'h2'} elemStyle={'h3'}>
              Unplanned Absences
            </Heading>
            <Button buttonType={'text'} className='d-flex align-items-center' data-testid='add-absence-button' onClick={handleAddAbsence}>
              <Icon name={'add'} color={'secondary'}/>
              &nbsp;Add absence
            </Button>
          </div>)}
        <Alert visible={!!failureMessage} onClose={onAlertClose} closeable={true} type={'error'} data-testid={'failure-message'}>
          {failureMessage}
        </Alert>
        <Alert visible={!!successMessage} onClose={onAlertClose} closeable={true} type={'success'} data-testid={'success-message'}>
          {successMessage}
        </Alert>
        {hasAbsences && (<>
            <Table theme={themes.lincoln} data-testid='ua-table'>
              <TableHeader>
                <TableRow isFilled={true}>
                  <TableHeaderCol>Date</TableHeaderCol>
                  <TableHeaderCol>Start Time</TableHeaderCol>
                  <TableHeaderCol>End Time</TableHeaderCol>
                  <TableHeaderCol>Duration</TableHeaderCol>
                  <TableHeaderCol>Type</TableHeaderCol>
                  <TableHeaderCol>Reason</TableHeaderCol>
                  <TableHeaderCol>Related Leave</TableHeaderCol>
                  <TableHeaderCol>Transaction ID</TableHeaderCol>
                  <TableHeaderCol>Actions</TableHeaderCol>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.absences.map((absence) => {
                return (<TableRow>
                      <TableData>{formatDate(absence.uaStartDate)}</TableData>
                      <TableData>{formatTime(absence.uaStartTime)}</TableData>
                      <TableData>{formatTime(absence.uaEndTime)}</TableData>
                      <TableData>{formatDuration(absence.uaDuration)}</TableData>
                      <TableData>{getAbsenceType(absence.absType)}</TableData>
                      <TableData>{getAbsenceReason(absence.absReason)}</TableData>
                      <TableData data-private={true}>{absence.associatedLeave?.trim() || 'N/A'}</TableData>
                      <TableData data-private={true}>{absence.transNum}</TableData>
                      <TableData>
                        <div className='d-flex'>
                          <Button className={'px-0'} theme={themes.lincoln} type={'button'} data-testid={`download-${absence.transNum}`} onClick={() => onDownloadClick(absence)}>
                            <Icon name={'pdf'} size='small' color={'secondary'} className={'font-weight-bold'}/>
                          </Button>
                          <Button className={'px-0'} theme={themes.lincoln} type={'button'} data-testid={`edit-${absence.transNum}`} onClick={() => editAbsence(absence)}>
                            <Icon name={'edit'} size='medium' color={'secondary'}/>
                          </Button>
                          <DeleteAbsenceButton absence={absence} getLeaves={getLeaves} setSuccessMessage={showSuccessMessage} setFailureMessage={showFailureMessage}/>
                        </div>
                      </TableData>
                    </TableRow>);
            })}
              </TableBody>
            </Table>
            {!!pdfError && (<Alert type={'error'} data-testid={'pdf-error'}>
                Something went wrong with downloading your Absence PDF. Please try again.
              </Alert>)}
          </>)}
        {(!hasAbsences || showUAForm) && (<Loader isShown={submitAbsenceLoading}>
            {showUAForm && (<Heading elemType={'h2'} elemStyle={'h3'}>
                {absenceToEdit ? 'Edit your absence' : 'Submit a new absence'}
              </Heading>)}
            <Form onSubmit={submitAbsence} data-testid={'ua-form'}>
              <UnplannedAbsenceForm onCancel={handleCancel} hasAbsences={hasAbsences} initialAbsence={absenceToEdit}/>
            </Form>
          </Loader>)}
      </div>
    </Loader>);
};
export default UnplannedAbsence;
