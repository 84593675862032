import React from 'react';
import { Col, Row, TextInput } from '@digitools/honest-react';
import useTranslate from 'src/hooks/useTranslate';
import useCliDataContext from '../../../hooks/useCliDataContext';
import AboutYourAbsenceText from '../../../constants/AboutYourAbsenceText';
import { validateTextArea } from 'src/packages/cli/utils/validationUtils';
import { useFormState } from 'informed';
const OtherSurgery = () => {
    const { absenceData } = useCliDataContext();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const otherInitialValue = values.absenceData?.surgeryIndicator === 'Y' ? absenceData?.surgeryOther : absenceData?.injuryOther;
    const illnessFieldAlignment = (values.absenceData?.claimantCondition === 'S' && values.absenceData?.surgeryIndicator === 'N' || values.absenceData?.surgeryIndicator === 'U');
    const injuryFieldAlignment = (values.absenceData?.claimantCondition === 'I' && values.absenceData?.surgeryIndicator === 'U');
    return (<>
            <Row className='mb-3'>
                <Col data-testid='otherSurgeryCol' offset={`${illnessFieldAlignment && !injuryFieldAlignment ? 'md-6' : ''}`} md={6}>
                    <TextInput field={values.absenceData?.surgeryIndicator === 'Y' ? 'surgeryOther' : 'injuryOther'} key={values.absenceData?.surgeryIndicator === 'Y' ? 'surgeryOther' : 'injuryOther'} data-testid='otherSurgery' label={values?.absenceData?.surgeryIndicator === 'Y' ? t(formLabels.otherSurgery) : t(formLabels.otherIllnessInjurySurgery)} 
    //@ts-ignore FIXME: type error
    ariaLabeltext={values?.absenceData?.surgeryIndicator === 'Y' ? t(formLabels.otherSurgery) : t(formLabels.otherIllnessInjurySurgery)} validateOnBlur={true} validateOnChange={true} validate={(props) => validateTextArea({ value: props, t, charLimit: 50 })} initialValue={otherInitialValue}/>
                </Col>
            </Row>
        </>);
};
export default OtherSurgery;
