import { Button, Col, Collapse, Form, Heading, Loader, Row, StepperContext, TableData, themes, } from '@digitools/honest-react';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import LCIApi from '../../../../api/LCIApi';
import useAuthentication from '../../../../hooks/useAuthentication';
import useBdmLciConfig from '../../../../hooks/useBdmLciConfig';
import useExperience from '../../../../hooks/useExperience';
import { isObjectEmpty } from '../../../../utils/validationUtils';
import { ADDITIONAL_INFO_LABEL, CALLER_INFO_LABEL, DEPENDENT_INFO_LABEL, EMPLOYEE_INFO_LABEL, EMPLOYEE_POLICY_INFO_LABEL, EMPLOYER_INFO_LABEL, getTitles, POLICY_BENEFICIARY_LABEL, } from '../../constants/LCIConstant';
import { getLabels } from '../../utils/LCIUtils';
import CancelLci from '../cancelLCI/CancelLci';
import Signature from './Signature';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';
import { StyledDiv } from 'src/components/Formatting/StyledComponents';
import ReviewField, { EditReviewField } from 'src/packages/cli/pages/review/ReviewField';
export const StyleColSize50 = styled(TableData) `
  width: 50%;
  word-break: break-all;
`;
export const StyleColSize100 = styled(TableData) `
  width: 100%;
  word-break: break-all;
`;
const ReviewSign = ({ titles, sections, onComplete, sectionTitle, eventType, claimantType, }) => {
    const { customContents } = useBdmLciConfig();
    const { language } = useExperience();
    const getCustomLabels = getLabels(customContents, language);
    const labelEmployee = getCustomLabels.employee || 'Employee';
    const { employerInfoTitle, employeeInfoTitle, policyInfoTitle, dependentInfoTitle, policyBeneficiariesTitle, callerInfoTitle, additionalInfoTitle, } = getTitles(labelEmployee);
    const employerInfoStepNum = titles.findIndex(title => title === employerInfoTitle);
    const employeeInfoStepNum = titles.findIndex(title => title === employeeInfoTitle);
    const employeePolicyInfoStepNum = titles.findIndex(title => title === policyInfoTitle);
    const dependentInfoStepNum = titles.findIndex(title => title === dependentInfoTitle);
    const callerInfoStepNum = titles.findIndex(title => title === callerInfoTitle);
    const additionalInfoStepNum = titles.findIndex(title => title === additionalInfoTitle);
    const policyBeneficiaryStepNum = titles.findIndex(title => title === policyBeneficiariesTitle);
    const reviewData = [];
    const stepper = useContext(StepperContext);
    const { authHeader } = useAuthentication();
    const { trackEvent } = useTealium();
    const handleBack = () => {
        stepper.back();
    };
    const ConstructReviewTable = (sectionObj, labelList, id) => {
        return Object.entries(sectionObj)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => {
            const isSsn = key === 'ssn' && (id === 'employeeInfo' || id === 'dependentInfo');
            const currencyFields = [
                'earnings',
                'retirementAmount',
                'basicAmount',
                'basicADandDAmount',
                'optionalAmount',
                'optionalADandDAmount',
                'basicDependentAmount',
                'basicADandDDependentAmount',
                'optionalDependentAmount',
                'optionalADandDDependentAmount',
            ];
            const isCurrency = id === 'employeePolicyInfo' && currencyFields.lastIndexOf(key);
            let propValue = value;
            if (isCurrency >= 0) {
                propValue = propValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            }
            const dataPrivate = key === 'firstName' || key === 'lastName';
            return {
                label: labelList[key],
                value: propValue,
                isSsn: isSsn,
                isDataPrivate: dataPrivate
            };
        });
    };
    const EmployerInfo = ConstructReviewTable(sections.employerInfo, EMPLOYER_INFO_LABEL, 'employerInfo');
    const employerInfoObject = {
        title: employerInfoTitle,
        data: EmployerInfo,
        stepNum: employerInfoStepNum,
    };
    reviewData.push(employerInfoObject);
    const EmployeeInfo = ConstructReviewTable(sections.employeeInfo, EMPLOYEE_INFO_LABEL(labelEmployee), 'employeeInfo');
    const employeeInfoObject = {
        title: employeeInfoTitle,
        data: EmployeeInfo,
        stepNum: employeeInfoStepNum,
    };
    reviewData.push(employeeInfoObject);
    const EmployeePolicyInfo = ConstructReviewTable(sections.policyInformation, EMPLOYEE_POLICY_INFO_LABEL(labelEmployee), 'employeePolicyInfo');
    const employeePolicyInfoObject = {
        title: policyInfoTitle,
        data: EmployeePolicyInfo,
        stepNum: employeePolicyInfoStepNum,
    };
    reviewData.push(employeePolicyInfoObject);
    if (policyBeneficiaryStepNum > 0 &&
        (sections.policyBeneficiaries.primary.length || sections.policyBeneficiaries.contingent.length)) {
        const PolicyBeneficiary = Object.entries({
            primary: sections.policyBeneficiaries.primary,
            contingent: sections.policyBeneficiaries.contingent,
        }).map(([key, value]) => {
            const beneficiaryList = value || [];
            const excludedKeys = (key) => {
                const hideList = ['id', 'beneficiaryCategory'];
                return hideList.indexOf(key) < 0 ? true : false;
            };
            return beneficiaryList.map((beneficiary, index) => {
                return (<Collapse className={index === 0 && key === 'primary' ? '' : 'mt-3'} key={`${key === 'primary' ? 'Primary' : 'Contingent'} Beneficiary ${index + 1}`} theme={themes.lincoln} title={`${key === 'primary' ? 'Primary' : 'Contingent'} Beneficiary ${index + 1}`}>
              <Row>
                {Object.entries(beneficiary)
                        .filter(([key, value]) => excludedKeys(key))
                        .map(([benKey, benValue]) => {
                        const dataPrivate = benKey === 'firstName' || benKey === 'lastName' || benKey === 'ssnOrTaxID';
                        const isAllocation = 'allocation' === benKey;
                        const isEqualShares = isAllocation &&
                            (key === 'primary'
                                ? sections.policyBeneficiaries.primaryEqual
                                : sections.policyBeneficiaries.contingentEqual);
                        return (<Col lg={6} md={6} sm={6} xs={6} data-testid={`${POLICY_BENEFICIARY_LABEL[benKey]}TestId`}>
                                <ReviewField data-private={dataPrivate} label={POLICY_BENEFICIARY_LABEL[benKey]} value={isEqualShares ? 'EQUAL' : benValue}/>
                              </Col>);
                    })}
              </Row>
            </Collapse>);
            });
        });
        const policyBeneficiaryObject = {
            title: policyBeneficiariesTitle,
            data: [...(PolicyBeneficiary[0] || []), ...(PolicyBeneficiary[1] || [])],
            stepNum: policyBeneficiaryStepNum,
        };
        reviewData.push(policyBeneficiaryObject);
    }
    if (dependentInfoStepNum > 0 && Object.entries(sections.dependentInfo).length > 0) {
        const DependentInfo = ConstructReviewTable(sections.dependentInfo, DEPENDENT_INFO_LABEL(labelEmployee), 'dependentInfo');
        const dependentInfoObject = {
            title: dependentInfoTitle,
            data: DependentInfo,
            stepNum: dependentInfoStepNum,
        };
        reviewData.push(dependentInfoObject);
    }
    if (callerInfoStepNum > 0 && Object.entries(sections.callerInfo).length > 0) {
        const CallerInfo = ConstructReviewTable(sections.callerInfo, CALLER_INFO_LABEL, 'callerInfo');
        const callerInfoObject = {
            title: callerInfoTitle,
            data: CallerInfo,
            stepNum: callerInfoStepNum,
        };
        reviewData.push(callerInfoObject);
    }
    const additionalInfoList = Object.entries(sections.additionalInfo);
    if (additionalInfoList.length > 0) {
        const AdditionalInfo = additionalInfoList.map(([key, value]) => {
            const isNotes = key === 'notes';
            return {
                label: ADDITIONAL_INFO_LABEL[key],
                value: isNotes ? value : <ul>{value.map((doc) => <li>{doc.file.name}</li>)}</ul>
            };
        });
        const additionalInfoObject = {
            title: additionalInfoTitle,
            data: AdditionalInfo,
            stepNum: additionalInfoStepNum,
        };
        reviewData.push(additionalInfoObject);
    }
    const ReviewInfo = reviewData.map((key, index) => {
        const formatSsn = (ssn) => {
            return "xxx-xx-" + ssn.substring(5);
        };
        return (key.title === 'Policy Beneficiaries' ? (<Collapse className='mb-3' headerRightDisplay={<EditReviewField data-testid={`${key.title}TestId`} onClick={() => { stepper.edit(key.stepNum); }}/>} isOpen={false} theme={themes.lincoln} type='alternate' title={key.title}>
            <>{key.data.map((item) => item)}</>
          </Collapse>) :
            (<Collapse className='mb-3' headerRightDisplay={<EditReviewField data-testid={`${key.title}TestId`} onClick={() => { stepper.edit(key.stepNum); }}/>} isOpen={true} theme={themes.lincoln} type='alternate' title={key.title}>
        <Row>
          {key.data?.map((item) => (item.value === ' ' ? <></> : (<Col lg={6} md={6} sm={6} xs={6} data-testid={`${item.label}TestId`}>
                  <ReviewField data-private={item.isDataPrivate} label={item.label} value={item.isSsn ? formatSsn(item.value) : item.value}/>
                </Col>)))}
        </Row>
        </Collapse>));
    });
    const [showSpinner, setShowSpinner] = useState(false);
    function trimWhiteSpace(dataObj) {
        { /* @ts-ignore */ }
        const keys = Object.keys(dataObj);
        for (const key of keys) {
            // @ts-ignore
            const val = dataObj[key];
            if (typeof val === 'string') {
                // @ts-ignore
                dataObj[key] = val.trim();
            }
        }
        return dataObj;
    }
    const handleSubmit = async (signatureInfo) => {
        setShowSpinner(true);
        const claimData = {
            employerInfo: trimWhiteSpace(sections.employerInfo),
            employeeInfo: trimWhiteSpace(sections.employeeInfo),
            policyInfo: trimWhiteSpace(sections.policyInformation),
            dependentInfo: isObjectEmpty(sections.dependentInfo)
                ? undefined
                : trimWhiteSpace(sections.dependentInfo),
            callerInfo: isObjectEmpty(sections.callerInfo) ? undefined : trimWhiteSpace(sections.callerInfo),
            additionalInfo: {
                notes: sections.additionalInfo.notes || '',
                documents: (sections.additionalInfo.documents || []).map((doc) => ({
                    id: doc.docId,
                    name: doc.file.name,
                })),
            },
            signatureInfo: trimWhiteSpace(signatureInfo),
            claimFlow: sectionTitle,
            dateOfSubmission: '',
            claimId: '',
            policyBeneficiaries: sections.policyBeneficiaries,
            eventType,
            claimantType,
            title: labelEmployee,
        };
        const signature = {
            ...signatureInfo,
        };
        if (!isObjectEmpty(sections.dependentInfo)) {
            claimData.policyInfo.dateOfDeath = sections.dependentInfo.dateOfDeath;
        }
        try {
            const response = await LCIApi().submitClaim(claimData, authHeader());
            onComplete({
                submissionId: response.data.submissionId,
                dateOfSubmission: response.data.dateOfSubmission,
                signatureInfo: signature,
            });
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
                event_name: 'continue',
                event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
                event_version: 'review & sign',
                event_status: TEALIUM_EVENT.EVENT_STATUS.COMPLETE,
            });
            setShowSpinner(false);
        }
        catch (error) {
            setShowSpinner(false);
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.ERROR,
                event_name: error?.response?.data[0]?.message,
                event_type: TEALIUM_EVENT.EVENT_TYPE.LIFE_CLAIM_INTAKE,
                event_version: 'review & sign',
            });
        }
        finally {
            setShowSpinner(false);
        }
    };
    const eventStatus = (claimantType, eventType) => {
        if (eventType === 'DEATH') {
            return 'step 8';
        }
        else if (((eventType === 'ACCELERATED_DEATH' || eventType === 'DISMEMBERMENT') && claimantType !== 'DEPENDENT') || eventType === 'DISABILITY') {
            return 'step 6';
        }
        else {
            return 'step 7';
        }
    };
    return (<Loader viewportCentered={true} id='ee-info-spinner' isShown={showSpinner}>
      <Form onSubmit={handleSubmit}>
        <Heading elemType='h2' color='primary'>
          Review and Sign
        </Heading>
        <p>Please review the information below and sign once it is complete</p>
        {ReviewInfo}
        <Signature />
        <StyledDiv>
          <Button buttonType={'secondary'} id='back_review_sign' type='button' onClick={handleBack}>
            Back
          </Button>
          <CancelLci step={eventStatus(claimantType, eventType)} pageTitle={'review & sign'} sectionInfo={'Review Sign'}/>
          <Button id='submitForm' buttonType='primary' type='submit'>
            Submit
          </Button>
        </StyledDiv>
      </Form>
    </Loader>);
};
export default ReviewSign;
