import React, { useState } from 'react';
import { Form } from 'informed';
import { Box, Button, Col, Collapse, DateSelect, InputMessage, Row, SelectInput, themes, } from '@digitools/honest-react';
import { isAfter, isBefore, parse } from 'date-fns';
const CorrespondenceFilter = (props) => {
    const [formApi, setFormApi] = useState();
    if (!props.initialData) {
        return null;
    }
    const validateDateFilter = (values) => {
        if (values.fromDate && !values.throughDate) {
            formApi.setError('throughDate', 'Through date required');
        }
        else if (values.throughDate && !values.fromDate) {
            formApi.setError('fromDate', 'From date required');
        }
        else if (values.fromDate && values.throughDate) {
            const fromDate = parse(values.fromDate, 'P', new Date());
            const throughDate = parse(values.throughDate, 'P', new Date());
            if (isAfter(fromDate, throughDate)) {
                formApi.setError('fromDate', 'From Date must come before Through Date');
            }
        }
    };
    const documentTypes = () => {
        if (!props.initialData) {
            return [];
        }
        else {
            // @ts-ignore
            const types = new Set(props.initialData.map(correspondenceDoc => correspondenceDoc.documentType));
            // convert set to array to use .map()
            return [...types].map(type => {
                return {
                    label: type,
                    value: type,
                };
            });
        }
    };
    const filterCorrespondence = (values) => {
        let filteredData = props.initialData;
        if (values && values.documentType) {
            filteredData = filteredData.filter((data) => data.documentType === values.documentType);
        }
        if (values && values.fromDate && values.throughDate) {
            const fromDate = parse(values.fromDate, 'P', new Date());
            const throughDate = parse(values.throughDate, 'P', new Date());
            filteredData = filteredData.filter((data) => {
                const date = data.date ? data.date : data.documentDate;
                const correspondenceDate = parse(date, 'P', new Date());
                return isBefore(fromDate, correspondenceDate) && isBefore(correspondenceDate, throughDate);
            });
        }
        props.setFilteredData(filteredData);
    };
    const resetFilteredData = () => {
        formApi.reset();
        props.setFilteredData(undefined);
    };
    return (<Box style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
      <Collapse title={'Filter'} data-testid={'leave-details-filter-correspondence-collapse'} type={'alternate'} theme={themes.lincoln}>
        <Form validate={validateDateFilter} onSubmit={filterCorrespondence} getApi={setFormApi}>
          <Row>
            <Col md={6} sm={12}>
              <DateSelect data-testid={'leave-details-filter-from-date'} field={'fromDate'} ariaLabelText={'From Date'} label={'Document Date - From Date'} theme={themes.lincoln}/>
            </Col>
            <Col md={6} sm={12}>
              <DateSelect data-testid={'leave-details-filter-through-date'} field={'throughDate'} ariaLabelText={'Through Date'} label={'Document Date - Through Date'} theme={themes.lincoln}/>
            </Col>
          </Row>
          {formApi && formApi.getState().error && (<InputMessage error={true} data-testid={'leave-details-correspondence-filter-error'}>
              {formApi.getState().error}
            </InputMessage>)}
          <Row>
            <Col md={4} sm={12}>
              <SelectInput field={'documentType'} label={'Document Type'} placeholderText={'Document Type...'} options={documentTypes()} data-testid={'leave-details-filter-correspondence-document-type-dropdown'} theme={themes.lincoln} style={{ marginBottom: 0 }}/>
            </Col>
            <Col md={8} sm={12}>
              <div style={{ marginLeft: 'auto', justifyContent: 'right', display: 'flex' }}>
                <Button type={'button'} buttonType={'secondary'} onClick={resetFilteredData} style={{ marginLeft: 'auto' }} theme={themes.lincoln}>
                  Clear Filter
                </Button>
                <Button type={'submit'} buttonType={'primary'} data-testid={'leave-details-correspondence-filter-apply-button'} theme={themes.lincoln}>
                  Apply
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Collapse>
    </Box>);
};
export default CorrespondenceFilter;
