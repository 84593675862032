import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage, useExperience } from 'status/hooks';
import getProductAttributeCode from 'status/utilities/getProductAttributeCode';
import getLeaveStatusDefinition from 'status/utilities/getLeaveStatusDefinition';
import checkDate from 'status/utilities/checkDate';
import LeaveSpecialist from './LeaveSpecialist';
import { encode } from 'status/utilities/hash';
import WatchlistCheckBox from '../../WatchlistCheckBox/WatchlistCheckBox';
import { downloadLeaveAppliedReport } from './downloadReport';
import DocumentUpload from '../../../../../components/documentUpload/DocumentUpload';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { Link } from 'react-router-dom';
import { Info } from '../../EmployeeDetails/EmployeeDetails';
import axios from 'axios';
import FileSaver from 'file-saver';
import Glossary from 'status/components/Glossary/Glossary';
import { Alert, Button, Col, Heading, Icon, Row, Link as HonestLink, Spinner } from '@digitools/honest-react';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import CategorizationDocumentUpload from 'src/components/documentUpload/CategorizationDocumentUpload';
import { getEnvVariable } from '../../../../../utils/envUtils';

const Error = ({ error }) => (
  <Alert type={'error'}>
    {error.response && error.response.status === 404 ? (
      'No leave found.'
    ) : (
      <span>
        <strong>Error!</strong> Something went wrong when loading leave details.
      </span>
    )}
  </Alert>
);

const BasicInfo = ({
  params,
  leave,
  leaveDetails,
  empNum,
  prodAttCodes,
  statusDefinitionList,
  leaveReports,
  name,
  updateStatus,
}) => {
  const { language } = useLanguage();
  const { experience } = useExperience();
  const { t } = useTranslation();
  const { trackEvent } = useTealium();

  const print = () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
      event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
      event_name: 'leave time applied report',
      event_version: 'details'
    });
    downloadLeaveAppliedReport(leaveReports, language, prodAttCodes, name, t);
  };

  const status = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Status', leave.lveSttusCde);
  updateStatus({ status: status });
  const type = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Type', leave.lveType);

  const reason = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Reason', leave.lveReas);

  const category = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Category', leave.lveCatg);

  const statusDef = getLeaveStatusDefinition(
    language,
    statusDefinitionList,
    leave.lveType,
    leave.lveSttusCde,
    leave.lveSttusReasCde,
  );

  const expPrefix = experience === 'EE' ? 'employee' : 'employer';

  const associatedClaim = {};

  if (leave && leave.coordClmNum && leave.coordClmNum !== '0' && leaveDetails && leaveDetails.coord_clm_num != 0) {
    associatedClaim.claimNum = leave.coordClmNum;
    associatedClaim.to = `/status/${expPrefix}/${encode(empNum)}/claims/${encode(leave.coordClmNum)}/details`;
    if (params.search) {
      associatedClaim.to = `${associatedClaim.to}?search=${params.search}&searchCrit=${params.searchCrit}`;
    }
    if (params.from) {
      associatedClaim.to = `${associatedClaim.to}?from=${params.from}`;
    }
  }

  return (
    <>
      <Row style={{ marginTop: '1rem' }}>
        <Col lg={6}>
          <Info text={t('statusText')} info={status} />
          <Info text={t('coverageText')} info={'Leave'} />
          {leave.lveCloseDte && <Info text={t('closedDateText')} info={checkDate(leave.lveCloseDte)} />}
          <Info
            text={t('associatedClaimText')}
            info={
              Object.keys(associatedClaim).length !== 0 ? (
                <Link
                  to={associatedClaim.to}
                  onClick={() =>
                    trackEvent({
                      event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                      event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                      event_name: associatedClaim.claimNum,
                      event_results: 'claim',
                      event_version: 'details',
                    })
                  }>
                  {associatedClaim.claimNum}
                </Link>
              ) : null
            }
          />
        </Col>
        <Col lg={6}>
          <Info text={t('leaveTypeText')} info={type} />
          <Info text={t('leaveReasonText')} info={reason} />
          <Info text={t('leaveCategoryText')} info={category} />
          {leaveReports && leaveReports.length > 0 && (
            <HonestLink onClick={print}>{t('leaveAppliedReportText')}</HonestLink>
          )}
        </Col>
        <Col lg={12}>
          <Info text={t('statusReasonText')} info={statusDef} />
        </Col>
      </Row>
    </>
  );
};

const LeaveInfo = ({
  leave,
  params,
  error,
  empNum,
  prodAttCodes,
  statusDefinitionList,
  leaveDetails,
  leaveId,
  leaveReports,
  name,
  updateStatusCallback,
}) => {
  const { t } = useTranslation();
  const { experience } = useExperience();
  const { authHeader } = useAuthentication();
  const { trackEvent } = useTealium();
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  if (error) {
    return <Error error={error} />;
  }

  const getColumnSize = size => {
    if (experience === 'ER') {
      switch (size) {
        case 'sm':
          return 7;
        case 'md':
          return 8;
        case 'lg':
          return 9;
      }
    }
    return 12;
  };

  const updateStatus = status => {
    if (updateStatusCallback) {
      updateStatusCallback(status);
    }
  };

  const downloadLeaveDetails = async () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
      event_name: 'download leave details',
      event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
      event_version: 'details'
    });
    try {
      setIsDownloading(true);
      const response = await axios.get(`/status-service/report/${empNum}/${leaveId}/leaveSummary`, {
        responseType: 'blob',
        headers: { Authorization: authHeader() },
      });
      const reportName = `${leaveId}-LeaveDetails.pdf`;
      const blob = new Blob([response.data]);
      setIsDownloadError(false);
      setIsDownloading(false);
      FileSaver.saveAs(blob, reportName);
    } catch (error) {
      setIsDownloadError(true);
      setIsDownloading(false);
    }
  };

  const DownloadLeaveDetailsError = () => (
    <Alert
      data-testid={'download-leave-details-alert'}
      type={'error'}
      closeable={true}
      onClose={() => setIsDownloadError(false)}>
      <span>
        <strong>Error!</strong> There was a problem downloading the leave details. Please try again later.
      </span>
    </Alert>
  );

  return (
    <Row style={{ marginBottom: '1rem', marginTop: '1.5rem' }}>
      <Col md={8} xs={12}>
        <div style={{ display: 'inline-block' }}>
          <Heading elemType={'h3'} color={'primary'} style={{ marginRight: '1rem' }}>
            {`${t('leaveDetailsText')} #${leave.lveNum}`}
          </Heading>
        </div>
        {experience === 'ER' && (
          <div style={{ display: 'inline-block' }}>
            <WatchlistCheckBox eventId={leaveId} eventType={'LEAVE'} />
          </div>
        )}
        <BasicInfo
          leaveReports={leaveReports}
          leave={leave}
          name={name}
          leaveDetails={leaveDetails}
          params={params}
          empNum={empNum}
          statusDefinitionList={statusDefinitionList}
          prodAttCodes={prodAttCodes}
          updateStatus={updateStatus}
        />
        <Row>
          {(getEnvVariable('enableCategorizationForS1') && experience === 'EE') ?(
            <Col xl={12} xs={12} style={{ marginBottom: '1rem'}} >
              <CategorizationDocumentUpload 
                eventNum={leaveId}
                productName={'LEAVE'}
                displayLabel={true}
                renderAsLink={false}
                key={'CategorizationDocumentUploadLeave' + leaveId}
                data-testid="CategorizationDocumentUploadLeave"
                page = 'details'
              />
            </Col>
          ) : (
          <Col xl={6} xs={12} style={{ marginBottom: '1rem' }}>
            <DocumentUpload eventNum={leaveId} productName={'LEAVE'} displayLabel={true} />
          </Col>
          )}
          {experience === 'ER' && (
            <Col xl={6} xs={12} style={{ marginBottom: '1rem' }}>
              <Button
                data-testid={'downloadLeaveDetails'}
                buttonType={'secondary'}
                size={'medium'}
                type={'button'}
                onClick={downloadLeaveDetails}
                disabled={isDownloading}
                aria-label='Download Leave Details'
                style={{ textTransform: 'inherit' }}>
                {isDownloading ? (
                  <Spinner
                    id='leave-info-spinner'
                    size='small'
                    style={{ float: 'left', margin: '0px', marginTop: '-.25rem', marginRight: '.5rem' }}
                  />
                ) : (
                  <Icon name='download' color={'secondary'} style={{ paddingRight: '.25rem' }} />
                )}
                Download Leave Details
              </Button>
            </Col>
          )}
        </Row>
        {isDownloadError && <DownloadLeaveDetailsError />}
      </Col>
      <Col md={4} xs={12}>
        <LeaveSpecialist
          specialistName={leaveDetails && leaveDetails.lve_analy_asgn_name}
          offNum={leaveDetails && leaveDetails.usr_off_num}
          leaveNum={leaveId}
          employeeName={name}
        />
        <Glossary />
      </Col>
    </Row>
  );
};

export default LeaveInfo;
