import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Modal, themes } from '@digitools/honest-react';
import { SaveForLaterText } from 'src/packages/eoi/i18n/SaveForLaterText';
import useTranslate from 'src/hooks/useTranslate';
import { CliText } from '../constants/CliText';
import useCliDataContext from '../hooks/useCliDataContext';
import { useNavigate } from 'react-router-dom';
const RouterPrompt = () => {
    const navigate = useNavigate();
    const { t } = useTranslate();
    const { isSflModalOpen } = useCliDataContext();
    const [currentPath, setCurrentPath] = useState('');
    const unblockRef = useRef();
    const setIsOpenRef = useRef(() => { });
    const setControl = (setIsOpen) => {
        setIsOpenRef.current = setIsOpen;
    };
    const showModal = (isShown) => {
        if (setIsOpenRef.current) {
            setIsOpenRef.current(isShown);
        }
    };
    const onCancel = () => {
        showModal(false);
    };
    const exceptionURLs = ['/cli/employee/confirmationNew', '/cli/employer/confirmationNew'];
    useEffect(() => {
        // TODO IM COMEBACK
        // unblockRef.current = navigate.block((location: Location) => {
        //     if(!isSflModalOpen) {
        //         setCurrentPath(location.pathname);
        //         showModal(true);
        //         return false;
        //     }
        // });
        // return (() => {
        //     unblockRef.current && unblockRef.current();
        // })
    }, [isSflModalOpen]);
    const handleConfirm = () => {
        unblockRef.current && unblockRef.current();
        showModal(false);
        navigate(currentPath);
    };
    return (<Modal getControl={setControl} footerContentPosition={'flex-start'} theme={themes.lincoln} headerText={t(CliText.leaveModal)} footerPrimaryRenderButton={() => <Button type={'button'} buttonType={'primary'} theme={themes.lincoln} onClick={handleConfirm}>Ok</Button>} footerSecondaryRenderButton={() => <Button type={'button'} buttonType={'text'} theme={themes.lincoln} onClick={onCancel}>{t(SaveForLaterText.returnToApplication)}</Button>} data-testid={'router-prompt'}>
            <Alert type={'warning'}>
                <p>
                    <strong>
                    {t(CliText.leaveModalHeader)}
                    </strong>
                </p>
                <p className={`mt-1`}>
                    {t(CliText.leaveModalHeaderSuccess)}
                </p>
            </Alert>
        </Modal>);
};
export default RouterPrompt;
