import { Row, Col } from '@digitools/honest-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Payment.scss';

const DataRow = ({ label, value }) => {
  return (
    <Row className={'mr-0'} style={{ marginBottom: '.5rem', marginTop: '0rem' }}>
      <Col>{label}</Col>
      <Col style={{ textAlign: 'right' }}>{value}</Col>
    </Row>
  );
};

const CheckDetailSelectValue = ({ type, value, activeTab, setActiveTab }) => {
  return (
    <a
      href='#'
      data-testid={type}
      className={type === activeTab ? styles.isActiveLink : ''}
      onClick={e => {
        e.preventDefault();
        setActiveTab(type);
      }}>
      {value}
    </a>
  );
};

const CheckDetails = ({ claimPayment, setActiveTab, activeTab }) => {
  const { t } = useTranslation();
  return (
    <>
      <DataRow label={<strong>Claim Details</strong>} value={''} />
      <DataRow label={t('benefitGrossAmountText')} value={claimPayment.benefitGross} />
      <DataRow
        label={t('checkTotalOffsetAmountText')}
        value={
          <CheckDetailSelectValue
            type={'offset'}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            value={claimPayment.totalOffsetDeductions ? claimPayment.totalOffsetDeductions : '$0'}
          />
        }
      />
      <DataRow label={t('checkAdjustedBenefitGrossAmountText')} value={claimPayment.adjustedBenefitGross} />
      <DataRow label={t('colaAmountText')} value={claimPayment.cola} />
      <DataRow
        label={t('checkTaxWithheldAmountText')}
        value={
          <CheckDetailSelectValue
            type={'taxes'}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            value={claimPayment.totalTaxWithheld}
          />
        }
      />
      <DataRow
        label={t('checkTotalDeductionAmountText')}
        value={
          <CheckDetailSelectValue
            type={'other'}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            value={claimPayment.totalOtherDeductions ? claimPayment.totalOtherDeductions : '$0'}
          />
        }
      />
      <DataRow label={t('benefitNetAmountText')} value={claimPayment.benefitNet} />
    </>
  );
};

export default CheckDetails;
