import React from 'react';
import { Col, Heading, Row, themes } from '@digitools/honest-react';
import dashedDateFormatToString from '../../../utilities/dashedDateFormatToString';
import { useExperience, useLanguage } from 'status/hooks';
import { useTranslation } from 'react-i18next';
import DocumentUpload from '../../../../../components/documentUpload/DocumentUpload';
import WatchlistCheckBox from '../../WatchlistCheckBox/WatchlistCheckBox';
import { ExperienceCode } from '../../../../../types/Experience';
import CaseManager from '../ClaimDetails/CaseManager';
const AccommodationInfo = ({ detailsData, employeeName }) => {
    const { t } = useTranslation();
    const { language } = useLanguage();
    const { experience } = useExperience();
    const status = language.toUpperCase() === 'EN'
        ? detailsData.asEventDetail.eventStatusValue_EN
        : detailsData.asEventDetail.eventStatusValue_ES;
    return (<>
      <Row>
        <Col md={8} xs={12}>
          <div style={{ display: 'inline-block' }}>
            <Heading elemType={'h2'} style={{ marginRight: '1rem' }} color={'primary'}>
              <strong>{t('accommodationEventHeaderText') + ' #' + detailsData.asEventDetail.evtNum}</strong>
            </Heading>
          </div>
          {experience === ExperienceCode.EMPLOYER && (<div style={{ display: 'inline-block' }}>
              <WatchlistCheckBox eventId={detailsData.asEventDetail.evtNum} eventType={'AS'}/>
            </div>)}
          <Row style={{ marginTop: '1rem' }}>
            <Col>
              <label htmlFor='eventStatus' style={{ marginRight: '3rem' }}>
                <strong>{t('eventStatus')}</strong>
              </label>
            </Col>
            <Col>
              <span id='eventStatus'>{status}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{t('jobAccommodation')}</strong>
              <br />
              <label htmlFor='eventReceivedDate' style={{ marginRight: '3rem' }}>
                <strong>{t('eventReceivedDate')}</strong>
              </label>
            </Col>
            <Col>
              <br />
              <span id='eventReceivedDate'>{dashedDateFormatToString(detailsData.asEventDetail.evtRecdDte)}</span>
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <DocumentUpload eventNum={detailsData.asEventDetail.evtNum} productName={'AS'} displayLabel={true} key={detailsData.asEventDetail.evtNum} theme={themes.lincoln}/>
          </Row>
        </Col>
        <Col md={4} xs={12}>
          {detailsData && (<CaseManager claimNum={detailsData.asEventDetail.evtNum} employeeName={employeeName} offNum={detailsData.asEventDetail.offNum} caseManager={detailsData.caseManager}/>)}
        </Col>
      </Row>
    </>);
};
export default AccommodationInfo;
