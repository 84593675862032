const leaveStatusReason = leave => {
  const code = leave.requestTimeReasonCode && leave.requestTimeReasonCode.trim();
  const status = leave.status && leave.status.trim();

  const mappings = [
    { code: 'DE', leaveStatusReason: 'Leave Denied' },
    { code: 'BB', leaveStatusReason: 'Prior to Leave Begin Date' },
    { code: 'AE', leaveStatusReason: 'After Leave End Date' },
    { code: 'OC', leaveStatusReason: 'Overlapping Continuous' },
    { code: 'PE', leaveStatusReason: 'Pending Frequency Evaluation' },
    { code: 'AT', leaveStatusReason: 'Pending Eligibility Recheck' },
    { code: 'NE', leaveStatusReason: 'Not Entitled' },
    { code: 'ET', leaveStatusReason: 'Employment Terminated' },
    { code: 'ED', leaveStatusReason: 'Death' },
  ];

  const foundStatusReason = mappings.find(element => element.code === code);

  if (foundStatusReason) return foundStatusReason.leaveStatusReason;
  else if (status == 'NE') {
    return 'Not Entitled';
  }

  return '';
};

export default leaveStatusReason;
