import { SelectInput } from '@digitools/honest-react';
import React from 'react';
import { CategoryCol, StyledSerial } from './CategorizationDocumentUpload';
import UploadButton from './UploadButton';
const SelectCategory = ({ productType, uploading, documentCategory, uploadedFiles, eventNum, onUpload, setDocumentCategory, }) => {
    let showCategory = uploadedFiles?.length < 10 && !uploading && productType.length !== 1;
    return (<>
      {showCategory && (<CategoryCol md={5} xs={10}>
          <StyledSerial>{uploadedFiles.length + 1}</StyledSerial>
          <SelectInput field={'documentCategory'} options={productType} data-testid={'documentCategory'} validateOnBlur={true} onChange={(e) => setDocumentCategory(e.target.value)} placeholderText={'Select document type'} initialValue={''}/>
        </CategoryCol>)}

      {(documentCategory || productType.length == 1) && !uploading && (<UploadButton onUpload={onUpload} eventNum={eventNum}/>)}
    </>);
};
export default SelectCategory;
