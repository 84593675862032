import React from 'react';
import { useFormState, Scope } from 'informed';
import useTranslate from '../../../../hooks/useTranslate';
import { themes, TextInput, Checkbox } from '@digitools/honest-react';
import { GraySpacerDiv } from '../../../../components/Formatting/StyledComponents';
import AdditionalQuestion from './AdditionalQuestion';
import useEoiContext from '../../hooks/useEoiContext';
const findMatchingReflexiveAnswer = (reflexiveAnswers, questionCode, applicant, dependentId) => {
    let applicantResponses;
    if (applicant === 'employee' || applicant === 'spouse') {
        applicantResponses = reflexiveAnswers[applicant];
    }
    else if (reflexiveAnswers.dependents) {
        applicantResponses = reflexiveAnswers.dependents.filter((appResponses) => dependentId === appResponses.dependentId).shift();
    }
    if (applicantResponses && applicantResponses.responses) {
        const matchingResponse = applicantResponses.responses.filter((response) => response.questionCode === questionCode).shift();
        return matchingResponse;
    }
    return undefined;
};
const findMatchingAdditionalQuestionResponse = (reflexiveResponse, questionCode) => {
    if (reflexiveResponse && reflexiveResponse.additionalQuestions) {
        const matchingResponse = reflexiveResponse.additionalQuestions.filter((additionalResponse) => additionalResponse.questionCode === questionCode).shift();
        return matchingResponse;
    }
    return undefined;
};
const ReflexiveQuestion = ({ originalQuestionCode, reflexiveQuestion, applicant, index, dependentIndex, dependentId, }) => {
    const formState = useFormState();
    const { t } = useTranslate();
    const { interviewAnswers } = useEoiContext();
    // check for a matching response to the question for prefill TODO: (could be made into useEffect with a state variable later)
    const matchingResponse = findMatchingReflexiveAnswer(interviewAnswers.reflexiveAnswers, reflexiveQuestion.questionCode, applicant, dependentId);
    // NOTE: Checkbox doesn't support scopes & overlapping field names
    // need to add unique stuff to Checkbox field to avoid formState issues
    // everything including and past the `-` will be stripped from the field naem on submit
    /**
     * Inspects form state to see if the correct field is checked and returns a boolean
     * HEAVILY DEPENDENT ON FORM SCOPE LAYOUT - ANY CHANGES WILL AFFECT THIS LOGIC
     */
    const isReflexiveQuestionChecked = () => {
        let formStateApplicant;
        let isChecked;
        if (applicant.indexOf('dependent') > -1 && dependentIndex !== undefined) {
            if (formState.values.dependents) {
                // @ts-ignore
                formStateApplicant = formState.values.dependents[dependentIndex];
            }
        }
        else {
            // @ts-ignore
            formStateApplicant = formState.values[applicant];
        }
        if (formStateApplicant) {
            isChecked = (formStateApplicant['responses'] && formStateApplicant['responses'][index] && formStateApplicant['responses'][index][`value-${applicant}${dependentId ? dependentId : ''}${reflexiveQuestion.questionCode}`]);
        }
        return isChecked;
    };
    return (<>
      {/* @ts-ignore */}
      <Scope scope={`responses[${index}]`}>
        <Checkbox key={reflexiveQuestion.questionCode} field={`value-${applicant}${dependentId ? dependentId : ''}${reflexiveQuestion.questionCode}`} label={t(reflexiveQuestion.questionText)} theme={themes.lincoln} initialValue={matchingResponse ? matchingResponse.value[0] === 'true' : undefined} // gross thing needed because checkbox value gets mapped into array of string...
    />
        {reflexiveQuestion.questionNote && (reflexiveQuestion.questionNote.english || reflexiveQuestion.questionNote) && <div style={{ marginLeft: '1.5rem' }}><em>{t(reflexiveQuestion.questionNote)}</em></div>}
        <br />
        <div style={{ display: 'none' }}>
          <TextInput hidden={true} hideLabel={true} label={''} field={'questionCode'} initialValue={reflexiveQuestion.questionCode}/>
          <TextInput hidden={true} hideLabel={true} label={''} field={'originalQuestionCode'} initialValue={originalQuestionCode}/>
        </div>
        {
        // @ts-ignore Super long condition - only renders stuff if there are additional questions to render and the above Checkbox is checked
        // reflexiveQuestion.additionalQuestions && reflexiveQuestion.additionalQuestions.length > 0 && formState && formState.values && formState.values[applicant] && formState.values[applicant]['responses'] && formState.values[applicant]['responses'][index] && formState.values[applicant]['responses'][index][`value-${applicant}${reflexiveQuestion.questionCode}`] &&
        reflexiveQuestion.additionalQuestions && reflexiveQuestion.additionalQuestions.length > 0 && isReflexiveQuestionChecked() &&
            <div style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
            <GraySpacerDiv style={{ height: '1rem', marginBottom: '1rem' }}/>
            {reflexiveQuestion.additionalQuestions.map((additionalQuestion, additionalQuestionIndex) => {
                    return (<>
                  {/* @ts-ignore */}
                  <Scope key={additionalQuestionIndex} scope={`additionalQuestions[${additionalQuestionIndex}]`}>
                    <AdditionalQuestion additionalQuestion={additionalQuestion} applicant={`${applicant}${dependentId ? dependentId : ''}`} matchingResponse={findMatchingAdditionalQuestionResponse(matchingResponse, additionalQuestion.questionCode)}/>
                  </Scope>
                </>);
                })}
            {/* ts-ignore */}
            {reflexiveQuestion.questionNote && (reflexiveQuestion.questionNote.english || reflexiveQuestion.questionNote) && <strong><em>{t(reflexiveQuestion.questionNote)}</em></strong>}
          </div>}
      </Scope>
    </>);
};
export default ReflexiveQuestion;
