import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Heading, Icon, Label, Link, Loader, Modal, Row, SelectInput, } from '@digitools/honest-react';
import { Language } from '../../../../../types/Language';
import { DocumentUploadDisplayTextEs } from '../../../../../components/documentUpload/language/DocumentUploadDisplayTextEs';
import { DocumentUploadDisplayTextEn } from '../../../../../components/documentUpload/language/DocumentUploadDisplayTextEn';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getTraceId } from '../../../../../utils/axiosUtils';
import useExperience from '../../../../../hooks/useExperience';
import useAuthentication from '../../../../../hooks/useAuthentication';
import styles from './RtwDetails.scss';
import { Experience } from 'src/types/Experience';
import { docMeta } from './docCategoryMapper';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
const StyledCol = styled(Col) `
  border: 0.5px solid lightgrey;
  padding: 1.5%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
`;
const StyledSerial = styled(Col) `
  background-color: #5b5d5e;
  color: white;
  display: flex;
  padding: 1em !important;
  margin-right: 1em;
  height: 2.5em;
  align-items: center;
  justify-content: center;
`;
const CategoryCol = styled(Col) `
  justify-content: space-between;
  display: flex;
`;
const StyledLink = styled(Link) `
  font-weight: bold;
`;
const StyledFileInput = styled('input') `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const supportedFiles = ['gif', 'jpg', 'pdf', 'pptx', 'tif', 'tiff', 'xls', 'xlsx', 'doc', 'docx'];
const RtwDocUpload = (props) => {
    const { authHeader, user } = useAuthentication();
    const { language, experience } = useExperience();
    const { t } = useTranslation();
    const { trackEvent } = useTealium();
    const languageFile = language === Language.SPANISH ? DocumentUploadDisplayTextEs : DocumentUploadDisplayTextEn;
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploading, setUploading] = useState(false);
    const [documentCategory, setDocumentCategory] = useState('');
    const [productType, setProductType] = useState([]);
    useEffect(() => {
        if (uploadedFiles.length > 0) {
            props.setUploaded(uploadedFiles);
            props.setUpdateDoc(false);
        }
    }, [uploadedFiles]);
    useEffect(() => {
        if (props.updateDoc) {
            setUploadedFiles([]);
        }
    }, [props.updateDoc]);
    useEffect(() => {
        if (user?.customerInContext?.smallMid) {
            const rtwSmallMid = experience === Experience.EMPLOYEE
                ? docMeta.docsNet.claimsEE.filter(e => e.isRtwEligible)
                : docMeta.docsNet.claimsER.filter(e => e.isRtwEligible);
            props.productName !== 'LEAVE' && setProductType(rtwSmallMid);
        }
        else {
            props.productName === 'STD'
                ? experience === Experience.EMPLOYEE
                    ? setProductType(docMeta.s1.claimsEE.filter(e => e.isRtwEligible))
                    : setProductType(docMeta.s1.claimsER.filter(e => e.isRtwEligible))
                : setProductType(docMeta.s1.leaves.filter(e => e.isRtwEligible));
        }
    }, []);
    const RenderOpenButton = ({ showModal }) => {
        const handleOnClick = () => {
            showModal();
        };
        if (props.isSuccess)
            return <></>;
        return (<Button buttonType={props.displayLabel ? 'secondary' : 'text'} size={'medium'} type={'button'} onClick={handleOnClick} data-testid={'openModal'} key={'openModal' + props.eventNum} aria-label={languageFile.uploadLabel} style={{ padding: '0.5rem 0.5rem ', marginBottom: '3%' }}>
        {t('uploadRtwLabel')}
        <Icon name='upload' color={'secondary'} style={{ paddingLeft: '.25rem' }}/>
      </Button>);
    };
    const FooterPrimaryRenderButton = ({ hideModal }) => {
        const handleClose = () => {
            clearStates();
            hideModal();
        };
        return (<Button buttonType={'secondary'} type={'button'} onClick={handleClose} data-testid={'close'}>
        {languageFile.closeText}
      </Button>);
    };
    const uploadDocument = async (file) => {
        try {
            setUploading(true);
            const response = await axios.post(`/mlp-ui-service/api/documents/upload`, getFormData(file, props.productName.toUpperCase()), {
                headers: {
                    Authorization: authHeader(),
                },
            });
            if (response.status === 200) {
                const docMeta = documentCategory ? getDocMeta(documentCategory) : productType[0];
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                    event_name: 'open',
                    event_version: 'return to work - details',
                    event_results: '1', // Allowing only one file at a time
                    form_result: 'gp_upload,' + docMeta.label + ',1',
                });
                setUploadedFiles(prev => [
                    ...prev,
                    {
                        documentName: file.name,
                        documentId: response.data,
                        label: docMeta.label,
                        documentTypeTitle: docMeta.title,
                        documentType: docMeta.type,
                    },
                ]);
                setErrorMessage('');
                setDocumentCategory('');
                setUploading(false);
            }
            else {
                trackEvent({
                    event_action: TEALIUM_EVENT.EVENT_ACTION.UPLOAD,
                    event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                    event_name: languageFile.failureMessage,
                    event_version: 'upload RTW document',
                });
                setUploading(false);
                setDocumentCategory('');
                setErrorMessage(`${languageFile.failureMessage}`);
            }
        }
        catch (error) {
            setUploading(false);
            setDocumentCategory('');
            setErrorMessage(`${languageFile.failureMessage}${getTraceId(error)}`);
        }
    };
    const onUpload = async (event) => {
        if (event.target.files.length >= 1) {
            const selectedFile = event.target.files[0];
            const fileName = selectedFile.name;
            if (fileName.match(/^[a-zA-Z0-9_.\s-]*$/)) {
                const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
                if (supportedFiles.indexOf(fileType.toLocaleLowerCase()) >= 0) {
                    if (selectedFile.size <= 6100000 && selectedFile.size !== 0) {
                        await uploadDocument(selectedFile);
                        event.target.value = null;
                    }
                    else if (selectedFile.size === 0) {
                        setErrorMessage(languageFile.uploadFileEmpty);
                    }
                    else {
                        setErrorMessage(languageFile.uploadSizeExceeded);
                    }
                }
                else {
                    setErrorMessage(languageFile.uploadFileTypeInvalid);
                }
            }
            else {
                setErrorMessage(languageFile.uploadFilenameInvalid);
            }
        }
    };
    const getDocMeta = (val) => {
        const metaObj = productType.filter((e) => e.label == val);
        return metaObj[0];
    };
    const getProduct = () => {
        if (props.productName === 'LEAVE') {
            return languageFile.leave;
        }
        else {
            return languageFile.claim;
        }
    };
    const getFormData = (file, interviewType) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('interviewType', interviewType);
        formData.append('experience', experience);
        return formData;
    };
    const uploadBtn = () => {
        return (<Col md={4}>
        <Label htmlFor={'file-upload' + props.eventNum}>
          <Icon color={'secondary'} name={'upload'} size={'medium'}/>
          <Link styles={{ marginLeft: '.5rem', fontSize: '16px', fontWeight: 'bold' }} data-testid={'uploadButton'}>
            {languageFile.uploadButtonText}
          </Link>
        </Label>
        <StyledFileInput data-testid={'fileUpload' + props.eventNum} type={'file'} id={'file-upload' + props.eventNum} onChange={onUpload} accept={'.gif, .jpg, .pdf, .pptx, .tif, .tiff, .xls, .xlsx, .doc, .docx'} aria-label='Upload Document'/>
      </Col>);
    };
    const updateCategory = (docDetails, val) => {
        let updatedCategory = uploadedFiles.map(e => {
            let metaDoc = getDocMeta(val);
            if (e.documentId == docDetails.documentId) {
                return { ...e, label: metaDoc.label, documentTypeTitle: metaDoc.title, documentType: metaDoc.type };
            }
            return e;
        });
        setUploadedFiles(updatedCategory);
    };
    const selectCategory = () => {
        let showCategory = uploadedFiles.length < 10 && !uploading && productType.length !== 1;
        return (<>
        {showCategory && (<CategoryCol md={5}>
            <StyledSerial>{uploadedFiles.length + 1}</StyledSerial>
            <SelectInput field={'documentCategory'} options={productType} data-testid={'documentCategory'} validateOnBlur={true} onChange={(e) => setDocumentCategory(e.target.value)} placeholderText={'Select document type'} initialValue={''}/>
          </CategoryCol>)}

        {(documentCategory || productType.length === 1) && !uploading && uploadBtn()}
      </>);
    };
    const renderColumns = () => {
        return (<>
        {uploadedFiles?.map((documentDetail, index) => {
                return (<>
              <CategoryCol md={5}>
                <StyledSerial>{index + 1}</StyledSerial>
                <SelectInput key={documentDetail.documentId} field={'doc-category'} options={productType} data-testid={'doc-category'} initialValue={documentDetail?.label} onChange={(e) => updateCategory(documentDetail, e.target.value)}/>
              </CategoryCol>

              <Col md={7}> {getSelectedDocuments(documentDetail)}</Col>
            </>);
            })}
      </>);
    };
    const getSelectedDocuments = (documentDetail, onPage) => {
        const handleDelete = () => {
            const newDocumentList = uploadedFiles.filter(file => file.documentId !== documentDetail.documentId);
            setUploadedFiles(newDocumentList);
            props.setUploaded(newDocumentList);
        };
        return (<Row key={documentDetail.documentId}>
        <StyledCol md={props.isSuccess ? 12 : 8}>
          <Row data-testid={'submitted-doc'} aria-labelledby={documentDetail.documentName}>
            <Icon styles={{ margin: '2px .5rem 0 1rem' }} name='documents'/> {documentDetail.documentName}
          </Row>
        </StyledCol>
        {!props.isSuccess && (<StyledCol md={onPage ? 4 : 3}>
            <StyledLink data-testid={'delete-doc'} aria-label={languageFile.delete} onClick={handleDelete}>
              <Icon color='secondary' className={styles.deleteIcon} name='trash'/> {languageFile.delete}
            </StyledLink>
          </StyledCol>)}
      </Row>);
    };
    const clearStates = () => {
        setUploading(false);
        setErrorMessage('');
    };
    const renderOnPageColumns = () => {
        return (<Col md={12}>
        {uploadedFiles.map(documentDetail => {
                return getSelectedDocuments(documentDetail, 'onpage');
            })}
      </Col>);
    };
    return (<>
      <Modal data-testid={'uploadModal'} style={{ margin: '2rem', overflowX: 'scroll' }} headerComponent={<Heading elemType={'h1'} style={{ lineHeight: 'normal' }}>
            {`${languageFile.headerText} ${getProduct()} #${props.eventNum}`}
          </Heading>} modalOpenRenderButton={RenderOpenButton} footerPrimaryRenderButton={FooterPrimaryRenderButton} size={'large'} disabledBackdropClick={true}>
        <Loader id='document-upload-spinner' isShown={uploading}>
          <Container>
            {errorMessage && (<Alert type={'error'} data-testid={'error-alert'}>
                <div>
                  {/* REVERTING CHANGE FROM OGS-3128 */}
                  {/* {t('claimErrorMessage')}
            <a href='/customer/home/contacts'>{t('contactUs')}</a> */}
                  {errorMessage}
                </div>
              </Alert>)}
            <Row data-testid={'informationText'} aria-labelledby='informationText'>
              <p style={{
            fontSize: 'larger',
        }}>{`${languageFile.informationalMessage1} ${languageFile.informationalMessagePart}`}</p>
            </Row>
            <Row data-testid={'acceptableDocumentsText'} aria-labelledby='acceptableDocumentsText'>
              {languageFile.informationalMessage2}
            </Row>
            <Row>
              <p style={{ fontWeight: 'bold', fontSize: 'larger' }}>{languageFile.uploadCategory}</p>
            </Row>
            <Row data-testid={'modalColumns'}>{renderColumns()}</Row>
            <Row data-testid={'modalCategory'}>{selectCategory()}</Row>
          </Container>
        </Loader>
      </Modal>
      <Row data-testid={'OnPageColumns'}>{renderOnPageColumns()}</Row>
    </>);
};
export default RtwDocUpload;
