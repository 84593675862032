import { Alert, Box, Heading } from '@digitools/honest-react';
import React from 'react';
import styled from 'styled-components';
import ProveCodeEntryForm from './ProveCodeEntryForm';
import useProveData from './useProveData';
import VerifyForm from './VerifyForm';
import ProveText from './ProveText';
import useTranslate from 'src/hooks/useTranslate';
const StyledBox = styled(Box) `
  max-width: 33rem;
  /* @media(min-width: 1200px) { */
  padding-left: 6rem;
  padding-right: 6rem;
  /* } */
  @media (max-width: 768px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;
/**
 * ProveComponent
 *
 * Highest level styling and routing logic around teh prove forms.
 * handles transferring user between pages
 * handles headings,  and alt display css for displaying within profile page
 * Assumes it's wrapped in the Prove Data Context
 */
const ProveComponent = ({ altDisplay = false }) => {
    const { isVerified, isUserLockedOut } = useProveData();
    const { t } = useTranslate();
    const Wrapper = ({ children }) => {
        if (altDisplay) {
            return <div style={{ width: '100%' }}>{children}</div>;
        }
        else
            return <StyledBox size='large'>{children}</StyledBox>;
    };
    return (<div style={{
            display: 'flex',
            justifyContent: altDisplay ? 'left' : 'center',
            width: '100%',
            paddingBottom: '2rem',
        }}>
        {isUserLockedOut ? (<Wrapper>
                {altDisplay && (<>
                    <Heading elemType='h2' elemStyle='h2' color='primary'>
                        {t(ProveText.verifyHeadingAlt)}
                    </Heading>
                    <hr />
                </>)}
                <Alert type='error' closeable={false}>{`${t(ProveText.proveUserLockout)}`}</Alert>
            </Wrapper>) : (<Wrapper>
                {!altDisplay ? (<Heading elemType='h2' elemStyle='h2' color='primary'>
                        {t(ProveText.verifyHeading)}
                    </Heading>) : (<>
                        <Heading elemType='h2' elemStyle='h2' color='primary'>
                            {t(ProveText.verifyHeadingAlt)}
                        </Heading>
                        <hr />
                        <Heading elemType='h3' elemStyle='h3' color='grayDark'>
                            {t(ProveText.verifyHeading)}
                        </Heading>
                    </>)}
                {!isVerified ? <VerifyForm altDisplay={altDisplay}/> : <ProveCodeEntryForm altDisplay={altDisplay}/>}
            </Wrapper>)}
    </div>);
};
export default ProveComponent;
