import { Box, Icon } from '@digitools/honest-react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
/** TODO: This should become an honest component in the future
 * ActionCardBox - contains styling for top and bottom halfs, top should be given the 'heading' box type prop
 * ActionCardButton/Link/Anchor - wrapping element for whatever kind of box you need.
 * Link - used for any routing behavior
 * Button - used only for executing actions on the same page
 * Container - for any non-actionable display of content
 */
export const ActionCardBox = styled(Box) `
  display: flex;
  justify-content: center;
  font-size: 0.8125rem;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  border-width: 2px;
  min-height: 4rem;
  border-color: ${({ theme }) => theme.palette.primary};
  ${({ type, theme }) => type === 'header' &&
    `
    height:4.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    font-weight: bold;
    background-color: ${type === 'header' ? theme.palette.grayLightest : theme.palette.white};
    font-size: ${theme.fontSize.large};
    & + & {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  `};
  ${({ type, theme }) => type !== 'header'
    ? `
    border-top: none;
  `
    : ''}
`;
// TODO: - make this a class for outer container of a link/button/anchor
export const ActionCardButton = styled('button') `
  height: fit-content;
  border-radius: 8px;
  width: 200px;
  border: none;
  &:hover {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
  }
  &:focus {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
  }
  @media (min-width: 768px) {
    width: 200px;
  }
`;
// TODO: - make this a class for outer container of a link/button/anchor
export const ActionCardContainer = styled('div') `
  border-radius: 8px;
  width: 200px;
  &:hover {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
  }
  &:focus {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
  }
  @media (min-width: 768px) {
    width: 200px;
  }
`;
// TODO: - make this a class for outer container of a link/button/anchor
export const ActionCardLink = styled(Link) `
  display: block;
  width: 200px;
  border-radius: 8px;
  height: fit-content;
  &:hover {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
    text-decoration: none;
  }
  &:focus {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
    text-decoration: none;
  }
  @media (min-width: 768px) {
    width: 200px;
  }
`;
// TODO: - make this a class for outer container of a link/button/anchor
export const ActionCardAnchor = styled('a') `
  display: block;
  width: 200px;
  border-radius: 8px;
  height: fit-content;
  &:hover {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
    text-decoration: none;
  }
  &:focus {
    box-shadow: 0 0 6px ${({ theme }) => theme.palette.primary};
    text-decoration: none;
  }
  @media (min-width: 768px) {
    width: 200px;
  }
`;
export const CardIcon = styled(Icon) `
  font-size: 3rem;
`;
