import { Alert, Heading } from '@digitools/honest-react';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'src/utils/withRouter';
import { TEALIUM_PAGE_VIEW } from '../../../constants/tealiumConstants';
import useTealium from '../../../hooks/useTealium';
import { ExperienceContext } from '../../../providers/ExperienceProvider';
import getDisplayText from '../../../utils/languageDisplay';
import PublicContainer from '../shared/PublicContainer';
import { VerifyEmailDisplayTextEn } from './i18n/VerifyEmailDisplayTextEn';
import { VerifyEmailDisplayTextEs } from './i18n/VerifyEmailDisplayTextEs';
const VerifyEmail = () => {
    const { language } = useContext(ExperienceContext);
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.VERIFY_EMAIL,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.VERIFY_EMAIL.VIEW,
        });
    }, []);
    return (<PublicContainer>
      <Alert data-testid={'alert'} type={'warning'}>
        <b>{getDisplayText('alertStaticText', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}</b>
      </Alert>
      <Heading>{getDisplayText('title', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}</Heading>

      <p>{getDisplayText('bodySection1', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}</p>
      <p>
        {getDisplayText('bodySection2', VerifyEmailDisplayTextEn, VerifyEmailDisplayTextEs, language)}{' '}
        <a href='mailto:donotreply@mail.mylincolnportal.com'>donotreply@mail.mylincolnportal.com</a>.
      </p>
    </PublicContainer>);
};
export default withRouter(VerifyEmail);
