import { Box, Heading, Row, Col, Icon } from '@digitools/honest-react';
import React from 'react';
import useTranslate from 'src/hooks/useTranslate';
import styled from 'styled-components';
import DisplayField from './DisplayField';
import { format } from "date-fns";
import { RIA_TEXT } from '../constants/RiaConstants';
const ColoredBox = styled(Box) `
  /* Colored Side Panel */
  ::before {
    /* Give the pseudo element substance */
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0rem;
    display: block;
    content: "";
    width: 2rem;
    /* Add a left border with a straight edge */
    border-left: 2rem solid ${({ theme }) => theme.palette.primaryDark};
  }
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.grayLighter};
  /* Remove the left border and add blank space where the border should be placed */
  border-left: 0;
  /* Offset to fit the ::before left panel */
  padding: 0;
  padding-left: 2rem;

  /*Transitions */
  -webkit-transition: all 0.20s ease-in-out;
  -moz-transition: all 0.20s ease-in-out;
  -ms-transition: all 0.20s ease-in-out;
  -o-transition: all 0.20s ease-in-out;
  transition: all 0.20s ease-in-out;
  i {
    margin-top: .5rem;
     /*Transitions */
    -webkit-transition: all 0.20s ease-in-out;
    -moz-transition: all 0.20s ease-in-out;
    -ms-transition: all 0.20s ease-in-out;
    -o-transition: all 0.20s ease-in-out;
    transition: all 0.20s ease-in-out;
  }
  /* hover state */
  :hover {
    text-decoration: none !important;
    /* box-shadow: 0 0 5px ${({ theme }) => theme.palette.boxShadow}; */
    box-shadow: 2px 2px 10px ${({ theme }) => theme.palette.grayLighter};
  }
  /* selected state */
  &.selected {
    box-shadow: 0px 0px 10px 1px ${({ theme }) => theme.palette.primaryDark};
  }
  /* children styles */
  .contentPad { // provides padding within border & away from color
    padding: .75rem;
    width: 100%;
    h2 { // heading
      color: ${({ theme }) => theme.palette.primaryDark};
      font-weight: 300;
      font-size: 1.75rem;
    }
    hr {
      margin: 0rem;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }
`;
const StyledDiv = styled('div') `
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
`;
const StyledIcon = styled(Icon) `
  font-size : 4rem;
  color: ${({ theme }) => theme.palette.primaryDark};
`;
const LeaveCard = ({ leave, isSelected, children }) => {
    const { t } = useTranslate();
    const todaysDate = format(new Date(), 'MM/dd/yyyy');
    const leaveTypeIcon = () => {
        if (leave.lveType === 'MAT') {
            return (<StyledIcon data-testid='pregnantIcon' name={'pregnant'}/>);
        }
        else if (leave.lveType === 'OTH' && leave.lveReas !== 'MD') {
            return (<StyledIcon data-testid='otherIcon' name={'other'}/>);
        }
        else if (leave.lveType === 'BND') {
            return (<StyledIcon data-testid='parentalIcon' name={'parental'}/>);
        }
        else if (leave.lveReas === 'MD') {
            return (<StyledIcon data-testid='militaryIcon' name={'military'}/>);
        }
        else if (leave.lveType === 'OWN') {
            return (<StyledIcon data-testid='ownIcon' name={'own-illness'}/>);
        }
        else if (leave.lveType === 'FMC') {
            return (<StyledIcon data-testid='familyIcon' name={'family-care'}/>);
        }
    };
    return (<>
      <ColoredBox role="region" aria-label={`Leave information for leave number ${leave.lveNum}`} data-testid='leave-card' className={isSelected ? 'selected' : undefined} style={{ flexGrow: 1 }}>
        <div className='contentPad'>
          {/* ~ header ~ */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Heading elemType='h2'>
              {t(RIA_TEXT.leave)} {leave.lveNum}
            </Heading>
          </div>
          <hr />

          {/* ~ content ~ */}
          <Row>
            <Col lg={children ? 3 : 4} md={children ? 3 : 4} sm={6}>
              <DisplayField label={t(RIA_TEXT.type)} value={t(leave.lveTypeTranslatable)}/>
              <DisplayField label={t(RIA_TEXT.reason)} value={t(leave.lveReasTranslatable)}/>
            </Col>
            <Col lg={children ? 3 : 4} md={children ? 3 : 4} sm={6}>
              <DisplayField label={t(RIA_TEXT.beginDate)} value={leave.lveBeginDte}/>
              <DisplayField label={t(RIA_TEXT.endDate)} value={leave.lveEndDte}/>
              
              {leave.approvedFrequencyTranslatable &&
            <DisplayField label={t(RIA_TEXT.approvedFrequency)} value={t(leave.approvedFrequencyTranslatable)}/>}
            </Col>
            {children && <Col lg={3} md={3} sm={6}>
              <DisplayField label={t(RIA_TEXT.dateReported)} value={todaysDate}/>
            </Col>}
            {/* {!children && */}
            <Col lg={children ? 3 : 4} md={children ? 3 : 4} sm={6} className='d-none d-md-flex'>
              <StyledDiv data-testid='leaveTypeIcon'>
                {leaveTypeIcon()}
              </StyledDiv>
            </Col>
            {/* } */}
          </Row>
          
          {children}
        </div>
      </ColoredBox>
    </>);
};
export default LeaveCard;
