import React from "react";
export const SURGERY_YES_TYPES = [
    { label: { english: 'Appendectomy', spanish: 'Apendicectomía' }, value: 'Appendectomy' },
    { label: { english: 'Cholecystectomy', spanish: 'Colecistectomía' }, value: 'Cholecystectomy' },
    { label: { english: 'Gastric Bypass Surgery', spanish: 'Cirugia De Banda Gastrica' }, value: 'Gastric Bypass Surgery' },
    { label: { english: 'Hernia', spanish: 'Hernia' }, value: 'Hernia Surgery' },
    { label: { english: 'Hysterectomy', spanish: 'Histerectomía' }, value: 'Hysterectomy' },
    { label: { english: 'Myomectomy', spanish: 'Miomectomía' }, value: 'Myomectomy' },
    { label: { english: 'Other', spanish: 'Otra' }, value: 'Other' },
];
export const SURGERY_NO_INJURY_TYPES = [
    {
        label: {
            english: <>Back, Neck & Spine <small>(e.g., slipped disc, disc displacement, pinched nerve, sciatica, stenosis, spine fracture, spinal disease, back or neck pain)</small></>,
            spanish: <>Espalda, cuello y columna <small>(p. ej., hernia de disco, desplazamiento de disco, pinzamiento de nervio, ciática, estenosis, fractura de columna, enfermedad de columna, dolor de espalda o cuello)</small></>
        },
        value: 'Back, Neck and Spine'
    },
    { label: {
            english: <>Bone & Limbs <small>(e.g., broken bones, amputations, dislocations, sprains)</small></>,
            spanish: <>Huesos y extremidades <small>(p. ej., huesos rotos, amputaciones, dislocaciones, esguinces)</small></>
        },
        value: 'Bones and Limbs'
    },
    {
        label: {
            english: <>Brain & Nerve <small>(e.g., headaches/migraines, nerve pain/disorders, carpal tunnel, stroke, head injury, Parkinson's, Multiple Sclerosis)</small></>,
            spanish: <>Cerebro y nervios <small>(p. ej., dolores de cabeza/migrañas, dolores/trastornos nerviosos, túnel carpiano, derrame cerebral, lesión en la cabeza, Parkinson, esclerosis múltiple)</small></>
        },
        value: 'Brain and Nerve'
    },
    {
        label: {
            english: <>Joints <small>(e.g., joint replacement, arthritis, osteoarthritis, ACL/knee tears, rotator cuff tears, joint pain, gout)</small></>,
            spanish: <>Articulaciones <small>(p. ej., reemplazo de articulación, artritis, osteoartritis, desgarros del ACL/rodilla, desgarros del manguito rotador, dolor articular, gota)</small></>
        },
        value: 'Joints'
    },
    {
        label: {
            english: <>Muscle & Tendons <small>(e.g., muscle tears, trigger finger, fibromyalgia, muscle disorders)</small></>,
            spanish: <>Músculos y tendones <small>(p. ej., desgarros musculares, dedo en gatillo, fibromialgia, trastornos musculares)</small></>
        },
        value: 'Muscle and Tendons'
    },
    {
        label: {
            english: <>Skin Conditions & Rashes <small>(e.g., eczema, lumps/cysts/abscess, shingles, acne, cellulitis, skin infection, burns)</small></>,
            spanish: <>Condiciones de la piel y erupciones <small>(p. ej., eczema, bultos/quistes/abscesos, culebrilla, acné, celulitis, infección de la piel, quemaduras)</small></>
        },
        value: 'Skin Conditions and Rashes'
    },
    {
        label: {
            english: <>Stomach & Digestive <small>(e.g., diverticulitis, intestinal infection, obesity, gallstones, abdominal/pelvic pain, liver disease)</small></>,
            spanish: <>Problemas digestivos y del estómago <small>(p. ej., diverticulitis, infección intestinal, obesidad, cálculos biliares, dolor abdominal/pélvico, enfermedad del hígado)</small></>
        },
        value: 'Stomach and Digestive'
    },
    {
        label: {
            english: <>Other Health Issues <small>(for conditions not listed above)</small></>,
            spanish: <>Otros problemas de salud <small>(para condiciones no listadas arriba)</small></>
        },
        value: 'Other'
    },
];
export const SURGERY_NO_ILLNESS_TYPES = [
    {
        label: {
            english: <>Autoimmune Disorders <small>(e.g., rheumatoid arthritis, lupus)</small></>,
            spanish: <>Trastornos autoinmunes <small>(p. ej., artritis reumatoide, lupus)</small></>,
        },
        value: 'Autoimmune Disorder'
    },
    {
        label: {
            english: <>Back, Neck & Spine <small>(e.g., slipped disc, disc displacement, pinched nerve, sciatica, stenosis, spine fracture, spinal disease, back or neck pain)</small></>,
            spanish: <>Espalda, cuello y columna <small>(p. ej., hernia de disco, desplazamiento de disco, pinzamiento de nervio, ciática, estenosis, fractura de columna, enfermedad de columna, dolor de espalda o cuello)</small></>
        },
        value: 'Back, Neck and Spine'
    },
    {
        label: {
            english: <>Behavioral Health <small>(e.g., anxiety, depression, substance abuse, bipolar disorder, PTSD)</small></>,
            spanish: <>Salud conductual <small>(p. ej., ansiedad, depresión, abuso de sustancias, trastorno bipolar, PTSD)</small></>
        },
        value: 'Behavioral Health'
    },
    {
        label: {
            english: <>Bone & Limbs <small>(e.g., broken bones, amputations, dislocations, sprains)</small></>,
            spanish: <>Huesos y extremidades <small>(p. ej., huesos rotos, amputaciones, dislocaciones, esguinces)</small></>
        },
        value: 'Bones and Limbs'
    },
    {
        label: {
            english: <>Brain & Nerve <small>(e.g., headaches/migraines, nerve pain/disorders, carpal tunnel, stroke, head injury, Parkinson's, Multiple Sclerosis)</small></>,
            spanish: <>Cerebro y nervios <small>(p. ej., dolores de cabeza/migrañas, dolores/trastornos nerviosos, túnel carpiano, derrame cerebral, lesión en la cabeza, Parkinson, esclerosis múltiple)</small></>
        },
        value: 'Brain and Nerve'
    },
    {
        label: {
            english: <>Breathing & Lungs <small>(e.g., asthma, chronic bronchitis, COPD, laryngitis, sinusitis, tonsilitis)</small></>,
            spanish: <>Respiración y pulmones <small>(p. ej., asma, bronquitis crónica, COPD, laringitis, sinusitis, amigdalitis)</small></>
        },
        value: 'Breathing and Lungs'
    },
    {
        label: {
            english: <>Cancers & Growths <small>(e.g., breast cancer, tumors, prostate cancer, colorectal cancer, lung cancer)</small></>,
            spanish: <>Cánceres y bultos <small>(p. ej., cáncer de mama, tumores, cáncer de próstata, cáncer colorrectal, cáncer de pulmón)</small></>
        },
        value: 'Cancer and Growths'
    },
    {
        label: {
            english: <>Cold, Flu & Viral Infections <small>(e.g., flu, COVID, pneumonia/lung infection, mono, strep throat)</small></>,
            spanish: <>Resfriado, gripe e infecciones virales <small>(p. ej., gripe, COVID, neumonía/infección pulmonar, mononucleosis, faringitis estreptocócica)</small></>
        },
        value: 'Cold, Flu and Viral Infections'
    },
    {
        label: {
            english: <>Diabetes & Thyroid <small>(e.g., Type 2 diabetes, hypothyroidism)</small></>,
            spanish: <>Diabetes y tiroides <small>(p. ej., diabetes tipo 2, hipotiroidismo)</small></>
        },
        value: 'Diabetes and Thyroid'
    },
    {
        label: {
            english: <>Eye, Ear & Mouth <small>(e.g., poor vision, toothache, glaucoma, retinal detachment, cataracts, vertigo, facial or jaw fractures)</small></>,
            spanish: <>Ojos, oídos y boca <small>(p. ej., mala visión, dolor dental, glaucoma, desprendimiento de retina, cataratas, vértigo, fracturas faciales o mandibulares)</small></>
        },
        value: 'Ear, Eye and Mouth'
    },
    {
        label: {
            english: <>Heart & Vascular <small>(e.g., heart attack, high blood pressure, blood vessels, heart failure, blood clots, anemia)</small></>,
            spanish: <>Condiciones cardíacas y vasculares <small>(p. ej., ataque al corazón, presión alta, problemas en los vasos sanguíneos, insuficiencia cardíaca, coágulos de sangre, anemia)</small></>
        },
        value: 'Heart and Vascular'
    },
    {
        label: {
            english: <>Joints <small>(e.g., joint replacement, arthritis, osteoarthritis, ACL/knee tears, rotator cuff tears, joint pain, gout)</small></>,
            spanish: <>Articulaciones <small>(p. ej., reemplazo de articulación, artritis, osteoartritis, desgarros del ACL/rodilla, desgarros del manguito rotador, dolor articular, gota)</small></>
        },
        value: 'Joints'
    },
    {
        label: {
            english: <>Muscle & Tendons <small>(e.g., muscle tears, trigger finger, fibromyalgia, muscle disorders)</small></>,
            spanish: <>Músculos y tendones <small>(p. ej., desgarros musculares, dedo en gatillo, fibromialgia, trastornos musculares)</small></>
        },
        value: 'Muscle and Tendons'
    },
    {
        label: {
            english: <>Reproductive Health <small>(e.g., menstrual pain, prostate conditions, prolapse, ovarian cysts, endometriosis, breast disorders)</small></>,
            spanish: <>Salud reproductiva <small>(p. ej., dolor menstrual, condiciones de la próstata, prolapso, quistes ováricos, endometriosis, trastornos mamarios)</small></>
        },
        value: 'Reproductive Health'
    },
    {
        label: {
            english: <>Skin Conditions & Rashes <small>(e.g., eczema, lumps/cysts/abscess, shingles, acne, cellulitis, skin infection, burns)</small></>,
            spanish: <>Condiciones de la piel y erupciones <small>(p. ej., eczema, bultos/quistes/abscesos, culebrilla, acné, celulitis, infección de la piel, quemaduras)</small></>
        },
        value: 'Skin Conditions and Rashes'
    },
    {
        label: {
            english: <>Stomach & Digestive <small>(e.g., diverticulitis, intestinal infection, obesity, gallstones, abdominal/pelvic pain, liver disease)</small></>,
            spanish: <>Problemas digestivos y del estómago <small>(p. ej., diverticulitis, infección intestinal, obesidad, cálculos biliares, dolor abdominal/pélvico, enfermedad del hígado)</small></>
        },
        value: 'Stomach and Digestive'
    },
    {
        label: {
            english: <>Urinary & Kidney <small>(e.g., urinary tract infections, bladder infections, incontinence, kidney stones, kidney disease, kidney failure)</small></>,
            spanish: <>Problemas urinarios y de los riñones <small>(p. ej., infecciones del tracto urinario, infecciones de la vejiga, incontinencia, cálculos renales, enfermedad renal, insuficiencia renal)</small></>
        },
        value: 'Urinary and Kidney'
    },
    {
        label: {
            english: <>Other Health Issues <small>(for conditions not listed above)</small></>,
            spanish: <>Otros problemas de salud <small>(para condiciones no listadas arriba)</small></>
        },
        value: 'Other'
    },
];
