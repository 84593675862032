import { Alert, Box, Col, Heading, Icon, Link, Row, Spinner, TabContainer, TabSection } from '@digitools/honest-react';
import axios from 'axios';
import FileSaver from 'file-saver';
import React from 'react';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import withAuthentication from '../../../hocs/withAuthentication';
import withTealium from '../../../hocs/withTealium';
import { RecordType } from '../../../types/IEmployerResource';
import { globalPhoneNumberFormat } from '../../../utils/validationUtils';
import UserAccessDetails from '../../permissions/UserAccessDetails';
import CopyUserModal from '../components/CopyUserModal';
import EmployerPermissions from '../components/EmployerPermissions';
import RemoveUserModal from '../components/RemoveUserModal';
import UserSearch from '../components/UserSearch';
export class UserPermissionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPageLoading: true,
            isReportGenerating: false,
            directories: [],
            allUsers: [],
            isLoading: false,
            isSearchString: false,
            results: [],
            selectedUser: undefined,
            showAllUsers: true,
            errorMessage: undefined,
            directoryIdsWithAccess: [],
            modifiedDirectoryAccessIds: [],
            modifiedEmployerDirectoryAccessIds: [],
            isAlert: false,
            subLocs: [],
            selectedSubLocs: [],
            products: [],
            userData: undefined,
            configData: undefined,
            permissionData: undefined,
            isEmployerLoaded: false,
            copyPermissionsStatus: undefined,
            copyPermissionsStatusMessageFirstName: '',
            copyPermissionsStatusMessageLastName: '',
        };
    }
    componentDidMount() {
        this.getAllEmployerUsers();
        this.getCustomerConfigData();
        this.getCustomerAppPermissions();
    }
    setCopyPermissionsState = async (success, firstName, lastName, newSelectedUser) => {
        this.handleSelectedUser(newSelectedUser);
        this.setState({ copyPermissionsStatus: success, copyPermissionsStatusMessageFirstName: firstName, copyPermissionsStatusMessageLastName: lastName });
    };
    getAllEmployerUsers = async () => {
        try {
            const response = await axios.get(`/user-service/services/v1/users/employers?active=true`, {
                headers: {
                    Authorization: this.props.authenticationContext.authHeader(),
                },
            });
            this.setState({
                isPageLoading: false,
                allUsers: response.data,
                isAlert: response.data.length === 0,
            });
        }
        catch (error) {
            this.setState({
                isPageLoading: false,
                errorMessage: `We're sorry, we are unable to retrieve users at this time. If this issue persist please contact Technical Support`,
            });
        }
    };
    getAllDirectories = async () => {
        const customerId = this.props.authenticationContext.user ? this.props.authenticationContext.user.customerId : '';
        const userId = this.state.selectedUser ? this.state.selectedUser.userId : '';
        const requestData = {
            customerId: customerId,
            userId: userId,
        };
        this.setState({
            directories: [],
        });
        try {
            const response = await axios.post(`/document-service/reports/directories`, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.props.authenticationContext.authHeader(),
                },
            });
            const employerResourceResponse = await axios.post(`/document-service/employer-resources/directories`, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.props.authenticationContext.authHeader(),
                },
            });
            this.setState({
                directories: [],
            });
            this.setState({
                directories: [...response.data, ...employerResourceResponse.data],
            });
        }
        catch (error) {
            this.setState({
                errorMessage: `Error getting report directories for customer ${customerId}`,
            });
        }
    };
    handleSelectedUser = async (user) => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.USER_PERMISSIONS,
            event_name: 'Employer user selected',
        });
        await this.setState({
            isSearchString: false,
            selectedUser: user,
            showAllUsers: false,
            copyPermissionsStatus: false,
        });
        await this.getEmployerAccess();
        await this.getAllDirectories();
    };
    removeUser = async (user) => {
        try {
            await this.setState({
                showAllUsers: false,
            });
            const requestBody = {
                userId: user.userId,
                removedEmployerAccess: true,
            };
            await axios.patch(`/user-service/services/v1/users/employers`, requestBody, {
                headers: { Authorization: this.props.authenticationContext.authHeader() },
            });
            await this.getAllEmployerUsers();
            this.showAllUsersCall();
        }
        catch (error) {
            this.setState({
                isPageLoading: false,
                errorMessage: `Error removing user`,
            });
        }
    };
    showAllUsersCall = () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.USER_PERMISSIONS,
            event_name: 'Show all users',
        });
        this.setState({
            showAllUsers: true,
            selectedUser: undefined,
            isSearchString: false,
            results: [],
            isAlert: this.state.allUsers.length === 0,
        });
    };
    copyShowAllUsersCall = () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.USER_PERMISSIONS,
            event_name: 'Show all users',
        });
        this.setState({
            showAllUsers: true,
            selectedUser: undefined,
            isSearchString: false,
            results: [],
            isAlert: this.state.allUsers.length === 0,
        });
    };
    closeAlert = () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.USER_PERMISSIONS,
            event_name: 'Close alert',
        });
        this.setState({
            errorMessage: undefined,
            isAlert: false,
        });
    };
    onCheckBoxChange = async (value, fileItem) => {
        const resource = fileItem;
        if (resource.recordType && resource.recordType === RecordType.CUSTOMER) {
            this.setState(prevState => {
                let ids = prevState.modifiedEmployerDirectoryAccessIds;
                if (ids.includes(resource.id)) {
                    ids = ids.filter(id => id !== resource.id);
                }
                else {
                    ids.push(resource.id);
                }
                return { modifiedEmployerDirectoryAccessIds: ids };
            });
        }
        else {
            this.setState(prevState => {
                let ids = prevState.modifiedDirectoryAccessIds;
                if (ids.includes(fileItem.id)) {
                    ids = ids.filter(id => id !== fileItem.id);
                }
                else {
                    ids.push(fileItem.id);
                }
                return { modifiedDirectoryAccessIds: ids };
            });
        }
    };
    renderSelectedUserInfo() {
        return this.state.isEmployerLoaded && (<Col md={3} lg={3}>
        <Box type='header' color={'grayLighter'} id='name' aria-labelledby='name'>
          {this.state.selectedUser && `${this.state.selectedUser.firstName} ${this.state.selectedUser.lastName}`}
        </Box>
        <Box style={{ overflowWrap: 'break-word' }}>
          <div id='EmailAddressHeader' aria-labelledby='EmailAddressHeader'>
            <b>Email Address</b>
          </div>
          <div id='EmailAddress' aria-labelledby='EmailAddress'>
            {this.state.userData && this.state.userData.email}
          </div>
          <br />
          <div id='PhoneNumberHeader' aria-labelledby='PhoneNumberHeader'>
            <b>Phone Number</b>
          </div>
          <div id='PhoneNumber' aria-labelledby='PhoneNumber'>
            {this.state.userData && globalPhoneNumberFormat(this.state.userData.phoneNumber)}
          </div>
        </Box>
        {!this.props.authenticationContext.user?.ssoUser && <Box style={{ marginTop: '0rem' }} data-testid='removeUserBox'> <RemoveUserModal removeSelectUser={this.removeUser} user={this.state.selectedUser}/></Box>}
        <Box style={{ marginTop: '0rem' }}>
          <Link id={'download-user-report'} data-testid='openModal' aria-label='Download user report' onClick={() => (this.state.isReportGenerating ? null : this.generateUserReport(true))}>
            <b>
              <Icon name={'download'} color={'accent1Light'} id='download-user-report' size={'xs'}/> Download user
              report
            </b>
          </Link>
        </Box>
        {this.state.selectedUser &&
                <Box style={{ marginTop: '0rem' }}>
            <CopyUserModal user={this.state.selectedUser} allUsers={this.state.allUsers} setCopyPermissionsState={this.setCopyPermissionsState} selectedUser={this.state.selectedUser} showAllUsers={this.showAllUsersCall} changeSelectedUser={this.handleSelectedUser}/>
          </Box>}
      </Col>);
    }
    undoUserFolderPermissions = async () => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.USER_PERMISSIONS,
            event_name: 'Undo User Folder Permissions',
        });
        this.setState({
            modifiedDirectoryAccessIds: [],
            modifiedEmployerDirectoryAccessIds: [],
        });
        this.getAllDirectories();
    };
    saveUserFolderPermissions = async () => {
        try {
            this.props.trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                event_type: TEALIUM_EVENT.EVENT_TYPE.USER_PERMISSIONS,
                event_name: 'Save User Folder Permissions',
            });
            const userId = this.state.selectedUser.userId;
            const reportsRequestData = {
                userId: userId,
                folderIds: this.state.modifiedDirectoryAccessIds,
            };
            await axios.put(`/document-service/reports/permissions`, reportsRequestData, {
                headers: {
                    Authorization: this.props.authenticationContext.authHeader(),
                },
            });
            await this.setState({
                modifiedDirectoryAccessIds: [],
            });
            const erResourceRequestData = {
                userId: userId,
                folderIds: this.state.modifiedEmployerDirectoryAccessIds,
            };
            await axios.put(`/document-service/employer-resources/permissions`, erResourceRequestData, {
                headers: {
                    Authorization: this.props.authenticationContext.authHeader(),
                },
            });
            await this.setState({
                modifiedEmployerDirectoryAccessIds: [],
            });
        }
        catch (error) {
            this.setState({
                errorMessage: 'Error saving user permissions',
            });
        }
        this.setState({
            isAlert: true,
        });
    };
    getEmployerAccess = async () => {
        await this.setState({
            userData: undefined,
            isEmployerLoaded: false,
        });
        const userId = this.state.selectedUser ? this.state.selectedUser.userId : '';
        try {
            const response = await axios.get(`/user-service/services/v1/users/employers/${userId}`, this.getHeader());
            this.setState({
                userData: response.data,
                isEmployerLoaded: true,
            });
        }
        catch (error) {
            this.setState({
                isEmployerLoaded: true,
                errorMessage: `Error getting user information for ${userId}`,
            });
        }
    };
    getHeader() {
        return {
            headers: {
                'Content-Type': 'application/json',
                Authorization: this.props.authenticationContext.authHeader(),
            },
        };
    }
    getCustomerAppPermissions = async () => {
        const customerId = this.props.authenticationContext.user ? this.props.authenticationContext.user.customerId : '';
        try {
            const customerPermissionResponse = await axios.get(`/auth-service/services/v1/customer/${customerId}/permissions`, this.getHeader());
            this.setState({ permissionData: customerPermissionResponse.data });
        }
        catch (error) {
            this.setState({
                errorMessage: `Error getting customer Permissions`,
            });
        }
    };
    getCustomerConfigData = async () => {
        try {
            const configResponse = await axios.get(`/mlp-ui-service/services/v1/customer/employer/configuration`, this.getHeader());
            this.setState({ configData: configResponse.data });
        }
        catch (error) {
            this.setState({
                errorMessage: `Error getting customer config information`,
            });
        }
    };
    generateUserReport = async (forIndividualUser) => {
        this.props.trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.USER_PERMISSIONS,
            event_name: 'User Report Generated',
        });
        this.setState({ isReportGenerating: true });
        let url = `/user-service/services/v1/user-reports/customer/${this.props.authenticationContext.user.customerId}/excel`;
        if (forIndividualUser) {
            url = url + `?loginIds=${this.state.selectedUser.userId}`;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: this.props.authenticationContext.authHeader(),
                },
                responseType: 'arraybuffer',
            });
            const contentDispositionHeader = response.headers['content-disposition'];
            const contentType = response.headers['content-disposition'];
            const filename = contentDispositionHeader?.split(';')[1]?.split('filename')[1]?.split('=')[1]?.trim();
            const strippedFilename = JSON.parse(filename);
            const blob = new Blob([response.data], { type: contentType });
            FileSaver.saveAs(blob, strippedFilename);
        }
        catch (error) {
            this.setState({
                errorMessage: `Error generating user report`,
            });
        }
        this.setState({ isReportGenerating: false });
    };
    render() {
        const employerDetails = this.state.isEmployerLoaded ? (<EmployerPermissions userInfo={this.state.userData} customerConfig={this.state.configData} getEmployerData={this.getEmployerAccess} permissions={this.state.permissionData}/>) : (<Spinner id='user-permisions-spinner1'/>);
        const unSavedFolderChangesAlert = this.state.modifiedDirectoryAccessIds.length > 0 || this.state.modifiedEmployerDirectoryAccessIds.length > 0 ? (<Alert type={'warning'} className={'mt-3'}>
          <strong>Attention!</strong>&nbsp;You have unsaved changes.
        </Alert>) : (<></>);
        const userDetails = this.state.selectedUser ? (<>
        <Row data-public={true}>
          {this.renderSelectedUserInfo()}
          <Col md={9} lg={9} className={'mb-2'}>
            <h4>Permissions</h4>
            <TabContainer>
              <TabSection name={'Employer'}>{employerDetails}</TabSection>
              <TabSection name={'Folders'}>
                {unSavedFolderChangesAlert}
                <UserAccessDetails closeAlert={this.closeAlert} errorMessage={this.state.errorMessage} user={this.state.selectedUser} directories={this.state.directories} onCheckboxChange={this.onCheckBoxChange} saveUserPermissions={this.saveUserFolderPermissions} isAlert={this.state.isAlert} undoUserPermissions={this.undoUserFolderPermissions}/>
              </TabSection>
            </TabContainer>
          </Col>
        </Row>
      </>) : (<></>);
        const errorGettingUsersAlert = this.state.errorMessage ? (<Alert type='error' closeable={true} onClose={this.closeAlert} data-public={true}>
        {this.state.errorMessage}
      </Alert>) : (<></>);
        if (this.state.isPageLoading) {
            return <Spinner id='user-permissions-spinner2'/>;
        }
        else {
            return (<>
          <br />
          {errorGettingUsersAlert}
          <Row data-public={true}>
              <Col xs={12} md={6}>
                <Heading elemType='h1' id='UserAccessHeader' aria-labelledby='UserAccessHeader'>
                  User Access
                </Heading>
              </Col>
          </Row>

          <div style={{ display: 'flex' }} className={'mb-3 mt-1'} data-public={true}>
            <p id='UserAccessMessage' aria-labelledby='UserAccessMessage' style={{ marginTop: '1rem' }}>
              Search for a user to modify or customize their permissions.
            </p>
            <Link id={'download-all-users-report'} style={{ marginTop: '2.5rem', marginLeft: 'auto', marginRight: '0rem' }} data-testid='openModal' aria-label='Download all users report' onClick={() => (!this.state.isReportGenerating ? this.generateUserReport(false) : null)}>
              <b>
                <Icon name={'download'} color={'accent1Light'} id='icon-download-all-users-report' size={'xs'}/>{' '}
                {this.state.isReportGenerating ? 'Generating Report...' : 'Download report'}
              </b>
            </Link>
          </div>
          <UserSearch selectedUser={this.state.selectedUser} users={this.state.allUsers} showAllIndicator={this.state.showAllUsers} showAllUsers={this.showAllUsersCall} handleSelectUser={this.handleSelectedUser} removeUser={this.removeUser} showCopyPermissionsTable={false}/>
          <Row> {this.state.copyPermissionsStatus && (<Alert style={{ width: '100%' }} type='success' closeable={true} onClose={this.closeAlert} data-public={true}>
              Success! Permissions and folders from <b> {this.state.copyPermissionsStatusMessageFirstName} {this.state.copyPermissionsStatusMessageLastName}</b> have been applied and saved to this user.

            </Alert>)}</Row>
          {userDetails}
        </>);
        }
    }
}
export default withAuthentication(withTealium(UserPermissionsContainer));
