// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import CheckboxGroup from '@digitools/honest-react/dist/lib/components/Checkbox/CheckboxGroup';
import Checkbox from '@digitools/honest-react/dist/lib/components/Checkbox';
import { StepperContext } from '@digitools/honest-react/dist/lib/components/Stepper/StepperContext';
import { Form, Scope } from 'informed';
import useTranslate from '../../../../hooks/useTranslate';
import StepperButtons from '../../components/StepperButtons';
import { ApplicantCoverageText } from '../../i18n/ApplicantCoverageText';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import styled from 'styled-components';
import CoverageSelect from './CoverageSelect';
import ApplicantContainer from '../../components/ApplicantContainer';
import { validateCoverageApplicantElection } from '../../utils/FormFieldValidations';
import useEoiContext from '../../hooks/useEoiContext';
import SaveForLaterErrorAlert from '../../components/SaveForLaterErrorAlert';
import ValidationErrorAlert from '../../components/ValidationErrorAlert';
import ContentMinHeightDiv from '../../components/ContentMinHeightDiv';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import { CheckDuplicateApplication } from '../../api/EoiApiCalls';
import useAuthentication from '../../../../hooks/useAuthentication';
import DuplicateApplicationModal from '../../components/DuplicateApplicationModal';
import { Loader } from '@digitools/honest-react';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import LegalFormNumbers from '../../components/LegalFormNumbers';
const StyledCheckboxContainer = styled('div') `
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    flex-direction: column;
  }

  > div {
    margin-right: 15px;
    margin-top: 0px !important;
  }
`;
const formatCoverages = (values, displayCoverages) => {
    const coverages = [];
    Object.keys(values).forEach(key => {
        if (displayCoverages) {
            if (typeof values[key] === 'object' && !Array.isArray(values[key])) {
                // @ts-ignore
                coverages.push({
                    coverageCode: key,
                    // @ts-ignore
                    ...values[key],
                });
            }
        }
        else {
            // @ts-ignore
            if (values[key] === true) {
                coverages.push({
                    coverageCode: key.substring(0, 3),
                });
            }
        }
    });
    return coverages;
};
const ApplicantCoverage = (props) => {
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const { customContent, setEoiError, initialInformation, setCoverageElections, setApplicantTypes, interviewAnswers, setDisplaySaveForLaterError, setDisplaySaveForLaterModal, setValidationError, } = useEoiContext();
    // FIXME: get rid of GA references, use Tealium event tracking
    const { trackClickWithPage } = useGoogleAnalytics();
    const { authHeader } = useAuthentication();
    const [checkingForDuplicateApplications, setCheckingForDuplicateApplications] = useState(false);
    const { trackView } = useTealium();
    const { user } = useAuthentication();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EOI,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.EOI.APPLICANT_COVERAGE,
        });
    }, []);
    const setIsOpenRef = useRef(() => { });
    const setControl = (setIsOpen) => {
        setIsOpenRef.current = setIsOpen;
    };
    const checkboxGroupInitialValues = () => {
        const values = [];
        if (interviewAnswers.applicantTypes.employee) {
            values.push('employee');
        }
        if (interviewAnswers.applicantTypes.spouse) {
            values.push('spouse');
        }
        if (interviewAnswers.applicantTypes.dependent) {
            values.push('dependent');
        }
        return values;
    };
    const savePageToProvider = async (values) => {
        let employeeCoverage = [];
        let spouseCoverage = [];
        let dependentCoverage = [];
        let familyCoverage = [];
        let employee = false;
        let spouse = false;
        let dependent = false;
        const displayCoverageAmounts = initialInformation && initialInformation.employerConfiguration.preference.displayProductAmountsIndicator;
        const addHiddenPrePopCoverageAmounts = interviewAnswers.coverageElections.prePopCoverage && !displayCoverageAmounts ? true : false;
        if (values.employeeCoverage) {
            if (addHiddenPrePopCoverageAmounts) {
                // if file feed has coverage amounts, and they should not be shown, add them to form.
                employeeCoverage = interviewAnswers.coverageElections.employeeCoverage;
            }
            else {
                employeeCoverage = formatCoverages(values.employeeCoverage, displayCoverageAmounts);
            }
            employee = true;
        }
        if (values.spouseCoverage) {
            if (addHiddenPrePopCoverageAmounts) {
                // if file feed has coverage amounts, and they should not be shown, add them to form.
                spouseCoverage = interviewAnswers.coverageElections.spouseCoverage;
            }
            else {
                spouseCoverage = formatCoverages(values.spouseCoverage, displayCoverageAmounts);
            }
            spouse = true;
        }
        if (values.dependentCoverage) {
            if (addHiddenPrePopCoverageAmounts) {
                // if file feed has coverage amounts, and they should not be shown, add them to form.
                dependentCoverage = interviewAnswers.coverageElections.dependentCoverage;
            }
            else {
                dependentCoverage = formatCoverages(values.dependentCoverage, displayCoverageAmounts);
            }
            dependent = true;
        }
        if (values.familyCoverage) {
            if (addHiddenPrePopCoverageAmounts) {
                // if file feed has coverage amounts, and they should not be shown, add them to form.
                familyCoverage = interviewAnswers.coverageElections.familyCoverage;
            }
            else {
                familyCoverage = formatCoverages(values.familyCoverage, displayCoverageAmounts);
            }
            // @ts-ignore
            if (values.coverageElections.indexOf('employee') > -1) {
                employee = true;
            }
            // @ts-ignore
            if (values.coverageElections.indexOf('spouse') > -1) {
                spouse = true;
            }
            // @ts-ignore
            if (values.coverageElections.indexOf('dependent') > -1) {
                dependent = true;
            }
        }
        setApplicantTypes({ employee, spouse, dependent });
        setCoverageElections({
            employeeCoverage,
            spouseCoverage,
            dependentCoverage,
            familyCoverage,
            prePopCoverage: interviewAnswers.coverageElections.prePopCoverage,
        });
        let empId = interviewAnswers &&
            interviewAnswers.verificationInformation &&
            interviewAnswers.verificationInformation.verificationEmpid;
        if (!empId && user && user.ssoCargo) {
            empId = user.ssoCargo.employeeId;
        }
        let ssn = interviewAnswers &&
            interviewAnswers.verificationInformation &&
            interviewAnswers.verificationInformation.verificationSsn;
        console.log('Dupe check' + (!ssn ? '[eid]' : '[s]'));
        // check for duplicate applications (with ssn or empId if no ssn avail)
        const duplicateApplicationResource = {
            ssn: ssn,
            empId: !ssn ? empId : undefined,
            applicationReasonCode: interviewAnswers &&
                interviewAnswers.additionalInformation &&
                interviewAnswers.additionalInformation.applicationReason,
            coverageElections: { employeeCoverage, spouseCoverage, dependentCoverage, familyCoverage },
        };
        setCheckingForDuplicateApplications(true);
        const data = await CheckDuplicateApplication(duplicateApplicationResource, authHeader());
        setCheckingForDuplicateApplications(false);
        return data;
    };
    const submit = async (values) => {
        trackClickWithPage('continue', '/customer/eoi/coverage');
        setValidationError(false);
        setDisplaySaveForLaterError(false);
        setDisplaySaveForLaterModal(false);
        // if duplicate application, open modal. If not, carry on as usual.
        const duplicateData = await savePageToProvider(values);
        if (duplicateData.data === true) {
            setIsOpenRef.current(true);
        }
        else {
            if (stepper.isEditing()) {
                stepper.completeEdit();
            }
            else {
                stepper.next();
            }
        }
    };
    const back = () => {
        trackClickWithPage('back', '/customer/eoi/coverage');
        props.setIsOnGettingStartedPage(true);
    };
    if (!customContent) {
        setEoiError(true);
    }
    // tslint:disable-next-line: one-variable-per-declaration
    let employeeCoverageEnabled = false, spouseCoverageEnabled = false, dependantCoverageEnabled = false;
    if (initialInformation && initialInformation.employerConfiguration) {
        if (initialInformation.employerConfiguration.employeeProducts) {
            employeeCoverageEnabled = initialInformation.employerConfiguration.employeeProducts.length >= 1;
        }
        if (initialInformation.employerConfiguration.spouseProducts) {
            spouseCoverageEnabled = initialInformation.employerConfiguration.spouseProducts.length >= 1;
        }
        if (initialInformation.employerConfiguration.dependentProducts) {
            dependantCoverageEnabled = initialInformation.employerConfiguration.dependentProducts.length >= 1;
        }
        if (initialInformation.employerConfiguration.familyProducts) {
            const hasFamilyCoverage = initialInformation.employerConfiguration.familyProducts.length >= 1;
            if (hasFamilyCoverage) {
                spouseCoverageEnabled = true;
                dependantCoverageEnabled = true;
            }
        }
        // Disable dependent coverage options for Burgundy customers (no matter their products)
        if (dependantCoverageEnabled && initialInformation.employerConfiguration.preference.burgundyPolicyIndicator) {
            dependantCoverageEnabled = false;
        }
    }
    const onSubmitFailure = (errors) => {
        trackClickWithPage('continue', '/customer/eoi/coverage');
        setValidationError(true);
        window.scrollTo(0, 0);
    };
    return customContent && initialInformation ? (<Form onSubmit={submit} onSubmitFailure={onSubmitFailure} data-public={true}>
      {({ formState }) => {
            // @ts-ignore
            return (<Loader id='applicant-coverage-spinner' isShown={checkingForDuplicateApplications} theme={themes.lincoln}>
            <DuplicateApplicationModal setControl={setControl}/>
            <ValidationErrorAlert />
            <Heading data-testid='eoi-verification-heading' theme={themes.lincoln} elemType={'h1'} elemStyle={'h2'} color={'primary'} style={{ marginBottom: '.5rem' }}>
              {t(ApplicantCoverageText.coverageElectionHeading)}
            </Heading>
            <ContentMinHeightDiv>
              <CheckboxGroup label={t(ApplicantCoverageText.coverageElectionSubHeading)} aria-label={t(ApplicantCoverageText.coverageElectionSubHeading)} field={'coverageElections'} validateOnBlur={true} validateOnChange={true} validate={validateCoverageApplicantElection} initialValue={checkboxGroupInitialValues()}>
                <StyledCheckboxContainer>
                  {employeeCoverageEnabled && (<Checkbox data-testid={'coverageElections-employee-checkbox'} theme={themes.lincoln} field={'employee'} label={t(ApplicantCoverageText.employeeOption)} aria-label={t(ApplicantCoverageText.employeeOption)} initialValue={interviewAnswers.applicantTypes.employee} disabled={interviewAnswers.coverageElections.prePopCoverage}/>)}
                  {spouseCoverageEnabled && (<Checkbox data-testid={'coverageElections-spouse-checkbox'} theme={themes.lincoln} field={'spouse'} label={t(initialInformation.employerConfiguration.preference.spouseLabel)} aria-label={t(initialInformation.employerConfiguration.preference.spouseLabel)} initialValue={interviewAnswers.applicantTypes.spouse} disabled={interviewAnswers.coverageElections.prePopCoverage}/>)}
                  {dependantCoverageEnabled && (<Checkbox data-testid={'coverageElections-dependent-checkbox'} theme={themes.lincoln} field={'dependent'} label={t(ApplicantCoverageText.dependentOption)} aria-label={t(ApplicantCoverageText.dependentOption)} initialValue={interviewAnswers.applicantTypes.dependent} disabled={interviewAnswers.coverageElections.prePopCoverage}/>)}
                </StyledCheckboxContainer>
              </CheckboxGroup>
              <br />
              {formState.values.coverageElections && formState.values.employee && (<ApplicantContainer applicant='employee' type='coverage' showEditButton={true} error={formState.errors && formState.errors.employeeCoverageSelections} errorText={ApplicantCoverageText.coverageElectionError} data-testid={'employee-coverage-container'}>
                  <Scope scope={'employeeCoverage'}>
                    <CoverageSelect applicant='employee'/>
                  </Scope>
                </ApplicantContainer>)}
              {
                // @ts-ignore
                formState.values.coverageElections &&
                    formState.values.coverageElections.indexOf('spouse') > -1 &&
                    initialInformation.employerConfiguration.spouseProducts.length >= 1 && (<ApplicantContainer applicant='spouse' type='coverage' showEditButton={true} error={formState.errors && formState.errors.spouseCoverageSelections} errorText={ApplicantCoverageText.coverageElectionError} data-testid={'spouse-coverage-container'}>
                      <Scope scope={'spouseCoverage'}>
                        <CoverageSelect applicant='spouse'/>
                      </Scope>
                    </ApplicantContainer>)}
              {
                // Apparently this breaks test, but this logic does not make sense = IM
                // @ts-ignore
                formState.values.coverageElections &&
                    formState.values.coverageElections.indexOf('dependent') > -1 &&
                    initialInformation.employerConfiguration.dependentProducts.length >= 1 && (<ApplicantContainer applicant='dependent' type='coverage' showEditButton={true} error={formState.errors && formState.errors.dependentCoverageSelections} errorText={ApplicantCoverageText.coverageElectionError} data-testid={'dependent-coverage-container'}>
                      <Scope scope={'dependentCoverage'}>
                        <CoverageSelect applicant='dependent'/>
                      </Scope>
                    </ApplicantContainer>)}
              {
                // @ts-ignore
                initialInformation.employerConfiguration.familyProducts.length > 0 &&
                    formState.values.coverageElections &&
                    (formState.values.coverageElections.indexOf('spouse') > -1 ||
                        formState.values.coverageElections.indexOf('dependent') > -1) && (<ApplicantContainer applicant='family' type='coverage' showEditButton={true} error={formState.errors && formState.errors.familyCoverageSelections} errorText={ApplicantCoverageText.coverageElectionError} data-testid={'family-coverage-container'}>
                      <Scope scope={'familyCoverage'}>
                        <CoverageSelect applicant='family'/>
                      </Scope>
                    </ApplicantContainer>)}
              <SaveForLaterErrorAlert />
            </ContentMinHeightDiv>
            <LegalFormNumbers />
            <StepperButtons continueButtonType={'continue'} backButtonOnClick={back} saveToProvider={savePageToProvider} page={'/customer/eoi/coverage'}/>
          </Loader>);
        }}
    </Form>) : (<></>);
};
export default ApplicantCoverage;
